import { useEffect, useState } from 'react';

import Typography from '@mui/material/Typography';
import { skipToken } from '@reduxjs/toolkit/query';
import { useSelector } from 'react-redux';

import useContractAndPeriodWithVersions from 'accruals/pages/clinical-expenses/shared/hooks/useContractAndPeriodWithVersions';
import { selectPeriodVersion } from 'accruals/state/slices/periodVersionSlice';
import ColumnMapperStep from 'shared/lib/column-mapper/ColumnMapperStep';
import FullWidthDialog from 'shared/lib/dialog/FullWidthDialog';
import {
  UnmappedFileEntityType,
  type UnmappedFileResponse,
  type UploadedFile,
} from 'shared/lib/types';
import Wizard from 'shared/lib/wizard/Wizard';
import type { ValidationError } from 'shared/wizards/csv-upload-wizards/validate-and-check-table/ValidationSidebar';
import useOnCloseLogic from 'shared/wizards/hooks/useOnCloseLogic';
import UploadUnmappedCsvStep from 'shared/wizards/upload-unmapped-step/csv/UploadUnmappedCsvStep';

import { useGetContractBudgetRecordsBySnapshotQuery } from 'shared/api/rtkq/contractbudgetrecords';
import {
  useCreateContractBudgetSnapshotFromCsvMutation,
  useDeleteContractBudgetSnapshotMutation,
} from 'shared/api/rtkq/contractbudgetsnapshots';

import columns from './columns';
import BudgetConfirmationStep from './steps/BudgetConfirmationStep';
import ContractBudgetReviewStep from './steps/ContractBudgetReviewStep';

type ContractBudgetUploadWizardProps = { onClose: () => void };

function ContractBudgetUploadWizard(props: ContractBudgetUploadWizardProps) {
  const { onClose: onCloseProp } = props;
  const { contractContainer, contractVersion } =
    useContractAndPeriodWithVersions();

  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [snapshotId, setSnapshotId] = useState<string>();

  const [validationErrors, setValidationErrors] = useState<ValidationError[]>(
    [],
  );
  const { currentData: allRecords } =
    useGetContractBudgetRecordsBySnapshotQuery(snapshotId ?? skipToken);
  const [deleteSnapshot] = useDeleteContractBudgetSnapshotMutation();
  const [setFfMappingId, handleCloseBtnClick] = useOnCloseLogic();
  const currentPeriodVersion = useSelector(selectPeriodVersion);

  function onClose(isLastStepSaveClick?: boolean) {
    handleCloseBtnClick(
      uploadedFiles,
      deleteSnapshot,
      setUploadedFiles,
      setSnapshotId,
      onCloseProp,
      isLastStepSaveClick,
      snapshotId,
    );
  }

  // Based on the below useEffect, validation errors are being set
  // in the BudgetConfirmationStep
  useEffect(() => {
    if (!allRecords) {
      return;
    }

    const errors: ValidationError[] = [];
    allRecords.forEach((record, index) => {
      if (
        record.inferred_rollup_row &&
        (record.confirmed_rollup_row === undefined ||
          record.confirmed_rollup_row === null)
      ) {
        errors.push({
          type: 'rollup',
          row: index,
          fields: [], // empty fields will be treated as the whole row
        });
      }
    });
    setValidationErrors(errors);
  }, [allRecords]);

  function saveUrl(unmappedFiles: UnmappedFileResponse[]) {
    setUploadedFiles(
      unmappedFiles.map((file) => ({
        csvBlobUrl: file.file,
        unmappedFileUrl: file.url,
        traceId: file.trace_id,
      })),
    );
  }

  const uploadHeader = 'Upload budget file';
  const uploadDescription =
    'Upload the contract budget as a CSV file. If you don’t have the budget in this format, you can request it from ' +
    'the vendor. We do not recommend entering the budget manually.';

  const mapperHeader = 'Assign these headers to your Contract Budget';

  return (
    <FullWidthDialog open>
      {contractVersion ? (
        <Wizard
          devTitle="DUMBLEDORE"
          startIndex={0}
          stepNames={['Map Columns', 'Validate', 'Review']}
          title="Column Mapping"
          onClose={onClose}
        >
          <UploadUnmappedCsvStep
            description={uploadDescription}
            entityClass={UnmappedFileEntityType.ContractBudgetSnapshot}
            fileTypeWithGrammaticalArticle="a budget file"
            fileUploadType="CBS"
            header={uploadHeader}
            periodVersion={currentPeriodVersion}
            setSnapshotId={setSnapshotId}
            vendor={contractContainer?.vendor}
            onSave={saveUrl}
          />
          <ColumnMapperStep
            columns={columns}
            setFfMappingId={setFfMappingId}
            setSnapshotId={setSnapshotId}
            stepTitleText={mapperHeader}
            uploadedFiles={uploadedFiles}
            extraCreateSnapshotArgs={{
              contract: contractVersion.trace_id,
              snapshot: snapshotId ?? '',
            }}
            useCreateSnapshotEndpoint={
              useCreateContractBudgetSnapshotFromCsvMutation
            }
          />
          <BudgetConfirmationStep
            allRecords={allRecords ?? []}
            setValidationErrors={setValidationErrors}
            validationErrors={validationErrors}
          />
          <ContractBudgetReviewStep snapshotId={snapshotId} />
        </Wizard>
      ) : (
        <Typography variant="h6">ERROR: No current contract!</Typography>
      )}
    </FullWidthDialog>
  );
}

export default ContractBudgetUploadWizard;

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import NavigationTabs from 'shared/components/tabs/NavigationTabs';

import * as routes from 'routes';
import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';
import PageContentLoadingContainer from 'shared/containers/page-content-loading-container/PageContentLoadingContainer';
import { selectCompany } from 'shared/state/slices/companySlice';

import { useGetIntegrationsByCompanyQuery } from 'shared/api/rtkq/integrations';

import AuditLogPage from './audit-log/AuditLogPage';
import IntegrationAuditLogPage from './integration/IntegrationAuditLogPage';

function AuditLogContainerPage() {
  const { activeTab } = useParams();
  const currentCompany = useSelector(selectCompany);

  const { currentData: integrations } = useGetIntegrationsByCompanyQuery({
    company: currentCompany.trace_id,
  });

  if (integrations == null || integrations.length === 0) {
    return (
      <PageContentLoadingContainer>
        <FlexGridContainer>
          <AuditLogPage />
        </FlexGridContainer>
      </PageContentLoadingContainer>
    );
  }

  const TABS = [
    { key: 'change-log', label: 'Change log' },
    { key: 'integration-log', label: 'Integration log' },
  ];

  const generateUrl = (tabKey: string) => routes.getAuditLogTab(tabKey);

  return (
    <PageContentLoadingContainer
      tabs={
        <NavigationTabs
          activeTab={activeTab}
          generateUrl={generateUrl}
          tabs={TABS}
        />
      }
    >
      <FlexGridContainer>
        {activeTab === 'change-log' && <AuditLogPage />}
        {activeTab === 'integration-log' && <IntegrationAuditLogPage />}
      </FlexGridContainer>
    </PageContentLoadingContainer>
  );
}

export default AuditLogContainerPage;

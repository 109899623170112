import type { MouseEventHandler } from 'react';

import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { alpha } from '@mui/system/colorManipulator';

import IconButton from 'shared/ui/icon-button/IconButton';

type SidebarToggleButtonProps = {
  collapsed?: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
};

function SidebarToggleButton(props: SidebarToggleButtonProps) {
  const { collapsed = false, onClick } = props;

  return (
    <IconButton
      data-testid="sidebar-toggle-button"
      size="small"
      variant="primary"
      sx={{
        position: 'fixed',
        bottom: 40,
        left: 176,
        transition: (theme) => theme.transitions.create('all'),
        border: (theme) =>
          `1px solid ${alpha(theme.palette.common.black, 0.1)})`,
        boxShadow: (theme) =>
          `0px 2px 6px 0px ${alpha(theme.palette.common.black, 0.2)}`,
        backgroundColor: (theme) => theme.palette.common.white,
        color: (theme) => alpha(theme.palette.common.black, 0.56),
        ...(collapsed && { transform: 'rotate(180deg)', left: 20 }),
        ':hover': {
          opacity: 0.8,
          backgroundColor: (theme) => theme.palette.background.default,
        },
      }}
      onClick={onClick}
    >
      <ChevronLeft />
    </IconButton>
  );
}

export default SidebarToggleButton;

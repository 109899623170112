import { useMemo } from 'react';

import type { CondorGridOptions } from 'shared/components/ag-grid/types';

function usePatientActivityGridOptions(): CondorGridOptions {
  return useMemo(
    () => ({
      getRowId: 'id',
      grandTotalRow: 'bottom',
      getRowStyle: 'highlightRow',
    }),
    [],
  );
}

export default usePatientActivityGridOptions;

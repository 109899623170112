import { useMemo, useState } from 'react';

import { useSelector } from 'react-redux';

import { useAccrualsRegionsAndAssumptionGroups } from 'accruals/components/contract-assumptions/hooks/useAccrualsRegionsAndAssumptionGroups';

import { selectPeriod } from 'accruals/state/slices/periodSlice';
import useSaveRegionsAndAssumptionGroups from 'shared/lib/contract-assumptions/hooks/useSaveRegionsAndAssumptionGroups';
import FullWidthDialog from 'shared/lib/dialog/FullWidthDialog';
import type {
  ContractVersionResponse,
  RegionResponse,
  TraceId,
  VendorType,
} from 'shared/lib/types';
import Wizard from 'shared/lib/wizard/Wizard';

import { useGetCurrentRegionsQuery } from 'shared/api/rtkq/regions';

import ConfigurationStep from './steps/ConfigurationStep';
import ContractParametersStep from './steps/ContractParametersStep';

type Props = {
  contractVersionTraceId?: TraceId;
  vendorType: VendorType | undefined;
  onClose: () => void;
};

function ContractAssumptionsWizard(props: Props) {
  const { vendorType, contractVersionTraceId, onClose } = props;

  const [selectedContractVersions, setSelectedContractVersions] = useState<
    ContractVersionResponse[]
  >([]);

  const period = useSelector(selectPeriod);
  const { currentData: inheritableRegions = [] } = useGetCurrentRegionsQuery(
    period.trace_id,
  );
  const regionMap = useMemo(
    () =>
      inheritableRegions.reduce<Record<string, RegionResponse>>(
        (acc, region) => {
          acc[region.trace_id] = region;
          return acc;
        },
        {},
      ),
    [inheritableRegions],
  );

  const [
    editableRegions,
    assumptionGroups,
    allRegions,
    allRegionGroups,
    setAssumptionGroups,
  ] = useAccrualsRegionsAndAssumptionGroups(contractVersionTraceId, false);
  const [saveRegionsAndAssumptionGroups] = useSaveRegionsAndAssumptionGroups(
    contractVersionTraceId,
    regionMap,
  );

  function onSave() {
    // TODO: Error handling
    void (async () => {
      await saveRegionsAndAssumptionGroups(
        allRegions,
        allRegionGroups,
        assumptionGroups,
      );
      onClose();
    })();
  }

  const showConfigurationStep = vendorType === 'OCC';

  return (
    <FullWidthDialog open>
      {contractVersionTraceId == null ? (
        <div />
      ) : (
        <Wizard
          devTitle="GENIE"
          title="Contract Assumptions"
          stepNames={
            showConfigurationStep ? ['Configuration', 'Assumptions'] : undefined
          }
          onClose={onClose}
        >
          {showConfigurationStep && (
            <ConfigurationStep
              contractVersionTraceId={contractVersionTraceId}
              selectedContractVersions={selectedContractVersions}
              setSelectedCurrentContracts={setSelectedContractVersions}
            />
          )}
          <ContractParametersStep
            assumptionGroups={assumptionGroups}
            contractVersionTraceId={contractVersionTraceId}
            regionMap={regionMap}
            regions={editableRegions}
            selectedContractVersions={selectedContractVersions}
            setAssumptionGroups={setAssumptionGroups}
            vendorType={vendorType}
            onSave={onSave}
          />
        </Wizard>
      )}
    </FullWidthDialog>
  );
}

export default ContractAssumptionsWizard;

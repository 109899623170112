import type {
  GenericData,
  PurchaseOrderSnapshotResponse,
} from 'shared/lib/types';

import constructApi from './constructApi';
import { HISTORIC_PURCHASE_ORDER_RECORDS_TAG } from './historicpurchaseorderrecords';

const PATH = 'historicpurchaseordersnapshots';

const apiEndpointFactory = constructApi<
  PurchaseOrderSnapshotResponse,
  GenericData
>(PATH).withTags('HISTORIC_PURCHASE_ORDER_SNAPSHOTS', [
  HISTORIC_PURCHASE_ORDER_RECORDS_TAG,
]);

const api = apiEndpointFactory.inject(() => ({
  createHistoricPurchaseOrdersSnapshotFromCsv: apiEndpointFactory.create(
    [HISTORIC_PURCHASE_ORDER_RECORDS_TAG],
    'new_from_csv',
  ),
  deleteHistoricPurchaseOrdersSnapshot: apiEndpointFactory.delete(),
}));

export const {
  useCreateHistoricPurchaseOrdersSnapshotFromCsvMutation,
  useDeleteHistoricPurchaseOrdersSnapshotMutation,
} = api;

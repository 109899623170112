import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';

import EmptyStateMessage from 'accruals/pages/trial-activity/schedule-of-assessments/EmptyStateMessage';
import { getScheduleOfAssessmentsTab } from 'routes';
import { SCHEDULE_TABS } from 'accruals/pages/trial-activity/schedule-of-assessments/ScheduleOfAssessmentsTabs';

function EmptyVisits() {
  const navigate = useNavigate();

  function onClickAddCohort() {
    navigate(getScheduleOfAssessmentsTab(SCHEDULE_TABS.COHORTS));
  }

  return (
    <EmptyStateMessage
      buttonIcon={<AddIcon />}
      buttonText="Add cohort"
      description="Add a cohort to configure your visit schedules."
      testId="btn-manage-visits"
      title="At least one cohort is needed to start your schedule of assessments."
      onButtonClick={onClickAddCohort}
    />
  );
}

export default EmptyVisits;

import Box from '@mui/material/Box';

import type { DropdownOption } from 'shared/lib/types';

import type { RenderOptionProps } from './Autocomplete';

export default function renderOptionWithButton(
  buttonLabel: string,
  button: React.ReactNode,
) {
  function renderOption(
    renderProps: RenderOptionProps,
    option: DropdownOption<unknown>,
  ) {
    const { className, key, ...restProps } = renderProps;

    return option.label === buttonLabel ? (
      <Box
        key={key ?? option.label}
        component="div"
        sx={{
          pl: (theme) => `${theme.spacing(1)} !important`,
        }}
        {...restProps}
        className={className}
      >
        {button}
      </Box>
    ) : (
      <Box
        key={key}
        className={className}
        component="li"
        sx={{
          '&:last-of-type': {
            borderBottom: (theme) => `1px solid ${theme.palette.grey[300]}`,
          },
        }}
        {...restProps}
      >
        {option.label}
      </Box>
    );
  }

  return renderOption;
}

import { useEffect, useState } from 'react';

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { useSelector } from 'react-redux';

import HorizontalInput from 'shared/components/horizontal-input/HorizontalInput';
import CondorTextField from 'shared/components/text-field/CondorTextField';
import IconButton from 'shared/ui/icon-button/IconButton';

import FullWidthDialog from 'shared/lib/dialog/FullWidthDialog';
import type { TrialRegionRequest } from 'shared/lib/types';
import Wizard from 'shared/lib/wizard/Wizard';
import { selectTrial } from 'shared/state/slices/trialSlice';
import WizardStep from 'shared/wizards/steps/WizardStep';

import {
  useCreateRegionMutation,
  useGetRegionsMetadataQuery,
  useUpdateRegionMutation,
} from 'shared/api/rtkq/trialregions';

import LeavePageModal from '../components/leave-page-modal/LeavePageModal';

type Props = {
  onClose: () => void;
};

const TOOLTIP_HIDE_TEXT =
  'Hide from region options in new contracts.\nThis will not affect any existing calculations.';
const TOOLTIP_SHOW_TEXT = 'Show as a region option in new contracts.';

function TrialRegionsWizard(props: Props) {
  const { onClose } = props;

  const trial = useSelector(selectTrial);

  const { currentData: regionsData } = useGetRegionsMetadataQuery(
    trial.trace_id,
  );
  const [updateRegion] = useUpdateRegionMutation();
  const [createRegion] = useCreateRegionMutation();

  const [regions, setRegions] = useState<
    Array<
      TrialRegionRequest & {
        isModified?: boolean;
      }
    >
  >([]);

  const [showLeavePageModal, setShowLeavePageModal] = useState(false);

  useEffect(() => {
    if (regionsData) {
      if (regionsData.length === 0) {
        setRegions([{ name: '', is_hidden: false }]);
      } else {
        setRegions(
          regionsData.map(({ trace_id, name, is_hidden }) => ({
            trace_id,
            name,
            is_hidden,
          })),
        );
      }
    }
  }, [regionsData]);

  const handleCloseBtnClick = (isSaveClick?: boolean) => {
    if (isSaveClick || changedRegions.length === 0) {
      onClose();
    } else {
      setShowLeavePageModal(true);
    }
  };

  const handleRegionRename = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const newName = event.target.value;
    setRegions((prev) => {
      const updated = [...prev];
      updated[index] = { ...updated[index], name: newName, isModified: true };
      return updated;
    });
  };

  const handleRegionAdd = () => {
    setRegions((prev) => [...prev, { name: '', is_hidden: false }]);
  };

  const handleRegionHide = (index: number) => {
    setRegions((prev) => {
      const updated = [...prev];
      updated[index] = {
        ...updated[index],
        is_hidden: !updated[index].is_hidden,
        isModified: true,
      };
      return updated;
    });
  };

  const changedRegions = regions.filter(
    ({ trace_id, name, isModified }) => (!trace_id && name) || isModified,
  );

  const onSave = async () => {
    const updates = changedRegions.map(
      // biome-ignore lint/suspicious/useAwait: We await the Promises below
      async ({ name, trace_id, is_hidden }) => {
        if (!trace_id) {
          return createRegion({
            trial: trial.trace_id,
            name,
            is_hidden,
          });
        }
        return updateRegion({ trace_id, name, is_hidden });
      },
    );

    await Promise.all(updates);
  };

  const regionNames = regions
    .filter(({ name }) => !!name)
    .map(({ name }) => name);
  const duplicateNamesExist = regionNames.length !== new Set(regionNames).size;

  return (
    <FullWidthDialog open>
      <LeavePageModal
        isOpen={showLeavePageModal}
        setIsOpen={setShowLeavePageModal}
        onLeaveBtnClick={() => {
          onClose();
        }}
        onStayBtnClick={() => {
          setShowLeavePageModal(false);
        }}
      />
      <Wizard title="Trial regions" onClose={handleCloseBtnClick}>
        <WizardStep
          disableNextButton={!changedRegions.length || duplicateNamesExist}
          contentSx={{
            mt: 4,
          }}
          onNextAsync={onSave}
        >
          <Container maxWidth="sm">
            <HorizontalInput
              error={duplicateNamesExist && 'Region names must be unique.'}
              helperText="At least one region is required."
              label="Regions"
              required
            >
              <Stack spacing={1}>
                {regions.map(({ name, trace_id, is_hidden }, index) => (
                  <Stack
                    key={trace_id ?? `new-${index}`}
                    alignItems="center"
                    direction="row"
                    spacing={1}
                  >
                    <CondorTextField
                      disabled={is_hidden}
                      name={trace_id}
                      placeholder="Enter region name"
                      size="small"
                      value={name}
                      errors={
                        !!name?.trim() &&
                        regionNames
                          .slice(0, index)
                          .some((existingName) => existingName === name)
                      }
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleRegionRename(event, index)
                      }
                    />
                    <Tooltip
                      title={!is_hidden ? TOOLTIP_HIDE_TEXT : TOOLTIP_SHOW_TEXT}
                      arrow
                    >
                      <div>
                        <IconButton
                          size="small"
                          onClick={() => handleRegionHide(index)}
                        >
                          {!is_hidden ? (
                            <VisibilityOutlinedIcon />
                          ) : (
                            <VisibilityOffOutlinedIcon />
                          )}
                        </IconButton>
                      </div>
                    </Tooltip>
                    {index === regions.length - 1 && (
                      <IconButton size="small" onClick={handleRegionAdd}>
                        <AddCircleOutlineOutlinedIcon />
                      </IconButton>
                    )}
                  </Stack>
                ))}
              </Stack>
            </HorizontalInput>
          </Container>
        </WizardStep>
      </Wizard>
    </FullWidthDialog>
  );
}

export default TrialRegionsWizard;

import type { ReactNode } from 'react';
import { useState } from 'react';

import type { GridApi, GridReadyEvent } from '@ag-grid-community/core';

import type { CondorColDef } from 'shared/components/ag-grid/types';

import FullWidthDialog from 'shared/lib/dialog/FullWidthDialog';
import type {
  IntegrationResponse,
  UnprocessedRecordResponse,
} from 'shared/lib/types';
import Wizard from 'shared/lib/wizard/Wizard';

import { useConvertUnprocessedRecordsMutation } from 'shared/api/rtkq/integrations';

import GridStep from '../components/GridStep';
import ImportLoadingStep from '../components/ImportLoadingStep';

type Props = {
  childTotalValidationKey?: keyof UnprocessedRecordResponse;
  colDefs: CondorColDef[];
  fieldsToValidate: Array<keyof UnprocessedRecordResponse>;
  headerText: ReactNode;
  integration: IntegrationResponse;
  overlayNoRowsTemplate: string;
  setShowIntegration: (showIntegration: boolean) => void;
  title: string;
};

function IntegrationImportWizard(props: Props) {
  const {
    fieldsToValidate,
    colDefs,
    integration,
    setShowIntegration,
    childTotalValidationKey,
    overlayNoRowsTemplate,
    title,
    headerText,
  } = props;
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [convertUnprocessedRecords] = useConvertUnprocessedRecordsMutation();

  const handleGridReady = (event: GridReadyEvent) => {
    setGridApi(event.api);
  };

  const handleGridSave = () => {
    void (async () => {
      const updatedRows: UnprocessedRecordResponse[] = [];
      gridApi?.forEachNode((node) => {
        updatedRows.push(node.data);
      });

      await convertUnprocessedRecords({
        integration: integration.trace_id,
        records: updatedRows,
      });
    })();
  };

  return (
    <FullWidthDialog open>
      <Wizard
        stepNames={['Data Import', 'Mapping']}
        title={title}
        onClose={() => setShowIntegration(false)}
      >
        <ImportLoadingStep integration={integration} />
        <GridStep
          childTotalValidationKey={childTotalValidationKey}
          colDefs={colDefs}
          fieldsToValidate={fieldsToValidate}
          handleGridReady={handleGridReady}
          handleGridSave={handleGridSave}
          headerText={headerText}
          integration={integration}
          overlayNoRowsTemplate={overlayNoRowsTemplate}
        />
      </Wizard>
    </FullWidthDialog>
  );
}

export default IntegrationImportWizard;

import LockIcon from '@mui/icons-material/Lock';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import Tooltip from '@mui/material/Tooltip';

import IconButton, {
  iconButtonClasses,
} from 'shared/ui/icon-button/IconButton';

import { type LockGrid } from 'shared/lib/types';

import withFeatureFlagRequired from 'shared/api/hocs/withFeatureFlagRequired';

import useLockGrid from './useLockGrid';

type Props = {
  grid: LockGrid;
  locationSlug: string | null;
};

function LockGridButton(props: Props) {
  const { grid, locationSlug } = props;
  const { isLocked, userCanLockGrids, toggleLock } = useLockGrid(
    grid,
    locationSlug,
  );

  return (
    <Tooltip
      placement="top"
      title={
        userCanLockGrids
          ? isLocked
            ? 'Unlock grid'
            : 'Lock grid'
          : 'You do not have permission to lock or unlock grids'
      }
      arrow
    >
      <span>
        <IconButton
          data-testid={`${grid}_LOCK`}
          disabled={!userCanLockGrids}
          sx={{
            ml: 1,
            [`&.${iconButtonClasses.root}`]: {
              backgroundColor: ({ palette }) => palette.primary.light,
            },
          }}
          onClick={toggleLock}
        >
          {isLocked ? (
            <LockIcon color="primary" />
          ) : (
            <LockOpenOutlinedIcon color="primary" />
          )}
        </IconButton>
      </span>
    </Tooltip>
  );
}

export default withFeatureFlagRequired(LockGridButton, 'lock_grid');

import type { GridApi, IRowNode } from '@ag-grid-community/core';
import sum from 'lodash/sum';
import { v4 as uuid4 } from 'uuid';

import type { TraceId, UnprocessedRecordResponse } from 'shared/lib/types';
import { IntegrationType } from 'shared/lib/types';

const RECORD_SEPARATOR = '@@@@@@';

function getExtraFields(integrationType: IntegrationType | undefined) {
  switch (integrationType) {
    case IntegrationType.PURCHASE_ORDER_SNAPSHOT:
      return {
        po_amount: 0.0,
        study_id: '',
      };
    case IntegrationType.INVOICE_SNAPSHOT:
      return {
        invoice_amount: 0.0,
        study_id: '',
      };
    default:
      return {};
  }
}

export function validateChildTotals(
  rowData: UnprocessedRecordResponse[],
  api: GridApi,
  amountFieldName: keyof UnprocessedRecordResponse,
) {
  const parentRowsWhichAreSplit = rowData.filter(
    (row) => row.isParent && row.split,
  );
  const amountTotalForChildrenIsValid = parentRowsWhichAreSplit
    .map((parentRow) => {
      const parentRowNode = api.getRowNode(parentRow.trace_id);
      const childRows = rowData.filter(
        (row) => row.parent === parentRow.trace_id && row.split,
      );
      const childAmounts = childRows.map((childRow) =>
        Number.parseFloat(childRow[amountFieldName] as string),
      );
      if (childAmounts.some((value) => Number.isNaN(value) || value === 0)) {
        parentRowNode?.setDataValue('error', true);
        return false;
      }
      const parentTotalAmount = Number.parseFloat(
        parentRow[amountFieldName] as string,
      ).toFixed(2);
      const sumOfChildAmounts = childAmounts
        .reduce((sumValue, current) => sumValue + current, 0)
        .toFixed(2);
      if (sumOfChildAmounts !== parentTotalAmount) {
        parentRowNode?.setDataValue('error', true);
        return false;
      }
      parentRowNode?.setDataValue('error', false);
      return true;
    })
    .every((value) => value);
  return amountTotalForChildrenIsValid;
}

export function validateRowData(
  rowData: UnprocessedRecordResponse[],
  fieldsToValidate: Array<keyof UnprocessedRecordResponse>,
) {
  // Checking if mandatory fields are filled
  const validRows = rowData.every(
    (row) =>
      fieldsToValidate.every(
        (field) =>
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions -- This is needed because we were doing check for null, undefined and empty string
          row[field] &&
          row[field] !== 0 &&
          row[field] !== '0' &&
          row[field] !== '0.00',
      ) || row.ignored,
  );
  return validRows;
}

export function handleAddNewDataRows(
  count: number,
  api: GridApi,
  currentRowId: string,
  parentTraceId: string,
  integrationType: IntegrationType | undefined,
) {
  const currentRow = api.getRowNode(currentRowId);

  if (currentRow?.rowIndex != null) {
    const newRow = {
      ...currentRow.data,
      error: false,
      isParent: false,
      parent: parentTraceId,
      split: true,
      trace_id: null,
      ...getExtraFields(integrationType),
    };
    const newRows = Array.from({ length: count }, () => ({
      ...newRow,
      row_id: `${currentRow.data.trace_id as string}${RECORD_SEPARATOR}${uuid4()}`,
    }));

    api.applyTransaction({
      add: newRows,
      addIndex: currentRow.rowIndex + 1,
      update: [{ ...currentRow.data, split: true, error: true }],
    });

    api.refreshCells({ rowNodes: [currentRow], force: true });
  }
}

export function handleDeleteRowFromGrid(
  api: GridApi,
  parentTraceId: TraceId,
  parentRow: IRowNode | undefined,
  currentRowToDelete: IRowNode | undefined,
) {
  const childrenRows = [] as UnprocessedRecordResponse[];

  api.forEachNode((eachNode) => {
    if (eachNode.data.parent === parentTraceId) {
      childrenRows.push(eachNode.data);
    }
  });

  const totalPOSum = sum(
    childrenRows.map((row) => Number.parseFloat(row.po_amount)),
  );

  if (currentRowToDelete && parentRow) {
    if (childrenRows.length > 2) {
      const currentRowPOAmount = currentRowToDelete.data.po_amount;

      api.applyTransaction({
        // remove the node being deleted
        remove: [currentRowToDelete.data],

        // Update parent node to show error if totals mismatch
        update: [
          {
            ...parentRow.data,
            // .toFixed(2) is needed as there is a possibility for a floating point error
            error:
              (totalPOSum - currentRowPOAmount).toFixed(2) !==
                Number.parseFloat(parentRow.data.po_amount).toFixed(2) &&
              childrenRows.length !== 0,
          },
        ],
      });
    } else {
      api.applyTransaction({
        // If there are only two children, remove both of them as removing one
        // would leave only one child, and you always need at least two children
        remove: childrenRows,
        // Clear parent row error as children are handled above
        update: [
          {
            ...parentRow.data,
            split: false,
            error: parentRow.data.study_id === '' || false,
          },
        ],
      });

      api.refreshCells({ rowNodes: [parentRow], force: true });
    }
  }
}

import { useEffect, useMemo, useState } from 'react';

import { useTheme } from '@mui/material/styles';
import { skipToken } from '@reduxjs/toolkit/query';
import * as Sentry from '@sentry/react';

import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import useGridColDefs from 'shared/components/ag-grid/hooks/useGridColDefs';
import useGridOptions from 'shared/components/ag-grid/hooks/useGridOptions';
import type {
  CondorColDef,
  CondorColGroupDef,
} from 'shared/components/ag-grid/types';

import { getJSONFromFile } from 'shared/helpers/helpers';
import { processCroExpenseGridColumnDefs } from 'shared/hook-helpers/processCroExpenseGridColumnDefs';
import { processExpenseRows } from 'shared/hook-helpers/processExpenseRowData';
import {
  type PeriodGridBlobResponse,
  PeriodGridBlobType,
  PeriodMenuItemType,
} from 'shared/lib/types';

import type { apiJSON } from 'shared/api/rtkq/apiJSON';
import { useGetCurrentContractVersionQuery } from 'shared/api/rtkq/contractcontainers';
import { useGetContractExpenseGridQuery } from 'shared/api/rtkq/contracts';
import { useGetPeriodGridBlobQuery } from 'shared/api/rtkq/periodgridblobs';
import { useGetPeriodMenuItemQuery } from 'shared/api/rtkq/periodmenuitems';

import useForecast from '../hooks/useForecast';
import useForecastedCroExpenseGridOptions from '../hooks/useForecastedCroExpenseGridOptions';
import useForecastedExpenseGridColumnDefs from '../hooks/useForecastedExpenseGridColumnDefs';
import useForecastedExpenseRowData from '../hooks/useForecastedExpenseRowData';
import type {
  GenericGridProps,
  PeriodSpecificProps,
} from './ForecastedExpenseGridProps';

const EMPTY_STATE = { rows: [] };

function OpenPeriodForecastedDirectFeesExpenseGrid(props: PeriodSpecificProps) {
  const { contractContainerSlug } = props;
  const { period } = useForecast();
  const { data: currentContract } = useGetCurrentContractVersionQuery(
    period
      ? {
          trace_id: contractContainerSlug,
          otherParameter: period.trace_id,
        }
      : skipToken,
  );

  let { currentData: expenseData } = useGetContractExpenseGridQuery(
    currentContract && period
      ? {
          trace_id: currentContract.trace_id,
          secondParameter: period.trace_id,
          thirdParameter: 'DIRECT_FEES',
        }
      : skipToken,
  );

  const themeMode = useTheme().palette.mode;

  expenseData ||= EMPTY_STATE;
  const processedExpenseData = processExpenseRows(expenseData);
  const baseColDefs = useMemo(
    () =>
      processCroExpenseGridColumnDefs({
        isOpenPeriod: !period?.is_closed,
        expenseData,
        costCategory: 'DIRECT_FEES',
        themeMode,
        canEditTrialInfo: false,
        activeTab: undefined,
        commentCountsByRowId: {},
        commentType: undefined,
        contractContainerTraceId: contractContainerSlug,
        isGridLocked: false,
        includeCommentButton: false,
      }),
    [period, expenseData, themeMode, contractContainerSlug],
  );

  return (
    <GenericForecastedDirectFeesExpenseGrid
      baseColDefs={baseColDefs}
      processedExpenseData={processedExpenseData}
      {...props}
    />
  );
}

function ClosedPeriodForecastedDirectFeesExpenseGrid(
  props: PeriodSpecificProps,
) {
  const { contractContainerSlug } = props;
  const [columnDefs, setColumnDefs] =
    useState<Array<CondorColDef | CondorColGroupDef>>();
  const [rowData, setRowData] = useState<apiJSON[] | undefined>();

  const { latestPeriodVersion, period } = useForecast();
  const { data: currentContract } = useGetCurrentContractVersionQuery(
    period
      ? {
          trace_id: contractContainerSlug,
          otherParameter: period.trace_id,
        }
      : skipToken,
  );

  const { currentData: periodMenuItems } = useGetPeriodMenuItemQuery(
    currentContract && latestPeriodVersion
      ? {
          saved_object_type: PeriodMenuItemType.CRO_CURRENT_CONTRACT,
          period_version: latestPeriodVersion.trace_id,
          live_slug: currentContract.trace_id,
        }
      : skipToken,
  );

  const { currentData: gridSnapshots } = useGetPeriodGridBlobQuery(
    latestPeriodVersion && periodMenuItems
      ? {
          saved_object_type: PeriodGridBlobType.CRO_DIRECT_FEES_EXPENSE_GRID,
          period_version: latestPeriodVersion.trace_id,
          parent_menu_item: periodMenuItems[0]?.trace_id,
        }
      : skipToken,
  );

  let gridSnapshot: PeriodGridBlobResponse | undefined;
  if (gridSnapshots) {
    gridSnapshot = gridSnapshots[0];

    if (gridSnapshots.length > 1) {
      Sentry.captureMessage(
        "There should only ever be one grid snapshot, but we're getting more than one. This likely means something is missing a parentMenuItem",
      );
    }
  }

  useEffect(() => {
    void (async () => {
      if (gridSnapshot === undefined) {
        return;
      }
      const colDefsFromJson = await getJSONFromFile<CondorColDef[]>(
        gridSnapshot.col_defs,
      );
      if (colDefsFromJson) {
        for (let i = 0; i < colDefsFromJson.length; i++) {
          if (colDefsFromJson[i].cellRenderer === 'AgGridAddCommentRenderer') {
            colDefsFromJson.splice(i, 1);
            break;
          }
        }
      }

      setColumnDefs(colDefsFromJson);
      setRowData(await getJSONFromFile(gridSnapshot.rows));
    })();
  }, [gridSnapshot]);

  return (
    <GenericForecastedDirectFeesExpenseGrid
      baseColDefs={columnDefs}
      processedExpenseData={rowData}
      {...props}
    />
  );
}

// logic that's common for both open and closed periods
function GenericForecastedDirectFeesExpenseGrid(props: GenericGridProps) {
  const {
    sx,
    baseColDefs,
    processedExpenseData,
    showActuals,
    contractContainerSlug,
  } = props;

  const { generatedForecast } = useForecast();
  const columnDefs = useGridColDefs(
    useForecastedExpenseGridColumnDefs,
    [
      baseColDefs,
      generatedForecast,
      showActuals,
      'DIRECT_FEES',
      contractContainerSlug,
    ],
    true,
  );

  const gridOptions = useGridOptions(useForecastedCroExpenseGridOptions, [
    contractContainerSlug,
  ]);

  const rowData = useForecastedExpenseRowData(
    processedExpenseData,
    generatedForecast,
    generatedForecast?.line_items_forecasting[contractContainerSlug]
      ?.direct_fees_line_items,
  );

  const userDisplayOptions = useMemo(
    () => ({ currencyViewMode: 'trial' as const }),
    [],
  );

  return (
    <CondorAgGrid
      columnDefs={columnDefs}
      gridOptions={gridOptions}
      rowData={rowData}
      sx={sx}
      userDisplayOptions={userDisplayOptions}
    />
  );
}

export default function ForecastedDirectFeesExpenseGrid(
  props: PeriodSpecificProps,
) {
  const { period } = useForecast();

  return period?.is_closed ? (
    <ClosedPeriodForecastedDirectFeesExpenseGrid {...props} />
  ) : (
    <OpenPeriodForecastedDirectFeesExpenseGrid {...props} />
  );
}

const DEFAULT_CURRENCY = 'USD';

export const AIP_SUFFIX = ' (amendment-in-progress)';

export function getDateYearAndMonth(date: string): string {
  const dateObject = new Date(date);
  return dateObject.toLocaleString('en-US', {
    month: 'short',
    year: 'numeric',
  });
}

export const getCurrencySymbol = (
  currency = DEFAULT_CURRENCY,
  locale?: string,
) =>
  new Intl.NumberFormat(locale, { style: 'currency', currency })
    .formatToParts(1)
    .find((x) => x.type === 'currency')?.value ?? '$';

export const currencyFormatter = (
  amount: unknown,
  currency: Intl.NumberFormatOptions | string | null = DEFAULT_CURRENCY,
) => {
  if (typeof amount === 'undefined') {
    return '';
  }

  let options: Intl.NumberFormatOptions = {
    style: 'currency',
    currencySign: 'accounting',
  };

  if (typeof currency === 'string') {
    options.currency = currency;
  } else if (currency !== null) {
    options = {
      ...options,
      ...currency,
      currency: currency.currency ?? DEFAULT_CURRENCY,
    };
  } else {
    options = { minimumFractionDigits: 2, currencySign: 'accounting' };
  }

  // this is used in places where we can't guarantee the currency is correctly set
  // (such as file uploads), so try the "best" way to parse it to the "worst".
  // a value of "abc" will be returned as "NaN", so we don't need to check if it's a number
  try {
    return Number(amount).toLocaleString(undefined, options);
  } catch {
    return Number(amount).toString();
  }
};

export const tryGetNumericValue = (value: string | null | undefined) => {
  // users can copy and paste any value they want, so ignore "bad" values
  const cleanedValue = currencyToNumberFormatter(value);
  return Number.isNaN(cleanedValue) ? null : cleanedValue;
};

export const tryGetNumericStringValue = (value: string | null | undefined) => {
  // users can copy and paste any value they want, so ignore "bad" values
  const cleanedValue = currencyToNumberFormatter(value);
  return Number.isNaN(cleanedValue) ? null : cleanedValue.toString();
};

export const currencyToNumberFormatter = (
  value: number | string | null | undefined,
  locale: string | undefined = undefined,
) => {
  if (value === undefined || value === null || value === '') {
    return Number.NaN;
  }

  // get the current separator from the intl library (or default to USD, if not found)
  const decimalSeparator =
    Intl.NumberFormat(locale)
      .formatToParts(1.1)
      .find((part) => part.type === 'decimal')?.value ?? ',';

  const numValue = value.toLocaleString().trim();

  // strip all the non-numbers and decimal separator (either , or . depending on locale)
  let parsedValue = numValue.replace(
    new RegExp(`[^\\-0-9\\${decimalSeparator}]+`, 'g'),
    '',
  );

  // convert any remaining `,` to `.` to ensure we can convert to a number
  parsedValue = parsedValue.replace(/,/g, '.');

  // ensure any non-currency number gets converted to two decimal points
  parsedValue = /^-?\d+(?:\.\d{0,2})?/.exec(parsedValue)?.[0] ?? '';

  return Number.parseFloat(parsedValue);
};

export const percentFormatter = (
  value: number | string | null | undefined,
  showPlusSign = false,
  decimalPlaces = 2,
) => {
  if (
    value === undefined ||
    value === null ||
    Number.isNaN(value) ||
    value === ''
  ) {
    return '';
  }

  let numValue = value;
  if (typeof numValue === 'string') {
    numValue = Number.parseFloat(numValue);
  }

  if (Number.isNaN(numValue) || !Number.isFinite(numValue)) {
    return '';
  }

  return `${showPlusSign && numValue > 0 ? '+' : ''}${(
    numValue / 100
  ).toLocaleString(undefined, {
    style: 'percent',
    maximumFractionDigits: decimalPlaces,
    minimumFractionDigits: decimalPlaces,
  })}`;
};

export const getFormattedNumber = (
  value: number | string | null | undefined,
  decimalPlaces = 2,
  locales?: Intl.LocalesArgument,
) => {
  const numValue =
    typeof value === 'number' ? value : Number.parseFloat(value ?? '');
  if (Number.isNaN(numValue)) {
    return '';
  }

  return numValue.toLocaleString(locales, {
    style: 'decimal',
    maximumFractionDigits: decimalPlaces,
    minimumFractionDigits: decimalPlaces,
  });
};

export const formatValue = (value: string | undefined) =>
  value === undefined || value === '' ? null : value;

import { useMemo } from 'react';

import { useTheme } from '@mui/material/styles';
import { useParams } from 'react-router-dom';

import type {
  CondorColDef,
  CondorColGroupDef,
} from 'shared/components/ag-grid/types';
import useRowCommentsCountLoader from 'shared/components/comments/hooks/useRowCommentsCountLoader';

import {
  getCommentSlugForCroTabs,
  processCroExpenseGridColumnDefs,
} from 'shared/hook-helpers/processCroExpenseGridColumnDefs';
import useHasPermission from 'shared/lib/permissions/useHasPermission';
import type {
  CommentLocationType,
  CroCostCategory,
  RegionAndRegionGroupResponse,
} from 'shared/lib/types';

import type { apiJSON } from 'shared/api/rtkq/apiJSON';

import useContractAndPeriodWithVersions from '../../shared/hooks/useContractAndPeriodWithVersions';

export default function useCroExpenseGridColumnDefs(
  isOpenPeriod: boolean,
  expenseData: apiJSON | undefined,
  costCategory: CroCostCategory,
  commentType: CommentLocationType,
  regionsAndRegionGroups: RegionAndRegionGroupResponse[],
): Array<CondorColDef | CondorColGroupDef> {
  const themeMode = useTheme().palette.mode;
  const canEditTrialInfo = useHasPermission(['canEditTrialInfo']);
  const { activeTab } = useParams();
  const { contractContainerTraceId } = useContractAndPeriodWithVersions();
  const commentCountsByRowId = useRowCommentsCountLoader(
    commentType,
    getCommentSlugForCroTabs(activeTab, contractContainerTraceId),
  );

  return useMemo(
    () =>
      processCroExpenseGridColumnDefs({
        isOpenPeriod,
        expenseData,
        costCategory,
        themeMode,
        canEditTrialInfo,
        activeTab,
        commentCountsByRowId,
        commentType,
        contractContainerTraceId,
        regionsAndRegionGroups,
      }),
    [
      isOpenPeriod,
      costCategory,
      expenseData,
      regionsAndRegionGroups,
      contractContainerTraceId,
      commentCountsByRowId,
      themeMode,
      canEditTrialInfo,
      activeTab,
      commentType,
    ],
  );
}

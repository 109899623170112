import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { selectForecast } from 'forecasting/state/slices/forecastSlice';
import { ForecastParameterType } from 'shared/lib/types';

import { useDefaultParametersQuery } from 'shared/api/rtkq/forecasts';

function useForecastSiteMonthRows() {
  const forecast = useSelector(selectForecast);
  const { currentData: defaultParameters } = useDefaultParametersQuery(
    forecast.trace_id,
  );

  return useMemo(() => {
    const startUpClinicalDuration =
      defaultParameters?.[ForecastParameterType.STARTUP_CLINICAL_DURATION_RATE]
        .start_up_clinical_duration;
    const siteMonths =
      defaultParameters?.[ForecastParameterType.SITES_MONTHS_RATE];
    const siteMonthsTotal = Object.values(siteMonths ?? {}).reduce(
      (acc, value) => acc + value,
      0,
    );

    return [
      {
        assumption: 'Start up + clinical duration',
        actual: startUpClinicalDuration ?? 0,
        forecast: startUpClinicalDuration ?? 0,
      },
      {
        isEmpty: true,
      },
      ...Object.entries(siteMonths ?? {}).map(([region, value]) => ({
        assumption: `Site months ${region}`,
        actual: value,
        forecast: value,
      })),
      {
        assumption: 'Site months global total',
        actual: siteMonthsTotal,
        forecast: siteMonthsTotal,
        isTotal: true,
      },
    ];
  }, [defaultParameters]);
}

export default useForecastSiteMonthRows;

import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  selectIsOpen,
  selectLocation,
  selectLocationRowId,
  selectLocationSlug,
  updateIsOpen,
} from 'shared/state/slices/commentSlice';

import CommentsContainer from './CommentsContainer';
import CommentsPopper from './CommentsPopper';
import { useCommentsPopup } from './CommentsPopupContext';

type Props = {
  commentsForPage?: boolean;
};

function CommentsPopup(props: Props) {
  const { commentsForPage } = props;
  const placement = commentsForPage ? 'top-end' : 'auto-start';
  const isOpen = useSelector(selectIsOpen);
  const location = useSelector(selectLocation);
  const locationSlug = useSelector(selectLocationSlug);
  const locationRowId = useSelector(selectLocationRowId);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { setCommentPopupAnchorEl } = useCommentsPopup();

  const onClose = () => {
    dispatch(updateIsOpen({ isOpen: false }));
    setCommentPopupAnchorEl(null);
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: we need to close the popup when the user navigates away from the page
  useEffect(() => {
    if (isOpen) {
      dispatch(updateIsOpen({ isOpen: false }));
    }
  }, [pathname]);

  return (
    <CommentsPopper
      arrow={!commentsForPage}
      open={isOpen}
      placement={placement}
      content={
        <CommentsContainer
          location={location}
          locationRowId={locationRowId}
          locationSlug={locationSlug}
          onClose={onClose}
        />
      }
    />
  );
}

export default CommentsPopup;

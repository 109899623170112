import type { CustomHeaderProps } from '@ag-grid-community/react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';
import type { Theme } from '@mui/material/styles';

import { tooltip } from 'shared/components/ag-grid-cells/renderers/ag-grid-custom-cell-renderer/helpers';

function AgGridForecastParameterHeader(props: CustomHeaderProps) {
  const { displayName, context } = props;

  return (
    <div className="ag-cell-label-container" role="presentation">
      <div
        className="ag-header-cell-label"
        data-ref="eLabel"
        role="presentation"
      >
        <span className="ag-header-cell-text" data-ref="eText">
          {displayName}
        </span>
        <Box display="flex" flex={1} justifyContent="flex-end">
          {tooltip(
            <InfoOutlinedIcon
              sx={{
                height: 16,
                width: 16,
                color: (theme: Theme) => theme.palette.grey[500],
              }}
            />,
            context.iconTooltipMessage,
          )}
        </Box>
      </div>
    </div>
  );
}

export default AgGridForecastParameterHeader;

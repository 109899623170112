import { useMemo } from 'react';

import { getRelevantChanges } from 'shared/components/ag-grid-cells/renderers/ag-grid-record-cell-renderer/utils';
import type { CondorGridOptions } from 'shared/components/ag-grid/types';

import type { AuditLogResponse } from 'shared/lib/types';

// Audit log is intentionally not period aware as it would be too much data to save
export default function useAuditLogGridOptions(): CondorGridOptions<AuditLogResponse> {
  return useMemo(
    () => ({
      getRowId: 'trace_id',
      getRowHeight: ({ node }) => {
        if (node.data?.changes) {
          const changes = getRelevantChanges(node.data.changes);
          const changesWithKey = changes.filter((change) => change.key);

          if (changes.length === 0 && changesWithKey.length === 0) {
            return 48;
          }

          return (
            changesWithKey.length * 48 +
            (changes.length - changesWithKey.length) * 30
          );
        }

        return 48;
      },
      rowModelType: 'serverSide',
      excelStyles: [
        {
          id: 'multiline',
          alignment: {
            wrapText: true,
          },
        },
      ],
    }),
    [],
  );
}

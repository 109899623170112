import { useMemo, useState } from 'react';

import IosShareIcon from '@mui/icons-material/IosShare';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';

import CommentsButton from 'shared/components/comments/CommentsButton';
import usePageCommentsCountLoader from 'shared/components/comments/hooks/usePageCommentsCountLoader';
import IntegrationExportWizard from 'shared/components/integrations/wizards/IntegrationExportWizard';

import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';
import PageContentLoadingContainer from 'shared/containers/page-content-loading-container/PageContentLoadingContainer';
import useFeatureFlag from 'shared/helpers/useFeatureFlag';
import useIntegration from 'shared/hooks/useIntegration';
import PeriodAwareButton from 'shared/lib/periods/PeriodAwareButton';
import { CommentLocationType, IntegrationType } from 'shared/lib/types';
import { selectPeriod } from 'accruals/state/slices/periodSlice';

import withAccrualPageRequiredData from 'accruals/api/hocs/withAccrualPageRequiredData';

import JournalEntryGrid from './grids/JournalEntryGrid';

function JournalEntryPage() {
  const period = useSelector(selectPeriod);

  const commentsCount = usePageCommentsCountLoader(
    CommentLocationType.ACCRUALS_AND_JOURNAL_ENTRY,
  );

  const isIntegrationEnabled = useFeatureFlag('integration_journal_entry');

  const integration = useIntegration(IntegrationType.JOURNAL_ENTRY);
  const [showDialog, setShowDialog] = useState(false);

  return (
    <PageContentLoadingContainer>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexDirection: 'row',
          mb: 2,
        }}
      >
        <Typography color="primary.main" variant="h3">
          Journal Entry
        </Typography>
        {isIntegrationEnabled && integration != null && (
          <>
            <PeriodAwareButton
              permissions={['canEditTrialInfo']}
              startIcon={<IosShareIcon />}
              testId="btn_export_journal_entry"
              variant="outlined"
              onClick={() => setShowDialog(true)}
            >
              Export
            </PeriodAwareButton>
            {showDialog && (
              <IntegrationExportWizard
                integration={integration}
                period={period}
                setShowIntegration={setShowDialog}
                title="Export Journal Entry"
              />
            )}
          </>
        )}
      </Box>
      <FlexGridContainer>
        <JournalEntryGrid sx={useMemo(() => ({ height: '90%' }), [])} />
      </FlexGridContainer>
      <CommentsButton
        commentsCount={commentsCount}
        location={CommentLocationType.ACCRUALS_AND_JOURNAL_ENTRY}
        commentsForPage
      />
    </PageContentLoadingContainer>
  );
}

export default withAccrualPageRequiredData(JournalEntryPage);

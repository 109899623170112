import DashboardCard from 'shared/ui/dashboard-card/DashboardCard';

import OccVersionHistoryGrid from './grids/OccVersionHistoryGrid';

function OccContractVersionHistorySection() {
  return (
    <DashboardCard title="Version History">
      <OccVersionHistoryGrid overlayNoRowsTemplate="No versions available." />
    </DashboardCard>
  );
}

export default OccContractVersionHistorySection;

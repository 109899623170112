import { useEffect, useState } from 'react';

import { skipToken } from '@reduxjs/toolkit/query';

import type { RegionGroupListItemType, TraceId } from 'shared/lib/types';

import { useGetRegionGroupsByContractVersionQuery } from 'shared/api/rtkq/regiongroups';

export const GLOBAL_REGION_GROUP: RegionGroupListItemType = {
  name: 'Global (all added regions)',
  global: true,
  new: false,
};

export function useAccrualsRegionGroups(contractVersionTraceId?: TraceId) {
  const { currentData: databaseRegionGroups } =
    useGetRegionGroupsByContractVersionQuery(
      contractVersionTraceId ?? skipToken,
    );

  const [allRegionGroups, setAllRegionGroups] = useState<
    RegionGroupListItemType[]
  >([GLOBAL_REGION_GROUP]);

  useEffect(() => {
    if (databaseRegionGroups?.length) {
      setAllRegionGroups(() => [
        GLOBAL_REGION_GROUP,
        ...databaseRegionGroups.map((dbRegionGroup) => ({
          ...dbRegionGroup,
          global: false,
          new: false,
        })),
      ]);
    }
  }, [databaseRegionGroups]);

  return { allRegionGroups, setAllRegionGroups };
}

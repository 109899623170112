import { useMemo } from 'react';

import type {
  NewValueParams,
  ValueGetterParams,
} from '@ag-grid-community/core';
import { useTheme } from '@mui/material/styles';
import { useParams } from 'react-router-dom';

import {
  getCommentButtonCellConfig,
  getMoneyCellConfig,
  getSelectCellConfig,
  getToggleableMoneyCellConfig,
  makeEditableIf,
} from 'shared/components/ag-grid-cells/config';
import type {
  CondorColDef,
  CondorColGroupDef,
} from 'shared/components/ag-grid/types';
import useRowCommentsCountLoader from 'shared/components/comments/hooks/useRowCommentsCountLoader';

import { tryGetNumericValue } from 'formatters';
import { isTopsideAdjustment } from 'shared/helpers/gridData';
import { getCommentSlugForCroTabs } from 'shared/hook-helpers/processCroExpenseGridColumnDefs';
import useHasPermission from 'shared/lib/permissions/useHasPermission';
import type { CommentsCountsGroupedByRowId, TraceId } from 'shared/lib/types';
import {
  CommentLocationType,
  CroAdjustmentType,
  CroAdjustmentTypeReverse,
} from 'shared/lib/types';

import {
  useUpdateContractReconTopsideAdjustmentMutation,
  useUpsertContractInvestigatorFeesAdjustmentsMutation,
} from 'shared/api/rtkq/contracts';

import useContractAndPeriodWithVersions from '../../shared/hooks/useContractAndPeriodWithVersions';

type ProcessCroInvestigatorFeesReconColumnDefsParams = {
  activeTab?: string;
  canEditData: boolean;
  commentCountsByRowId?: CommentsCountsGroupedByRowId;
  contractContainerTraceId?: string;
  themeMode?: 'dark' | 'light';
  onAdjustmentAmountChanged?: (params: NewValueParams) => void;
  onAdjustmentTypeChanged?: (params: NewValueParams) => void;
  onReportedLtdExpenseChanged?: (params: NewValueParams) => void;
};

export function processCroInvestigatorFeesReconColumnDefs({
  commentCountsByRowId,
  activeTab,
  contractContainerTraceId,
  canEditData,
  themeMode,
  onAdjustmentAmountChanged,
  onAdjustmentTypeChanged,
  onReportedLtdExpenseChanged,
}: ProcessCroInvestigatorFeesReconColumnDefsParams): Array<
  CondorColDef | CondorColGroupDef
> {
  const currencyKey = 'currency_toggleable';

  return [
    {
      headerName: '',
      children: [
        { field: 'isTopsideAdjustment', hide: true },
        { field: 'site_trace_id', hide: true },
        {
          headerName: 'Site #',
          field: 'site_number',
          pinned: 'left',
          filter: true,
        },
        { headerName: 'Site name', field: 'site_name', pinned: 'left' },
        {
          headerName: 'Region',
          field: 'region_name',
          rowGroup: true,
          hide: true,
        },
      ],
    },
    {
      headerName: 'Contracted Expenses',
      children: [
        {
          headerName: 'Expected Visit LTD expense',
          type: 'rightAligned',
          aggFunc: 'sum',
          ...getToggleableMoneyCellConfig('expected_visit_costs_toggleable', {
            cellRendererParams: { currencyKey },
          }),
        },
        {
          headerName: 'Visit LTD expense',
          type: 'rightAligned',
          aggFunc: 'sum',
          ...getToggleableMoneyCellConfig('actual_visit_costs_toggleable', {
            cellRendererParams: { currencyKey },
          }),
        },
        {
          headerName: 'Admin LTD expense',
          type: 'rightAligned',
          aggFunc: 'sum',
          ...getToggleableMoneyCellConfig('total_admin_toggleable', {
            cellRendererParams: { currencyKey },
          }),
        },
        {
          headerName: 'Procedure LTD expense',
          type: 'rightAligned',
          aggFunc: 'sum',
          ...getToggleableMoneyCellConfig('total_procedures_toggleable', {
            cellRendererParams: { currencyKey },
          }),
        },
        {
          headerName: 'Total LTD expense',
          type: 'rightAligned',
          aggFunc: 'sum',
          ...getToggleableMoneyCellConfig('total_toggleable', {
            cellRendererParams: { currencyKey },
          }),
        },
      ],
    },
    {
      headerName: 'CRO Reported Expenses',
      children: [
        {
          field: 'reportedLtdExpenseUpdated',
          headerName: 'Total LTD expense (reported)',
          aggFunc: 'sum',
          type: 'rightAligned',
          ...getToggleableMoneyCellConfig('reported_ltd_expense_toggleable', {
            cellRendererParams: { currencyKey, useEmDashInTotal: false },
          }),
          ...(canEditData && {
            ...makeEditableIf(
              ({ data, context }) =>
                context.currencyViewMode === 'native' && data?.site_number,
              themeMode,
            ),
            onCellValueChanged: onReportedLtdExpenseChanged,
          }),
        },
        {
          headerName: 'Variance from contracted',
          aggFunc: 'sum',
          filter: 'AgGridVarianceFilter',
          type: 'rightAligned',
          ...getToggleableMoneyCellConfig(
            'reported_ltd_expense_variance_toggleable',
            {
              cellRendererParams: { currencyKey, useEmDashInTotal: false },
            },
          ),
        },
      ],
    },
    {
      headerName: 'Reconciliation',
      children: [
        {
          field: 'adjustment_type',
          headerName: 'Adjustment type',
          refData: CroAdjustmentType,
          filter: true,
          filterParams: {
            values: Object.keys(CroAdjustmentType).filter(
              (adjustmentType) =>
                adjustmentType !==
                CroAdjustmentTypeReverse[CroAdjustmentType.AMIP],
            ),
          },
          ...getMoneyCellConfig({
            useEmDashInGroup: false,
            useEmDashInTotal: false,
          }),
          ...(canEditData && {
            ...getSelectCellConfig({
              useEmDashInGroup: false,
              useEmDashInTotal: false,
            }),
            cellEditorParams: (params: ValueGetterParams) => ({
              values: Object.keys(CroAdjustmentType).filter(
                (adjustmentType) =>
                  adjustmentType !==
                    CroAdjustmentTypeReverse[CroAdjustmentType.AMIP] &&
                  (adjustmentType !==
                    CroAdjustmentTypeReverse[CroAdjustmentType.CROR] ||
                    params.data?.reported_ltd_expense),
              ),
            }),
            ...makeEditableIf(
              ({ data }) => data?.site_number !== undefined,
              themeMode,
            ),
            onCellValueChanged: onAdjustmentTypeChanged,
          }),
        },
        {
          field: 'adjustmentAmountUpdated',
          headerName: 'Adjustment amount',
          type: 'rightAligned',
          aggFunc: 'sum',
          ...getToggleableMoneyCellConfig('adjustment_amount_toggleable', {
            cellRendererParams: {
              currencyKey,
              useEmDash: false,
              useEmDashInTotal: false,
            },
          }),
          ...(canEditData && {
            ...makeEditableIf(
              (params) =>
                params.context.currencyViewMode === 'native' &&
                (isTopsideAdjustment(params) ||
                  (params.data?.site_number !== undefined &&
                    params.data?.adjustment_type ===
                      CroAdjustmentTypeReverse[CroAdjustmentType.OTHR])),
              themeMode,
            ),
            onCellValueChanged: onAdjustmentAmountChanged,
          }),
        },
        {
          headerName: 'Final reconciled expense',
          type: 'rightAligned',
          aggFunc: 'sum',
          ...getToggleableMoneyCellConfig(
            'final_reconciled_expense_toggleable',
            {
              cellRendererParams: {
                currencyKey,
                useEmDash: false,
                useEmDashInTotal: false,
              },
            },
          ),
        },
      ],
    },
    {
      ...getCommentButtonCellConfig(
        CommentLocationType.INVESTIGATOR_FEES_RECON,
        getCommentSlugForCroTabs(activeTab, contractContainerTraceId),
        commentCountsByRowId,
      ),
    },
  ];
}

export default function useCroInvestigatorFeesReconColumnDefs(
  isOpenPeriod: boolean,
  currentContractVersionTraceId: TraceId | undefined,
  currentPeriodTraceId: TraceId | undefined,
  isGridLocked: boolean,
): Array<CondorColDef | CondorColGroupDef> {
  const [upsertContractInvestigatorFeesAdjustmentsRequest] =
    useUpsertContractInvestigatorFeesAdjustmentsMutation();
  const [updateContractReconTopsideAdjustmentRequest] =
    useUpdateContractReconTopsideAdjustmentMutation();

  const canEditTrialInfo = useHasPermission(['canEditTrialInfo']);
  const canEditData = isOpenPeriod && canEditTrialInfo && !isGridLocked;
  const themeMode = useTheme().palette.mode;

  const { activeTab } = useParams();
  const { contractContainerTraceId } = useContractAndPeriodWithVersions();
  const commentCountsByRowId = useRowCommentsCountLoader(
    CommentLocationType.INVESTIGATOR_FEES_RECON,
    getCommentSlugForCroTabs(activeTab, contractContainerTraceId),
  );

  return useMemo(() => {
    const onAdjustmentAmountChanged = (params: NewValueParams) => {
      const adjustmentAmount = tryGetNumericValue(
        params.data?.adjustmentAmountUpdated,
      );

      if (isTopsideAdjustment(params)) {
        void updateContractReconTopsideAdjustmentRequest({
          object: {
            trace_id: currentContractVersionTraceId ?? '',
            topside_adjustment: adjustmentAmount,
          },
          secondParameter: currentPeriodTraceId ?? '',
          thirdParameter: 'INVESTIGATOR_FEES',
        });

        return;
      }

      onAdjustmentUpdated(
        params.data?.site_trace_id,
        params.data?.adjustment_type,
        adjustmentAmount,
        params.data?.reported_ltd_expense,
      );
    };

    const onAdjustmentTypeChanged = (params: NewValueParams) => {
      // if changing the adjustment type, clear the adjustment amount (in case they were on `OTHER`)
      onAdjustmentUpdated(
        params.data?.site_trace_id,
        params.newValue,
        0,
        params.data?.reported_ltd_expense,
      );

      // force the row that was changed to redraw so the amount can be colored correctly
      if (params.node) {
        params.api.redrawRows({ rowNodes: [params.node] });
      }
    };

    const onReportedLtdExpenseChanged = (params: NewValueParams) => {
      onAdjustmentUpdated(
        params.data?.site_trace_id,
        params.data?.adjustment_type,
        params.data?.adjustment_amount,
        tryGetNumericValue(params.data?.reportedLtdExpenseUpdated),
      );
    };

    const onAdjustmentUpdated = (
      siteTraceId: TraceId,
      adjustmentType: CroAdjustmentType | null,
      adjustmentAmount: number | null,
      reportedLtdExpense: number | null,
    ) => {
      if (
        adjustmentType !== null &&
        Object.keys(CroAdjustmentType).includes(adjustmentType)
      ) {
        void upsertContractInvestigatorFeesAdjustmentsRequest({
          object: {
            trace_id: currentContractVersionTraceId ?? '',
            site_trace_id: siteTraceId,
            adjustment_type: adjustmentType,
            adjustment_amount: adjustmentAmount,
            reported_ltd_expense: reportedLtdExpense,
          },
          secondParameter: currentPeriodTraceId ?? '',
        });
      }
    };

    return processCroInvestigatorFeesReconColumnDefs({
      commentCountsByRowId,
      activeTab,
      contractContainerTraceId,
      canEditData,
      themeMode,
      onAdjustmentAmountChanged,
      onAdjustmentTypeChanged,
      onReportedLtdExpenseChanged,
    });
  }, [
    currentContractVersionTraceId,
    currentPeriodTraceId,
    canEditData,
    upsertContractInvestigatorFeesAdjustmentsRequest,
    updateContractReconTopsideAdjustmentRequest,
    themeMode,
    commentCountsByRowId,
    activeTab,
    contractContainerTraceId,
  ]);
}

import { useMemo } from 'react';

import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';

import { makeEditableIf } from 'shared/components/ag-grid-cells/config';
import type { CondorColDef } from 'shared/components/ag-grid/types';

import { selectForecast } from 'forecasting/state/slices/forecastSlice';
import useFeatureFlag from 'shared/helpers/useFeatureFlag';
import { ForecastParameterType } from 'shared/lib/types';

import {
  useCreateForecastParameterMutation,
  useUpdateForecastParameterMutation,
} from 'shared/api/rtkq/forecastparameters';

const useForecastParamViewerColDefs = (
  groupName: string,
  valueType: 'date' | 'number',
): CondorColDef[] => {
  const isEditable = useFeatureFlag(
    `forecasting_parameters_editable_${groupName.toLowerCase().split(' ').join('_')}`,
  );
  const forecast = useSelector(selectForecast);
  const [createForecastParameter] = useCreateForecastParameterMutation();
  const [updateForecastParameter] = useUpdateForecastParameterMutation();

  const themeMode = useTheme().palette.mode;

  return useMemo(() => {
    const handleParameterUpsert = (
      parameter_trace_id: string | undefined,
      regionName: string,
      parameterType: ForecastParameterType,
      newValue?: number,
    ) => {
      void (async () => {
        if (newValue === undefined) {
          return;
        }
        if (!parameter_trace_id) {
          await createForecastParameter({
            forecast: forecast.trace_id,
            type: parameterType,
            region_name: regionName,
            value: newValue,
          }).unwrap();
        } else {
          await updateForecastParameter({
            trace_id: parameter_trace_id,
            value: newValue,
          }).unwrap();
        }
      })();
    };
    return [
      {
        field: 'assumption',
        flex: 1,
        headerName: groupName,
        cellRendererSelector: 'forecastParameterCellRendererSelector',
      },
      {
        field: 'actual',
        flex: 1,
        headerName: 'Contracted',
        valueGetter: (params) => params.data.actual,
      },
      { field: 'parameter_trace_id', hide: true },
      { field: 'region_name', hide: true },
      {
        field: 'forecast',
        flex: 1,
        headerName: 'Forecast',
        valueGetter: (params) =>
          valueType === 'number' && params.data.forecast != null
            ? Math.ceil(params.data.forecast)
            : params.data.forecast,
        ...(isEditable && !forecast.locked && valueType === 'number'
          ? {
              ...makeEditableIf(
                ({ data }) =>
                  // until we implement editing for all types
                  [
                    ForecastParameterType.PATIENTS_COMPLETED_RATE,
                    ForecastParameterType.PATIENTS_ENROLLED_RATE,
                    ForecastParameterType.PATIENTS_SCREENED_RATE,
                    ForecastParameterType.SITES_RATE,
                    ForecastParameterType.STARTUP_CLINICAL_DURATION_RATE,
                    ForecastParameterType.PATIENT_MONTHS_THROUGHPUT_RATE,
                  ].includes(data?.parameterType),
                themeMode,
              ),
              cellEditor: 'AgGridTextEditor',
              cellEditorParams: { inputType: 'number' },
              onCellValueChanged: (event) => {
                handleParameterUpsert(
                  event.data.parameter_trace_id,
                  event.data.region_name,
                  event.data.parameterType,
                  event.newValue,
                );
              },
            }
          : {}),
      },
    ];
  }, [
    groupName,
    forecast,
    createForecastParameter,
    updateForecastParameter,
    valueType,
    themeMode,
    isEditable,
  ]);
};

export default useForecastParamViewerColDefs;

import type { ReactElement, ReactNode } from 'react';
import { useState } from 'react';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import type { DialogContentProps } from '@mui/material/DialogContent/DialogContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';

import SnackbarError from 'shared/components/snackbar-error/SnackbarError';
import Button from 'shared/ui/button/Button';
import LoadingButton from 'shared/ui/loading-button/LoadingButton';

import DialogTitleWithClose from 'shared/lib/dialog/DialogTitleWithClose';
import StepperWithSteps from 'shared/lib/stepper/StepperWithSteps';
import useWizard from 'shared/lib/wizard/useWizard';

type Props = {
  children?: ReactNode;
  contentSx?: DialogContentProps['sx'];
  customHeaderComponent?: ReactElement;
  description?: ReactElement | string;
  disableBackButton?: boolean;
  disableNextButton?: boolean;
  fieldValidator?: () => object;
  formErrors?: string[];
  header?: string;
  isLoading?: boolean;
  nextButtonTextOverride?: string;
  readyToContinue?: boolean;
  setFieldErrors?: (obj: object) => void;
  showSave?: boolean;
  showTitle?: boolean;
  titleOverride?: string;
  onNext?: () => void;
  onNextAsync?: () => Promise<void>;
};

function WizardStep(props: Props) {
  const {
    titleOverride,
    customHeaderComponent,
    header,
    description,
    children,
    onNext,
    onNextAsync,
    disableNextButton = false,
    nextButtonTextOverride,
    disableBackButton = false,
    readyToContinue = true,
    formErrors = [],
    fieldValidator,
    setFieldErrors,
    isLoading,
    contentSx,
    showTitle = true,
    showSave = true,
  } = props;

  const [onNextError, setOnNextError] = useState<string>();

  const {
    devTitle,
    title,
    onClose,
    nextStep,
    previousStep,
    isFirstStep,
    isLastStep,
    activeStep,
    stepNames,
    stepCount,
    goToStep,
  } = useWizard();

  function onCancel() {
    previousStep();
  }

  async function onClick() {
    const fieldErrors = fieldValidator?.() ?? {};
    setFieldErrors?.(fieldErrors);

    if (Object.keys(fieldErrors).length > 0) {
      return;
    }

    try {
      onNext?.();
      await onNextAsync?.();
    } catch (error) {
      if (
        typeof error === 'object' &&
        error !== null &&
        'status' in error &&
        'data' in error
      ) {
        if (error.status === 404) {
          setOnNextError('API or page not found');
        }

        setOnNextError(JSON.stringify(error.data));
      }

      if (error instanceof Error) {
        setOnNextError(error.message);
      }
      return;
    }

    if (readyToContinue) {
      if (isLastStep) {
        onClose(true);
      } else {
        void nextStep();
      }
    }
  }

  const currentStepIndex = activeStep - stepCount + (stepNames?.length ?? 0);

  return (
    <>
      <SnackbarError
        message={onNextError ?? ''}
        onClose={() => {
          setOnNextError(undefined);
        }}
      />
      {showTitle && (
        <DialogTitleWithClose
          stepper={
            stepNames && currentStepIndex >= 0 ? (
              <StepperWithSteps
                activeStep={currentStepIndex}
                stepNames={stepNames}
                width="50%"
                onClick={(clickedStepIndex) => {
                  if (clickedStepIndex < currentStepIndex) {
                    goToStep(clickedStepIndex + 1);
                  }
                }}
              />
            ) : undefined
          }
          onClose={() => onClose(false)}
        >
          {devTitle}
          {titleOverride ?? title}
        </DialogTitleWithClose>
      )}
      <DialogContent sx={{ ...contentSx }}>
        {customHeaderComponent ?? (
          <>
            <Typography color="primary.main" variant="h6">
              {header}
            </Typography>
            <Typography variant="body1">{description}</Typography>
          </>
        )}
        {children}
      </DialogContent>
      <DialogActions>
        {formErrors.length !== 0 && (
          <Box mr={2}>
            <Typography color="error.main" component="div">
              {formErrors}
            </Typography>
          </Box>
        )}
        {!isFirstStep && !disableBackButton ? (
          <Button testId="back" variant="outlined" onClick={onCancel}>
            Back
          </Button>
        ) : null}
        {showSave && (
          <LoadingButton
            disabled={disableNextButton || isLoading}
            loading={isLoading}
            testId={isLastStep ? 'save' : 'next'}
            variant="contained"
            onClick={() => void onClick()}
          >
            {nextButtonTextOverride ?? (isLastStep ? 'Save' : 'Next')}
          </LoadingButton>
        )}
      </DialogActions>
    </>
  );
}

export default WizardStep;

import type {
  CellClassParams,
  CellStyle,
  EditableCallbackParams,
} from '@ag-grid-community/core';

import { toggleableGetter } from 'shared/components/ag-grid/helpers/value-getters';
import type { CondorColDef } from 'shared/components/ag-grid/types';

import type {
  CommentLocationType,
  CommentsCountsGroupedByRowId,
} from 'shared/lib/types';

import currencies from '../../../currencies';
import {
  EM_DASH_PLACEHOLDER,
  highlightCellBackgroundColor,
} from '../ag-grid/helpers/shared';

type CommonConfig = {
  useEmDash?: boolean;
  useEmDashInGroup?: boolean;
  useEmDashInTotal?: boolean;
};

type EditableConfig = {
  showEditIcon?: boolean;
};

type MoneyCellConfig = CommonConfig &
  EditableConfig &
  Intl.NumberFormatOptions & {
    fxRateKey?: string | null;
    currencyKey?: string;
    currencyFooterStyle?: Intl.NumberFormatOptions['style'];
  };

type NullOrDecimalConfig = {
  emptyValueForTopSideAdj: boolean;
  placeholder: string;
  decimalPlaces: number;
  emptyValueForNodeLevel: number | undefined;
};

export const SHARED_MONEY_CELL_CONFIG = {
  cellDataType: 'text',
  cellEditor: 'AgGridTextEditor',
  cellEditorParams: { inputType: 'number' },
  cellRenderer: 'AgGridCustomCellRenderer',
  cellRendererParams: {
    formatter: 'currency',
    fxRateKey: 'fxRate',
    currencyKey: 'currency',
    currencyFooterStyle: 'currency',
    useEmDash: true,
    useEmDashInTotal: true,
    useEmDashInGroup: false,
  },
};

export function getGenericCellConfig(options?: CommonConfig) {
  return {
    cellDataType: 'text',
    cellRenderer: 'AgGridCustomCellRenderer',
    cellRendererParams: {
      useEmDash: true,
      useEmDashInTotal: true,
      useEmDashInGroup: false,
      ...options,
    },
  };
}

export function getSelectCellConfig(options?: CommonConfig) {
  return {
    cellDataType: 'text',
    cellEditor: 'AgGridSelectEditor',
    cellRenderer: 'AgGridCustomCellRenderer',
    cellRendererParams: {
      endIcon: 'drop-down',
      useEmDash: true,
      useEmDashInTotal: true,
      useEmDashInGroup: false,
      ...options,
    },
  };
}

export function getUserManagementCheckboxCellConfig(
  options?: CommonConfig & {
    fieldToCheck?: string;
    tooltip?: string;
  },
) {
  return {
    cellRenderer: 'AgGridUserManagementCheckboxCellRenderer',
    cellEditor: 'AgGridCheckboxCellEditor',
    cellRendererParams: {
      ...options,
    },
  };
}

export function getTextCellConfig(options?: CommonConfig) {
  return {
    cellDataType: 'text',
    cellEditor: 'AgGridTextEditor',
    cellRenderer: 'AgGridCustomCellRenderer',
    cellRendererParams: {
      useEmDash: true,
      useEmDashInTotal: true,
      useEmDashInGroup: false,
      ...options,
    },
  };
}

export function getNumberCellConfig(options?: CommonConfig) {
  return {
    cellDataType: 'text',
    cellEditor: 'AgGridTextEditor',
    cellEditorParams: { inputType: 'number' },
    cellRenderer: 'AgGridCustomCellRenderer',
    cellRendererParams: {
      useEmDash: true,
      useEmDashInTotal: true,
      useEmDashInGroup: false,
      ...options,
    },
  };
}

export function getDateCellConfig(
  options?: CommonConfig & { endIcon?: string },
) {
  return {
    cellDataType: 'text',
    cellEditor: 'AgGridDateCellEditor',
    cellEditorParams: { dateFormat: 'yyyy-MM-dd' },
    cellRenderer: 'AgGridCustomCellRenderer',
    cellRendererParams: {
      formatter: 'date',
      format: 'yyyy-MM-dd',
      endIcon: 'calendar-icon',
      useEmDash: true,
      useEmDashInTotal: true,
      useEmDashInGroup: false,
      ...options,
    },
  };
}

export function getPercentCellConfig(options?: CommonConfig) {
  return {
    cellDataType: 'text',
    cellEditor: 'AgGridPercentEditor',
    cellRenderer: 'AgGridCustomCellRenderer',
    cellRendererParams: {
      formatter: 'percent',
      useEmDash: true,
      useEmDashInTotal: false,
      useEmDashInGroup: false,
      ...options,
    },
  };
}

export function getToggleablePercentCellConfig(
  fieldName: string,
  options?: CommonConfig,
) {
  return {
    cellDataType: 'text',
    cellEditor: 'AgGridPercentEditor',
    cellRenderer: 'AgGridPercentCellRenderer',
    cellRendererParams: {
      useEmDash: false,
      useEmDashInTotal: false,
      useEmDashInGroup: false,
      ...options,
    },
    valueGetter: toggleableGetter(fieldName),
  };
}

export function getPercentCompleteCellConfig(options?: CommonConfig) {
  return {
    cellDataType: 'text',
    cellEditor: 'AgGridPercentEditor',
    cellRenderer: 'AgGridCustomCellRenderer',
    cellRendererParams: {
      formatter: 'percent_complete',
      useEmDash: true,
      useEmDashInTotal: false,
      useEmDashInGroup: false,
      ...options,
    },
  };
}

export function getCurrencyCellConfig(options?: CommonConfig) {
  return {
    cellDataType: 'text',
    cellEditor: 'AgGridSelectEditor',
    cellEditorParams: {
      options: currencies.map((currency) => ({
        value: currency,
        label: currency,
      })),
    },
    cellRenderer: 'AgGridCustomCellRenderer',
    cellRendererParams: {
      endIcon: 'drop-down',
      useEmDash: true,
      useEmDashInTotal: true,
      useEmDashInGroup: false,
      ...options,
    },
  };
}

export function getRowNumberConfig(options?: CommonConfig) {
  return {
    cellDataType: 'text',
    valueGetter:
      "data?.type !== 'BOTTOM_LINE_ADJUSTMENT' && data?.type !== 'CHILD' && data?.displayRowNum !== undefined ? data.displayRowNum : ''",
    cellRendererParams: { ...options },
  };
}

export function getNullOrDecimalConfig(options?: Partial<NullOrDecimalConfig>) {
  const config: NullOrDecimalConfig = {
    emptyValueForTopSideAdj: false,
    placeholder: EM_DASH_PLACEHOLDER,
    decimalPlaces: 2,
    emptyValueForNodeLevel: undefined,
    ...options,
  };

  return {
    cellDataType: 'text',
    valueFormatter: `
    if (node.level <= ${config.emptyValueForNodeLevel}) {      return '';
    }
    if ((value === undefined || value === null || Number.isNaN(value) || !value)) {      if (${config.emptyValueForTopSideAdj.toString()} && data?.isTopsideAdjustment) {        return '';
      }
      return '${config.placeholder}';
    }
    return parseFloat(value).toFixed(${config.decimalPlaces});
    `,
  };
}

export function getTrialMoneyCellConfig(options?: MoneyCellConfig) {
  return {
    ...SHARED_MONEY_CELL_CONFIG,
    cellRendererParams: {
      ...SHARED_MONEY_CELL_CONFIG.cellRendererParams,
      currencyKey: 'trialCurrency',
      ...options,
    },
  };
}

export function getToggleableMoneyCellConfig<TData>(
  fieldName: string,
  overrides: Partial<CondorColDef<TData>> = {},
) {
  const { cellRendererParams: cellRendererOverrides, ...configOverrides } =
    overrides;
  return {
    cellDataType: 'text',
    cellEditor: 'AgGridTextEditor',
    cellEditorParams: { inputType: 'number' },
    cellRendererParams: {
      formatter: 'currency',
      currencyKey: 'currency', // Override this if necessary, for example, a collision with an existing column
      currencyFooterStyle: 'currency',
      useEmDash: true,
      useEmDashInTotal: true,
      useEmDashInGroup: false,
      ...cellRendererOverrides,
    },
    cellRenderer: 'AgGridMoneyCellRenderer',
    colId: fieldName,
    valueGetter: toggleableGetter(fieldName),
    ...configOverrides,
  };
}

export function getMoneyCellConfig(options?: MoneyCellConfig) {
  return {
    ...SHARED_MONEY_CELL_CONFIG,
    cellRendererParams: {
      ...SHARED_MONEY_CELL_CONFIG.cellRendererParams,
      ...options,
    },
  };
}

export function getNativeCurrencyMoneyCellConfig() {
  return {
    cellDataType: 'text',
    cellRenderer: 'AgGridCustomCellRenderer',
    cellRendererParams: {
      formatter: 'native_currency',
      currencyKey: 'nativeCurrency',
      currencyFooterStyle: 'currency',
      useEmDash: true,
      useEmDashInTotal: false,
      useEmDashInGroup: false,
    },
  };
}

/**
 * This must only be used when inside an `isOpenPeriod` conditional
 * Leave themeMode blank if you don't want any coloring
 */
export const makeEditableIf = (
  condition: (params: EditableCallbackParams) => boolean,
  themeMode?: 'dark' | 'light',
) => ({
  editable: condition,
  ...(themeMode && {
    cellStyle(params: CellClassParams): CellStyle | null {
      return condition(params) ? highlightCellBackgroundColor(themeMode) : {};
    },
  }),
});

export function getCommentButtonCellConfig(
  commentLocation: CommentLocationType | undefined,
  locationSlug: string | undefined,
  commentCountsByRowId: CommentsCountsGroupedByRowId | undefined,
) {
  return {
    pinned: 'right' as const,
    resizable: false,
    suppressHeaderMenuButton: true,
    width: 50,
    cellClass: 'ag-cell-button',
    cellRenderer: 'AgGridAddCommentRenderer',
    cellRendererParams: {
      location: commentLocation,
      locationSlug,
      commentCountsByRowId,
    },
  };
}

import type { PurchaseOrderRecordResponse } from 'shared/lib/types';

import constructApi from './constructApi';

const PATH = 'historicpurchaseorderrecords';

export const HISTORIC_PURCHASE_ORDER_RECORDS_TAG =
  'HISTORIC_PURCHASE_ORDER_RECORDS';

const apiEndpointFactory = constructApi<PurchaseOrderRecordResponse>(
  PATH,
).withTags(HISTORIC_PURCHASE_ORDER_RECORDS_TAG);

export const api = apiEndpointFactory.inject(() => ({
  getHistoricPurchaseOrdersByCompany: apiEndpointFactory.getBy('company'),
  getHistoricPurchaseOrderRecordsBySnapshot: apiEndpointFactory.getBy(
    'snapshot',
    'snapshot_row',
  ),
}));

export const {
  useGetHistoricPurchaseOrderRecordsBySnapshotQuery,
  useLazyGetHistoricPurchaseOrdersByCompanyQuery,
  useGetHistoricPurchaseOrdersByCompanyQuery,
} = api;

import type { CroReconRow } from 'shared/lib/types';
import { CroReconGroupType } from 'shared/lib/types';

export const hasAnyActivityRow = (
  type: CroReconGroupType,
  rows: CroReconRow[] | undefined,
) => {
  for (const row of rows ?? []) {
    if (
      (type === CroReconGroupType.AiP && row.hasAip) ||
      (type === CroReconGroupType.Vendor && row.hasVendor) ||
      (type === CroReconGroupType.Current && row.hasContract)
    ) {
      return true;
    }
  }
  return false;
};

export const hasActivityRow = (
  type: CroReconGroupType,
  row: CroReconRow | undefined,
) => {
  if (
    (type === CroReconGroupType.AiP && row?.hasAip) ||
    (type === CroReconGroupType.Vendor && row?.hasVendor) ||
    // there will always be a contract row because we automatically add one for every "current" contract
    // budget line item, but if there is no activity driver, assume we don't have a "valid" row
    (type === CroReconGroupType.Current &&
      row?.hasContract &&
      row.unitType &&
      row.unitDetail)
  ) {
    return true;
  }
  return false;
};

export const createEmptyStateColumnGroupDef = (
  headerName: string,
  emptyLabel: string,
  columnWidth = 600,
) => ({
  headerName,
  children: [
    {
      headerName: emptyLabel,
      headerClass: 'ag-header-cell-force-label',
      width: columnWidth,
    },
  ],
});

import type { RegionResponse } from 'shared/lib/types';

import constructApi from './constructApi';

export const CONTRACT_REGIONS = 'REGIONS';

const apiEndpointFactory =
  constructApi<RegionResponse>('regions').withTags(CONTRACT_REGIONS);
export const api = apiEndpointFactory.inject(() => ({
  getRegionsByContractVersion: apiEndpointFactory.getBy('contract_version'),
  getRegionsByContractContainer: apiEndpointFactory.getBy('contract_container'),
  getCurrentRegions: apiEndpointFactory.getBy('period'),
  getContractRegionsByTrial: apiEndpointFactory.getBy('trial'),
}));

export const {
  useGetRegionsByContractVersionQuery,
  useGetRegionsByContractContainerQuery,
  useLazyGetRegionsByContractVersionQuery,
  useGetCurrentRegionsQuery,
  useGetContractRegionsByTrialQuery,
} = api;

import type { ReactElement } from 'react';
import { useState } from 'react';

import Check from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Popover from '@mui/material/Popover';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

import MenuDown from 'shared/assets/icons/MenuDown';
import MenuUp from 'shared/assets/icons/MenuUp';
import Alert from 'shared/ui/alert/Alert';
import DashboardCard from 'shared/ui/dashboard-card/DashboardCard';

import * as routes from 'routes';
import PeriodAwareButton from 'shared/lib/periods/PeriodAwareButton';
import { type ContractStatusOptions } from 'shared/lib/types';

import ConfirmToChangeStatus from '../ConfirmToChangeStatus';
import useContractAndPeriodWithVersions from '../hooks/useContractAndPeriodWithVersions';
import useContractVersionStatus from './hooks/useContractVersionStatus';

export default function ContractVersionStatusSection(): ReactElement {
  const { allowedStatuses, executionDateString, status } =
    useContractVersionStatus();
  const {
    contractContainerTraceId,
    contractContainerPeriodMenuItemTraceId,
    contractVersion,
  } = useContractAndPeriodWithVersions();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState<boolean>(false);

  const [statusToChangeTo, setStatusToChangeTo] =
    useState<ContractStatusOptions>();

  const navigate = useNavigate();

  const handleChange = (newStatus: ContractStatusOptions) => {
    setStatusToChangeTo(newStatus);
    setOpenConfirm(true);
    handleCloseDropdown();
  };

  const handleOpenDropdown = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };

  function onConfirmChanges() {
    setOpenConfirm(false);
    (() => {
      navigate(
        routes.getClinicalExpensesByContractContainer(
          contractContainerTraceId ?? contractContainerPeriodMenuItemTraceId,
        ),
      );
    })();

    setShowSuccessMsg(true);
    setStatusToChangeTo(undefined);
  }

  function onRejectChanges() {
    setStatusToChangeTo(undefined);
    setOpenConfirm(false);
  }

  const open = Boolean(anchorEl);

  return (
    <DashboardCard>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography color="text.secondary" sx={{ mb: 1.5 }} variant="button">
          Contract Status
        </Typography>
        {allowedStatuses !== undefined && (
          <>
            <PeriodAwareButton
              endIcon={open ? <MenuUp /> : <MenuDown />}
              permissions={['canEditTrialInfo']}
              testId="change"
              variant="text"
              onClick={handleOpenDropdown}
            >
              Change
            </PeriodAwareButton>
            <Popover
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              open={open}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              onClose={handleCloseDropdown}
            >
              <MenuList>
                {allowedStatuses.map((label) => {
                  const active = label === status;
                  return (
                    <MenuItem key={label} onClick={() => handleChange(label)}>
                      {active && (
                        <ListItemIcon>
                          <Check />
                        </ListItemIcon>
                      )}
                      <ListItemText inset={!active}>{label}</ListItemText>
                    </MenuItem>
                  );
                })}
              </MenuList>
            </Popover>
          </>
        )}
      </Box>
      <Typography color="success.dark" component="div" variant="h3">
        {status ?? ''}
      </Typography>
      {executionDateString !== undefined && (
        <Typography
          color="secondary"
          component="div"
          sx={{ mt: 1.5 }}
          variant="caption"
        >
          {executionDateString}
        </Typography>
      )}
      {openConfirm &&
        contractVersion &&
        contractContainerTraceId &&
        statusToChangeTo !== undefined && (
          <ConfirmToChangeStatus
            contractContainerTraceId={contractContainerTraceId}
            contractVersionTraceId={contractVersion.trace_id}
            currentStatus={status}
            existingAipEffectiveDate={contractVersion.aip_effective_date}
            existingEffectiveDate={contractVersion.effective_date}
            existingVoidedDate={contractVersion.voided_date}
            newStatus={statusToChangeTo}
            onClose={onRejectChanges}
            onConfirm={onConfirmChanges}
          />
        )}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={4000}
        open={showSuccessMsg}
        onClose={() => setShowSuccessMsg(false)}
      >
        <Alert severity="success">Contract status changed.</Alert>
      </Snackbar>
    </DashboardCard>
  );
}

import { useMemo } from 'react';

import withCompanyPageRequiredData from 'shared/api/hocs/withCompanyPageRequiredData';

import IntegrationAuditLogGrid from './grids/IntegrationAuditLogGrid';

function IntegrationAuditLogPage() {
  return (
    <IntegrationAuditLogGrid
      overlayNoRowsTemplate="No Data."
      sx={useMemo(() => ({ height: '100%' }), [])}
    />
  );
}

export default withCompanyPageRequiredData(IntegrationAuditLogPage);

import { useMemo, useState } from 'react';

import Grid from '@mui/material/Grid';

import DashboardCard from 'shared/ui/dashboard-card/DashboardCard';

import CurrentContractsGrid from '../grids/CurrentContractsGrid';
import FinancialForecastSummaryGrid from '../grids/FinancialForecastSummaryGrid';
import OperationalForecastPatientsGrid from '../grids/OperationalForecastPatientsGrid';
import OperationalForecastSitesGrid from '../grids/OperationalForecastSitesGrid';
import ShowActuals from './ShowActuals';

function DetailTab() {
  const [showActuals, setShowActuals] = useState<boolean>(true);

  return (
    <>
      <ShowActuals setShowActuals={setShowActuals} showActuals={showActuals} />
      <Grid spacing={4} container>
        <Grid sx={{ pt: 0 }} xs={12} item>
          <DashboardCard title="Financial Forecast">
            <FinancialForecastSummaryGrid
              overlayNoRowsTemplate="No forecast yet."
              showActuals={showActuals}
              sx={useMemo(() => ({ height: 400 }), [])}
            />
          </DashboardCard>
        </Grid>
        <Grid xs={12} item>
          <DashboardCard title="Operational Forecast">
            <OperationalForecastPatientsGrid
              overlayNoRowsTemplate="No forecast yet."
              showActuals={showActuals}
              sx={useMemo(() => ({ mb: 5 }), [])}
            />
            <OperationalForecastSitesGrid
              overlayNoRowsTemplate="No forecast yet."
              showActuals={showActuals}
            />
          </DashboardCard>
        </Grid>
        <Grid xs={12} item>
          <DashboardCard title="Contracts">
            <CurrentContractsGrid
              overlayNoRowsTemplate="No contracts found"
              sx={useMemo(() => ({ height: '100%' }), [])}
            />
          </DashboardCard>
        </Grid>
      </Grid>
    </>
  );
}

export default DetailTab;

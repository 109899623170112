import Typography from '@mui/material/Typography';
import { format } from 'date-fns/format';
import { parseISO } from 'date-fns/parseISO';

import type { LockGrid } from 'shared/lib/types';
import { LockGridStatus } from 'shared/lib/types';

import withFeatureFlagRequired from 'shared/api/hocs/withFeatureFlagRequired';

import useLockGrid from './useLockGrid';

type Props = {
  grid: LockGrid;
  locationSlug: string | null;
};

function LockGridReminder(props: Props) {
  const { grid, locationSlug } = props;
  const { lockInfo } = useLockGrid(grid, locationSlug);

  if (!lockInfo) {
    return null;
  }

  const {
    status,
    updated_at,
    user: { first_name, last_name },
  } = lockInfo;

  const date = format(parseISO(updated_at!), 'PPpp');
  const text = `Last ${status === LockGridStatus.LOCK_GRID ? 'locked' : 'unlocked'} by ${first_name} ${last_name} ${date}`;

  return (
    <Typography color="text.secondary" mt={1} variant="caption">
      {text}
    </Typography>
  );
}
export default withFeatureFlagRequired(LockGridReminder, 'lock_grid');

import { useMemo } from 'react';

import type { CurrencyCode } from 'currencies';

import useForecast from './useForecast';

const COST_TYPES = [
  'STARTUP',
  'CLOSE',
  'ANNUAL',
  'QUARTERLY',
  'MONTHLY',
] as const;

type CostType = (typeof COST_TYPES)[number];

const COST_TYPE_LABELS: Record<CostType, string> = {
  STARTUP: 'startup',
  CLOSE: 'close',
  ANNUAL: 'annual',
  QUARTERLY: 'quarterly',
  MONTHLY: 'monthly',
};

type SiteAverageRow = {
  row_title: string;
  actual_average: number;
  forecasted_average: number;
  trialCurrency: CurrencyCode;
  isTotal?: boolean;
};

function useSiteAveragesGridRows(): SiteAverageRow[] {
  const { loading, generatedForecast } = useForecast();

  return useMemo(() => {
    if (loading || !generatedForecast) {
      return [];
    }

    const costsData = generatedForecast.avg_site_admin_costs_by_region;
    const trialCurrency = generatedForecast.trial_currency;

    const regions: string[] = [];

    for (const regionData of Object.values(costsData)) {
      for (const region of Object.keys(regionData)) {
        if (!regions.includes(region)) {
          regions.push(region);
        }
      }
    }

    const rows: SiteAverageRow[] = [];
    for (const costType of COST_TYPES) {
      const regionData = costType in costsData ? costsData[costType] : {};

      for (const region of regions) {
        const value = regionData[region] || 0;
        rows.push({
          row_title: `Avg site ${COST_TYPE_LABELS[costType]} costs ${region}`,
          actual_average: value,
          forecasted_average: value, // For now, same as actual
          trialCurrency,
        });
      }

      const values = Object.values(regionData);
      const globalAvg = values.length
        ? values.reduce((sum, val) => sum + val, 0) / values.length
        : 0;

      rows.push({
        row_title: `Avg site ${COST_TYPE_LABELS[costType]} costs global`,
        actual_average: globalAvg,
        forecasted_average: globalAvg, // For now, same as actual
        trialCurrency,
        isTotal: true,
      });
    }

    return rows;
  }, [loading, generatedForecast]);
}

export default useSiteAveragesGridRows;

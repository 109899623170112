import { useRef, useState } from 'react';

import { useDispatch } from 'react-redux';

// eslint-disable-next-line import/no-restricted-paths -- this files knows how to handle this empty correctly
import { missingPeriodVersion } from 'accruals/state/slices/periodVersionSlice';
import type {
  CommentLocationType,
  CompanyResponse,
  PeriodVersionResponse,
} from 'shared/lib/types';
import { deleteDraft } from 'shared/state/slices/commentSlice';

import { useCreateCommentMutation } from 'shared/api/rtkq/comments';

import type { AddCommentRef } from './AddComment';
import AddComment from './AddComment';
import FilesDropZone from './FilesDropZone';

type Props = {
  currentCompany: CompanyResponse;
  currentPeriodVersion: PeriodVersionResponse;
  location: CommentLocationType;
  locationRowId?: string;
  locationSlug: string;
};

function CommentEditor(props: Props) {
  const {
    location,
    locationSlug,
    locationRowId,
    currentPeriodVersion,
    currentCompany,
  } = props;
  const addCommentRef = useRef<AddCommentRef | null>(null);
  const dispatch = useDispatch();

  const [saveComment, { isLoading }] = useCreateCommentMutation();
  const [attachments, setAttachments] = useState<File[]>();

  async function onAddComment(text: string) {
    if (text) {
      const data = new FormData();

      if (attachments) {
        for (const attachment of attachments) {
          data.append('files', attachment);
        }
      }

      if (currentPeriodVersion !== missingPeriodVersion) {
        data.append('period_version', currentPeriodVersion.trace_id);
      } else {
        data.append('company', currentCompany.trace_id);
      }

      data.append('location_type', location);
      if (locationSlug) {
        data.append('location_slug', locationSlug);
      }
      if (locationRowId) {
        data.append('location_row_id', locationRowId);
      }
      data.append('text', text);

      try {
        await saveComment(data).unwrap();

        setAttachments([]);
        dispatch(deleteDraft());
        addCommentRef.current?.resetComment();
      } catch {
        // user will see error message from the API in the Toast
      }
    }
  }

  function onDeleteAttachment(toDelete: File) {
    const index = attachments?.findIndex((item) => item.name === toDelete.name);
    if (attachments && typeof index !== 'undefined' && index !== -1) {
      attachments.splice(index, 1);
      setAttachments([...attachments]);
    }
  }

  function upsertAttachments(newAttachments: File[]) {
    setAttachments((current) => [...(current ?? []), ...newAttachments]);
  }

  return (
    <FilesDropZone onAddAttachments={upsertAttachments}>
      <AddComment
        ref={addCommentRef}
        attachments={attachments}
        showLoadingButton={isLoading}
        onAddAttachments={upsertAttachments}
        onAddComment={(text: string) => void onAddComment(text)}
        onDeleteAttachment={onDeleteAttachment}
      />
    </FilesDropZone>
  );
}

export default CommentEditor;

import type { IRowNode } from '@ag-grid-community/core';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';
import type { Theme } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import type { IconTypes } from 'shared/components/ag-grid/types';

const ICON_SX = {
  height: 12,
  width: 12,
  color: (theme: Theme) => theme.palette.grey[500],
};

function getIconColor(
  severity: 'error' | 'success' | 'warning' | undefined,
  materialTheme: Theme,
) {
  switch (severity) {
    case 'success':
      return materialTheme.palette.success.main;
    case 'error':
      return materialTheme.palette.error.main;
    case 'warning':
      return materialTheme.palette.warning.main;
    default:
      return materialTheme.palette.grey[500];
  }
}

export function getIcon(
  severity: 'error' | 'success' | 'warning' | undefined,
  materialTheme: Theme,
  node: IRowNode,
  icon: IconTypes | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- it can literally be any possible value that ag-grid knows how to render
  finalValue: any,
  newRowText?: string,
) {
  if (node.group || node.footer) {
    return null;
  }

  if (icon === 'circle-icon') {
    return (
      <CircleRoundedIcon
        sx={{ ...ICON_SX, color: getIconColor(severity, materialTheme) }}
      />
    );
  }

  if (icon === 'calendar-icon') {
    return <CalendarMonthOutlinedIcon sx={ICON_SX} />;
  }

  if (icon === 'edit-icon') {
    return <ModeEditOutlinedIcon sx={ICON_SX} />;
  }

  if (icon === 'drop-down') {
    // this is intentionally bigger than the others because the icon is so small
    return <ArrowDropDownOutlinedIcon sx={{ width: 16, height: 16 }} />;
  }

  if (icon === 'drop-down-if-new-row') {
    if (finalValue === newRowText) {
      // this is intentionally bigger than the others because the icon is so small
      return <ArrowDropDownOutlinedIcon sx={{ width: 16, height: 16 }} />;
    }

    return <Box sx={ICON_SX} />;
  }

  if (icon === 'add-icon') {
    return <AddCircleOutlineIcon sx={ICON_SX} />;
  }

  if (icon === 'add-icon-if-new-row') {
    if (finalValue === newRowText) {
      return <AddCircleOutlineIcon sx={ICON_SX} />;
    }

    return <Box sx={ICON_SX} />;
  }

  const sx = {
    height: 16,
    width: 16,
    color: getIconColor(severity, materialTheme),
  };

  if (icon === 'error-icon') {
    return <ErrorOutlineOutlinedIcon sx={sx} />;
  }

  if (icon === 'check-icon') {
    return <CheckCircleOutlinedIcon sx={sx} />;
  }

  if (icon === 'info-icon') {
    return <InfoOutlinedIcon sx={sx} />;
  }
}

export function getTextColor(
  materialTheme: Theme,
  severity: 'error' | 'success' | 'warning' | undefined,
  value: number,
  forecast: boolean,
  isCurrency: boolean,
) {
  if (isCurrency) {
    if (value < 0.0 && forecast) {
      return materialTheme.palette.success.main;
    }
    if (value < 0.0 || forecast) {
      return materialTheme.palette.error.main;
    }
  }

  if (severity === 'error') {
    return materialTheme.palette.error.main;
  }
}

export function tooltip(children: React.ReactNode, message?: React.ReactNode) {
  if (!message) {
    return children;
  }

  return (
    <Tooltip
      title={message}
      PopperProps={{
        sx: {
          [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: (theme) => theme.palette.common.black,
          },
        },
      }}
    >
      <span>{children}</span>
    </Tooltip>
  );
}

import { skipToken } from '@reduxjs/toolkit/query';

import type { SnapshotRecordType } from 'shared/lib/types';
import SnapshotRecordsTable from 'shared/wizards/csv-upload-wizards/validate-and-check-table/SnapshotRecordsTable';
import WizardStep from 'shared/wizards/steps/WizardStep';

import { useGetHistoricPurchaseOrderRecordsBySnapshotQuery } from 'shared/api/rtkq/historicpurchaseorderrecords';

import columns from '../../purchase-order/columns'; // these always need to match

type Props = { snapshotId?: string; onComplete: () => void };

function HistoricPurchaseOrdersReviewStep(props: Props) {
  const { onComplete, snapshotId } = props;

  const { currentData: allRecords } =
    useGetHistoricPurchaseOrderRecordsBySnapshotQuery(snapshotId ?? skipToken);

  return (
    <WizardStep
      header="Review your historic PO listing to make sure everything looks right."
      onNext={onComplete}
    >
      <SnapshotRecordsTable
        headers={columns}
        rows={allRecords as SnapshotRecordType[]}
      />
    </WizardStep>
  );
}

export default HistoricPurchaseOrdersReviewStep;

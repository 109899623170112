import { useMemo } from 'react';

import { skipToken } from '@reduxjs/toolkit/query';
import cloneDeep from 'lodash/cloneDeep';

import type {
  AccrualsGridRow,
  BsFluxRow,
  PeriodResponse,
} from 'shared/lib/types';

import {
  useGetAccrualsQuery,
  useGetBsFluxQuery,
} from 'shared/api/rtkq/periods';

export function processBsFluxRows(
  grid:
    | {
        rows: AccrualsGridRow[] | BsFluxRow[];
      }
    | undefined,
) {
  return grid ? cloneDeep(grid.rows) : undefined;
}

export default function useBsFluxRows(
  currentPeriod: PeriodResponse | null,
  lastQuarterPeriod: PeriodResponse | null | undefined,
): AccrualsGridRow[] | BsFluxRow[] | undefined {
  const { currentData: bsFluxGrid } = useGetBsFluxQuery(
    currentPeriod?.trace_id && lastQuarterPeriod?.trace_id
      ? {
          trace_id: currentPeriod.trace_id,
          otherParameter: lastQuarterPeriod.trace_id,
        }
      : skipToken,
  );

  const { currentData: accruals } = useGetAccrualsQuery(
    (lastQuarterPeriod?.trace_id ? undefined : currentPeriod?.trace_id) ??
      skipToken,
  );

  return useMemo(
    () => processBsFluxRows(bsFluxGrid ?? accruals),
    [bsFluxGrid, accruals],
  );
}

import useIsReadOnlyPermission from 'shared/lib/permissions/useIsReadOnlyPermission';

import type { CondorColDef } from '../types';

export default function useGridColDefs<TReturn, TProps extends unknown[]>(
  colDefsHook: (
    isOpenPeriod: boolean,
    ...rest: TProps
  ) => Array<CondorColDef<TReturn>>,
  params?: TProps,
  isGridLocked?: boolean, // TODO: Check useLockedGridStatus inside here so we can pass a LockGrid value instead of a boolean
): Array<CondorColDef<TReturn>> {
  const isReadOnlyPermission = useIsReadOnlyPermission();
  return colDefsHook.apply(null, [
    !isReadOnlyPermission && !isGridLocked,
    ...(params ?? ([] as unknown as TProps)),
  ]);
}

import { useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import NavigationTabs from 'shared/components/tabs/NavigationTabs';

import useCohortAndPeriodWithVersions from 'accruals/pages/clinical-expenses/shared/hooks/useCohortAndPeriodWithVersions';
import { getScheduleOfAssessmentsVisitsCohort } from 'routes';

function VisitsCohortsTabs() {
  const navigate = useNavigate();
  const { activeTab, cohortId } = useParams<{
    activeTab: string;
    cohortId: string;
  }>();
  const { periodSpecificTabItems: finalCohortTabs } =
    useCohortAndPeriodWithVersions();

  const tabs =
    finalCohortTabs?.map((item) => ({
      key: item.key,
      link: getScheduleOfAssessmentsVisitsCohort(activeTab, item.key),
      label: item.title,
      disabled: false,
    })) ?? [];

  useEffect(() => {
    const initialTab = cohortId ?? tabs[0]?.key;

    if (initialTab && !cohortId) {
      navigate(getScheduleOfAssessmentsVisitsCohort(activeTab, initialTab));
    }
  }, [tabs, navigate, cohortId]);

  if (!tabs.length || !cohortId) {
    return null;
  }

  return (
    <NavigationTabs
      activeTab={cohortId}
      generateUrl={(id) => getScheduleOfAssessmentsVisitsCohort(activeTab, id)}
      sx={{ bgcolor: 'common.white' }}
      tabs={tabs}
    />
  );
}

export default VisitsCohortsTabs;

import type { SxProps } from '@mui/material/styles';

import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';

import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';

import useIntegrationAuditLogColumnDefs from '../hooks/useIntegrationAuditLogColumnDefs';
import useIntegrationAuditLogGridOptions from '../hooks/useIntegrationAuditLogGridOptions';
import useIntegrationAuditLogServerSideDatasource from '../hooks/useIntegrationAuditLogServerSideDatasource';

type Props = {
  overlayNoRowsTemplate?: string;
  sx?: SxProps;
};

function IntegrationAuditLogGrid(props: Props) {
  const { overlayNoRowsTemplate, sx } = props;

  const columnDefs = useIntegrationAuditLogColumnDefs();
  const gridOptions = useIntegrationAuditLogGridOptions();
  const serverSideDatasource = useIntegrationAuditLogServerSideDatasource();

  return (
    <FlexGridContainer>
      <CondorAgGrid
        columnDefs={columnDefs}
        gridOptions={gridOptions}
        overlayNoRowsTemplate={overlayNoRowsTemplate}
        paginationPageSize={100}
        serverSideDatasource={serverSideDatasource}
        sx={sx}
        pagination
      />
    </FlexGridContainer>
  );
}

export default IntegrationAuditLogGrid;

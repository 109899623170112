import { useMemo } from 'react';

import {
  getSelectCellConfig,
  makeEditableIf,
} from 'shared/components/ag-grid-cells/config';

function usePatientActivityColDefs() {
  const forecastTypeMappings = {
    per_site_per_month: 'Per site per month',
    manual: 'Manual',
  };

  return useMemo(
    () => [
      {
        headerName: 'Enrolled patients',
        children: [
          {
            field: 'region_name',
            flex: 1,
            headerName: 'Region',
            aggFunc: 'totalLabel',
          },
          {
            field: 'ltd_total',
            flex: 1,
            headerName: 'LTD total',
            aggFunc: 'sum',
          },
          {
            field: 'expected_trial_total',
            flex: 1,
            headerName: 'Expected trial total',
            aggFunc: 'sum',
          },
          {
            field: 'forecast_type',
            flex: 1,
            headerName: 'Forecast type',
            refData: forecastTypeMappings,
            editable: true,
            ...getSelectCellConfig(),
          },
          {
            field: 'per_site_per_month',
            flex: 1,
            headerName: 'Per site per month',
            ...makeEditableIf(
              ({ data }) => data.forecast_type === 'per_site_per_month',
            ),
          },
          {
            field: 'forecasted_total',
            flex: 1,
            headerName: 'Forecasted total',
            aggFunc: 'sum',
          },
          {
            field: 'grand_total',
            flex: 1,
            headerName: 'Grand total',
            aggFunc: 'sum',
          },
        ],
      },
    ],
    [],
  );
}

export default usePatientActivityColDefs;

import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { selectForecast } from 'forecasting/state/slices/forecastSlice';
import { ForecastParameterType } from 'shared/lib/types';

import { useDefaultParametersQuery } from 'shared/api/rtkq/forecasts';

function useForecastPatientMonthRows() {
  const forecast = useSelector(selectForecast);
  const { currentData: defaultParameters } = useDefaultParametersQuery(
    forecast.trace_id,
  );

  return useMemo(() => {
    const patientMonthsThroughput =
      defaultParameters?.[ForecastParameterType.PATIENT_MONTHS_THROUGHPUT_RATE]
        .patient_months_throughput;
    const patientMonths =
      defaultParameters?.[ForecastParameterType.PATIENT_MONTHS_RATE];
    const patientMonthsTotal = Object.values(patientMonths ?? {}).reduce(
      (acc, value) => acc + value,
      0,
    );

    return [
      {
        assumption: 'Expected patient throughput (months) global',
        actual: patientMonthsThroughput ?? 0,
        forecast: patientMonthsThroughput ?? 0,
      },
      {
        isEmpty: true,
      },
      ...Object.entries(patientMonths ?? {}).map(([region, value]) => ({
        assumption: `Patient months ${region}`,
        actual: value,
        forecast: value,
      })),
      {
        assumption: 'Patient months global total',
        actual: patientMonthsTotal,
        forecast: patientMonthsTotal,
        isTotal: true,
      },
    ];
  }, [defaultParameters]);
}

export default useForecastPatientMonthRows;

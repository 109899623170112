import { useMemo, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import DashboardCard from 'shared/ui/dashboard-card/DashboardCard';

import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';
import PageContentLoadingContainer from 'shared/containers/page-content-loading-container/PageContentLoadingContainer';
import type { CurrencyViewMode } from 'shared/lib/currency-toggle-group/CurrencyToggleGroup';
import CurrencyToggleGroup from 'shared/lib/currency-toggle-group/CurrencyToggleGroup';
import PeriodAwareButton from 'shared/lib/periods/PeriodAwareButton';

import withAccrualPageRequiredData from 'accruals/api/hocs/withAccrualPageRequiredData';

import ContractContainerWizard from '../shared/ContractContainerWizard';
import OccContractVersionHistorySection from './OccContractVersionHistorySection';
import OccTabs from './OccTabs';
import OccExpenseSummaryGrid from './grids/OccExpenseSummaryGrid';

function OccOverviewTab() {
  const [currencyViewMode, setCurrencyViewMode] =
    useState<CurrencyViewMode>('trial');
  const [showNewOcc, setShowNewOcc] = useState<boolean>(false);
  const userDisplayOptions = useMemo(
    () => ({
      currencyViewMode,
    }),
    [currencyViewMode],
  );

  return (
    <PageContentLoadingContainer tabs={<OccTabs />}>
      <Stack spacing={4}>
        <DashboardCard
          title="OCC Expense Summary"
          titleAction={
            <Box alignItems="center" display="flex" flexDirection="row" gap={1}>
              <CurrencyToggleGroup
                setValue={setCurrencyViewMode}
                testId="ClinicalExpenseSummaryToggle"
                value={currencyViewMode}
              />
              <PeriodAwareButton
                permissions={['canEditTrialInfo']}
                startIcon={<AddIcon />}
                testId="add_occ"
                variant="outlined"
                onClick={() => setShowNewOcc(true)}
              >
                Add OCC
              </PeriodAwareButton>
            </Box>
          }
        >
          <FlexGridContainer>
            <OccExpenseSummaryGrid userDisplayOptions={userDisplayOptions} />
          </FlexGridContainer>
        </DashboardCard>
        <OccContractVersionHistorySection />
      </Stack>
      {showNewOcc && (
        <ContractContainerWizard
          vendorType="OCC"
          onClose={() => setShowNewOcc(false)}
        />
      )}
    </PageContentLoadingContainer>
  );
}

export default withAccrualPageRequiredData(OccOverviewTab);

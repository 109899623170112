import { useMemo } from 'react';

import { useSelector } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';

import { selectTrial } from 'shared/state/slices/trialSlice';

import { useGetAdministrativeOrProcedureCategoriesByTrialQuery } from 'shared/api/rtkq/administrativeorprocedurecategories';
import { useGetProcedureOccurrenceByCohortQuery } from 'shared/api/rtkq/procedureoccurrence';

function useAssociatedVisitsRows(cohortId: string) {
  const trial = useSelector(selectTrial);
  const { currentData: adminOrProcedureCategories } =
    useGetAdministrativeOrProcedureCategoriesByTrialQuery(trial.trace_id);
  const { currentData } = useGetProcedureOccurrenceByCohortQuery(cohortId);
  return useMemo(
    () => (currentData?.rows ? cloneDeep(currentData?.rows) : undefined),
    [adminOrProcedureCategories, currentData],
  );
}

export default useAssociatedVisitsRows;

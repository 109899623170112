import { useMemo } from 'react';

import type { CondorGridOptions } from 'shared/components/ag-grid/types';

export default function useForecastParamEditorGridOptions(): CondorGridOptions {
  return useMemo(
    () => ({
      getRowStyle: 'highlightRow',
    }),
    [],
  );
}

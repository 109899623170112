/* eslint-disable @typescript-eslint/no-restricted-imports -- Overridden MUI component is only allowed in this file that overrides it */

import type {
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
} from '@mui/material/Autocomplete';
import MuiAutocomplete from '@mui/material/Autocomplete';
import type { AutocompleteProps } from '@mui/material/Autocomplete/Autocomplete';
import type { SxProps, Theme } from '@mui/material/styles';

import CondorTextField from 'shared/components/text-field/CondorTextField';

import type { DropdownOption } from 'shared/lib/types';
/* eslint-enable @typescript-eslint/no-restricted-imports */

type MuiAutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> = Pick<
  AutocompleteProps<DropdownOption<T>, Multiple, DisableClearable, FreeSolo>,
  | 'defaultValue'
  | 'filterSelectedOptions'
  | 'freeSolo'
  | 'fullWidth'
  | 'getOptionLabel'
  | 'limitTags'
  | 'multiple'
  | 'onChange'
  | 'renderTags'
  | 'value'
>;

type Props<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> = MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo> & {
  open?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  loading?: boolean;
  noOptionsText?: string;
  options: Array<DropdownOption<T>>;
  label: string;
  errorMsg?: boolean | string;
  placeholder?: string;
  size?: 'medium' | 'small';
  sx?: SxProps<Theme>;
  listboxSx?: SxProps<Theme>;
  disabled?: boolean;
  required?: boolean;
  renderOption?: (
    props: RenderOptionProps,
    option: DropdownOption<T>,
    state: AutocompleteRenderOptionState,
  ) => React.ReactNode;
  isOptionEqualToValue?: (
    option: DropdownOption<T>,
    value: DropdownOption<T>,
  ) => boolean;
  freeSolo?: FreeSolo;
  autoSelect?: boolean;
  selectOnFocus?: boolean;
  disableClearable?: DisableClearable;
  limitTags?: number;
  clearOnBlur?: boolean;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
};

function Autocomplete<
  T,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
>(props: Props<T, Multiple, DisableClearable, FreeSolo>) {
  const {
    open,
    onOpen,
    onClose,
    loading,
    noOptionsText,
    options,
    value,
    defaultValue,
    label,
    errorMsg = '',
    placeholder,
    size = 'small',
    sx,
    listboxSx,
    disabled = false,
    fullWidth,
    required = false,
    freeSolo,
    autoSelect = false,
    selectOnFocus,
    disableClearable,
    isOptionEqualToValue,
    renderOption,
    onChange,
    getOptionLabel,
    filterSelectedOptions,
    multiple,
    limitTags = 4,
    clearOnBlur,
    onBlur,
    renderInput = (params: AutocompleteRenderInputParams) => (
      <CondorTextField
        {...params}
        errors={errorMsg}
        label={label}
        placeholder={placeholder}
        required={required}
        size={size}
      />
    ),
    ...otherProps
  } = props;

  return (
    <MuiAutocomplete
      ListboxProps={{ sx: listboxSx }}
      autoSelect={autoSelect}
      clearOnBlur={clearOnBlur}
      defaultValue={defaultValue}
      disableClearable={disableClearable}
      disabled={disabled}
      filterSelectedOptions={filterSelectedOptions}
      freeSolo={freeSolo}
      fullWidth={fullWidth}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      limitTags={limitTags}
      loading={loading}
      multiple={multiple}
      noOptionsText={noOptionsText}
      open={open}
      options={options}
      renderInput={renderInput}
      renderOption={renderOption}
      selectOnFocus={selectOnFocus}
      sx={sx}
      value={value}
      disablePortal
      onBlur={onBlur}
      onChange={onChange}
      onClose={onClose}
      onOpen={onOpen}
      {...otherProps}
    />
  );
}

export type RenderOptionProps = React.HTMLAttributes<
  HTMLDivElement | HTMLLIElement
> & {
  key?: number | string;
};

/* eslint-disable @typescript-eslint/no-restricted-imports -- Overridden MUI component is only allowed in this file that overrides it */
export { autocompleteClasses } from '@mui/material/Autocomplete';
/* eslint-enable @typescript-eslint/no-restricted-imports */

export default Autocomplete;

import type { SvgIconComponent } from '@mui/icons-material'; // eslint-disable-line @typescript-eslint/no-restricted-imports  -- This is a special type, not an icon
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

type Props = {
  icon: SvgIconComponent;
  text: string;
  onClick: (event: React.SyntheticEvent) => void;
};

function ParametersMenuHeader({ icon: Icon, text, onClick }: Props) {
  return (
    <ListItem disablePadding>
      <ListItemButton onClick={onClick}>
        <ListItemIcon
          sx={{
            minWidth: 4,
            mr: 1.5,
          }}
        >
          <Icon
            sx={{
              color: 'primary.dark',
            }}
          />
        </ListItemIcon>
        <ListItemText
          primary={text}
          primaryTypographyProps={{
            variant: 'subtitle2',
          }}
        />
      </ListItemButton>
    </ListItem>
  );
}

export default ParametersMenuHeader;

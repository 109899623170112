import { useMemo } from 'react';

import type { CondorGridOptions } from 'shared/components/ag-grid/types';

import type { PatientActivityRow } from 'shared/lib/types';

export default function usePatientActivityGridOptions(
  _isOpenPeriod: boolean,
): CondorGridOptions<PatientActivityRow> {
  return useMemo(
    () => ({
      // this is used for row-level-comments as rowId, so ideally should not be changed
      getRowId: 'site_number',
      getRowStyle: 'highlightRow',
      groupDisplayType: 'singleColumn',
      groupTotalRow: 'bottom',
      grandTotalRow: 'bottom',
      suppressAggFuncInHeader: true,
      suppressFieldDotNotation: true,
      showOpenedGroup: true,
      autoGroupColumnDef: { headerName: 'Region', pinned: 'left' },
    }),
    [],
  );
}

import { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import NavigationTabs from 'shared/components/tabs/NavigationTabs';

import { selectForecast } from 'forecasting/state/slices/forecastSlice';
import { getForecastingTabWithContractContainer } from 'routes';

import { useGetContractContainersWithFiltersQuery } from 'shared/api/rtkq/contractcontainers';

import { FORECASTED_OCC_EXPENSE_PATH } from './ExpensesTab';

function ContractContainerTabs() {
  const navigate = useNavigate();
  const forecast = useSelector(selectForecast);
  const { data: contractContainers } = useGetContractContainersWithFiltersQuery(
    {
      trial: forecast.trial,
      vendor_type: 'CRO',
    },
  );

  const { contractContainerSlug } = useParams<{
    contractContainerSlug: string;
  }>();

  useEffect(() => {
    const initialTab =
      contractContainerSlug ?? contractContainers?.[0]?.trace_id;
    if (initialTab && !contractContainerSlug) {
      navigate(
        getForecastingTabWithContractContainer(
          forecast.trace_id,
          'expenses',
          initialTab,
        ),
      );
    }
  }, [navigate, contractContainerSlug, contractContainers, forecast.trace_id]);

  if (!contractContainers || !contractContainerSlug) {
    return null;
  }

  const contractContainerTabs = contractContainers.map((container) => ({
    key: container.trace_id,
    link: getForecastingTabWithContractContainer(
      forecast.trace_id,
      'expenses',
      container.trace_id,
    ),
    label: container.vendor_name ?? container.contract_id,
  }));

  contractContainerTabs.push({
    key: FORECASTED_OCC_EXPENSE_PATH,
    link: getForecastingTabWithContractContainer(
      forecast.trace_id,
      'expenses',
      FORECASTED_OCC_EXPENSE_PATH,
    ),
    label: 'Other Clinical Contracts',
  });

  return (
    <NavigationTabs
      activeTab={contractContainerSlug}
      tabs={contractContainerTabs}
      generateUrl={(id) =>
        getForecastingTabWithContractContainer(
          forecast.trace_id,
          'expenses',
          id,
        )
      }
    />
  );
}

export default ContractContainerTabs;

import { type ForwardedRef, type ReactElement, forwardRef } from 'react';

import type { SxProps } from '@mui/material/styles';

import type {
  AgGridRef,
  UserDisplayOptions,
} from 'shared/components/ag-grid/CondorAgGrid';
import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import useGridColDefs from 'shared/components/ag-grid/hooks/useGridColDefs';
import useGridOptions from 'shared/components/ag-grid/hooks/useGridOptions';

import useCroInvestigatorFeesGridOptions from 'accruals/pages/clinical-expenses/cro/hooks/useCroInvestigatorFeesGridOptions';
import withPeriodSpecificGridWrapper from 'shared/lib/periods/withPeriodSpecificGridWrapper';
import { PeriodGridBlobType } from 'shared/lib/types';

import type { apiJSON } from 'shared/api/rtkq/apiJSON';

import useContractAndPeriodWithVersions from '../../shared/hooks/useContractAndPeriodWithVersions';
import useCroInvestigatorFeesExpenseGridColumnDefs from '../hooks/useCroInvestigatorFeesExpenseGridColumnDefs';
import useCroInvestigatorFeesExpenseRows from '../hooks/useCroInvestigatorFeesExpenseRows';

type Props = {
  overlayNoRowsTemplate?: string;
  sx?: SxProps;
  userDisplayOptions: UserDisplayOptions;
};

function CroInvestigatorFeesExpensesGrid(
  props: Props,
  ref: ForwardedRef<AgGridRef<apiJSON> | undefined>,
): ReactElement {
  const { sx, overlayNoRowsTemplate, userDisplayOptions } = props;

  const { contractVersionTraceId, periodTraceId, loadingContractInformation } =
    useContractAndPeriodWithVersions();

  const columnDefs = useGridColDefs(
    useCroInvestigatorFeesExpenseGridColumnDefs,
  );
  const rowData = useCroInvestigatorFeesExpenseRows(
    contractVersionTraceId,
    periodTraceId,
    loadingContractInformation,
  );
  const gridOptions = useGridOptions(useCroInvestigatorFeesGridOptions);

  return (
    <CondorAgGrid
      ref={ref}
      columnDefs={columnDefs}
      gridOptions={gridOptions}
      overlayNoRowsTemplate={overlayNoRowsTemplate}
      rowData={rowData}
      sx={sx}
      userDisplayOptions={userDisplayOptions}
    />
  );
}

export default withPeriodSpecificGridWrapper(
  forwardRef(CroInvestigatorFeesExpensesGrid),
  PeriodGridBlobType.CRO_INVESTIGATOR_FEES_EXPENSE_GRID,
);

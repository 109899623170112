import { useState } from 'react';

import AddIcon from '@mui/icons-material/Add';

import DashboardCard from 'shared/ui/dashboard-card/DashboardCard';

import CroVersionHistoryGrid from 'accruals/pages/clinical-expenses/cro/grids/CroVersionHistoryGrid';
import useContractAndPeriodWithVersions from 'accruals/pages/clinical-expenses/shared/hooks/useContractAndPeriodWithVersions';
import ContractVersionWizard from 'accruals/wizards/contract-version-wizard/ContractVersionWizard';
import PeriodAwareButton from 'shared/lib/periods/PeriodAwareButton';

function CroContractVersionHistorySection() {
  const { contractContainerPeriodMenuItemTraceId, contractContainerTraceId } =
    useContractAndPeriodWithVersions();
  const [openContractVersionWizard, setOpenContractVersionWizard] =
    useState<boolean>(false);

  return (
    <DashboardCard
      title="Version History"
      titleAction={
        <PeriodAwareButton
          permissions={['canEditTrialInfo']}
          startIcon={<AddIcon />}
          testId="add_historical_version"
          variant="outlined"
          onClick={() => setOpenContractVersionWizard(true)}
        >
          Add Historical Version
        </PeriodAwareButton>
      }
    >
      <CroVersionHistoryGrid
        overlayNoRowsTemplate="No versions available."
        parentMenuItem={contractContainerPeriodMenuItemTraceId}
      />

      {openContractVersionWizard && contractContainerTraceId !== undefined && (
        <ContractVersionWizard
          contractContainerId={contractContainerTraceId}
          contractStatus="SUPERSEDED"
          contractVersionTraceId={undefined}
          showVoidedDate
          onClose={() => setOpenContractVersionWizard(false)}
        />
      )}
    </DashboardCard>
  );
}

export default CroContractVersionHistorySection;

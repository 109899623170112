import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { getNumberCellConfig } from 'shared/components/ag-grid-cells/config';
import type { CondorColDef } from 'shared/components/ag-grid/types';

import { selectForecast } from 'forecasting/state/slices/forecastSlice';
import { FORECASTED_TOTAL } from 'shared/columnHeaders';

import { useGenerateForecastQuery } from 'shared/api/rtkq/forecasts';

import {
  actualMonthColDefs,
  forecastMonthColDefs,
} from './forecastMonthColDefs';

const useOperationalForecastSitesColDefs = (
  showActuals: boolean,
): CondorColDef[] => {
  const forecast = useSelector(selectForecast);
  const { currentData: forecastGrids } = useGenerateForecastQuery(
    forecast.trace_id,
  );

  return useMemo(() => {
    const forecastedMonths = Object.keys(forecastGrids?.sites[0] ?? {}).filter(
      (key) => key.startsWith('forecasted_month_'),
    );

    return [
      {
        field: 'type',
        hide: true,
        rowGroup: true,
      },
      {
        field: 'region_name',
        rowGroup: true,
        headerName: 'Region',
        pinned: 'left',
        width: 100,
      },

      {
        field: 'actual_month_total',
        headerName: 'LTD Total',
        ...getNumberCellConfig(),
        aggFunc: 'sum',
        pinned: 'left',
        width: 100,
      },
      {
        field: 'forecasted_month_total',
        headerName: FORECASTED_TOTAL,
        ...getNumberCellConfig(),
        aggFunc: 'sum',
        pinned: 'left',
        width: 100,
      },
      {
        field: 'grand_total',
        headerName: 'Grand total',
        ...getNumberCellConfig(),
        aggFunc: 'sum',
        pinned: 'left',
        width: 100,
      },
      {
        headerName: 'Actual',
        children: actualMonthColDefs(
          forecastGrids?.actual_months ?? [],
          false,
          !showActuals,
        ),
      },
      {
        headerName: 'Forecasted',
        children: forecastMonthColDefs(forecastedMonths, false),
      },
    ];
  }, [forecastGrids, showActuals]);
};

export default useOperationalForecastSitesColDefs;

import { useMemo } from 'react';

import cloneDeep from 'lodash/cloneDeep';

import type {
  IntegrationResponse,
  UnprocessedRecordResponse,
} from 'shared/lib/types';

import { useGetUnprocessedRecordsQuery } from 'shared/api/rtkq/integrations';

function processIntegrationRows(
  integrationGrid?: { rows: UnprocessedRecordResponse[] } | undefined,
) {
  return integrationGrid
    ? cloneDeep(
        integrationGrid.rows.map((eachRow) => ({
          ...eachRow,
          isParent: eachRow.parent === null,
          row_id: eachRow.trace_id,
        })),
      )
    : undefined;
}

export default function useIntegrationRows(
  integration: IntegrationResponse,
): UnprocessedRecordResponse[] | undefined {
  const { currentData: unProcessedData } = useGetUnprocessedRecordsQuery(
    integration.trace_id,
  );

  return useMemo(() => {
    if (unProcessedData === undefined) {
      return undefined;
    }
    return processIntegrationRows({ rows: unProcessedData });
  }, [unProcessedData]);
}

import type { ICellRendererParams } from '@ag-grid-community/core';
import Box from '@mui/material/Box';

import Checkbox from 'shared/ui/checkbox/Checkbox';

function AgGridSetProcedurePartOfVisitCheckboxCellRenderer(
  params: ICellRendererParams,
) {
  const { setValue, data, colDef } = params;

  function onChange(checked: boolean) {
    setValue?.(checked);
  }

  const value = colDef?.field ? !!data[colDef.field] : false;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 33,
      }}
    >
      <Checkbox
        checked={value}
        disableRipple
        onChange={(_, checked) => onChange(checked)}
      />
    </Box>
  );
}

export default AgGridSetProcedurePartOfVisitCheckboxCellRenderer;

/*
██████╗░██╗░░░░░███████╗░█████╗░░██████╗███████╗  ██████╗░███████╗░█████╗░██████╗░
██╔══██╗██║░░░░░██╔════╝██╔══██╗██╔════╝██╔════╝  ██╔══██╗██╔════╝██╔══██╗██╔══██╗
██████╔╝██║░░░░░█████╗░░███████║╚█████╗░█████╗░░  ██████╔╝█████╗░░███████║██║░░██║
██╔═══╝░██║░░░░░██╔══╝░░██╔══██║░╚═══██╗██╔══╝░░  ██╔══██╗██╔══╝░░██╔══██║██║░░██║
██║░░░░░███████╗███████╗██║░░██║██████╔╝███████╗  ██║░░██║███████╗██║░░██║██████╔╝
╚═╝░░░░░╚══════╝╚══════╝╚═╝░░╚═╝╚═════╝░╚══════╝  ╚═╝░░╚═╝╚══════╝╚═╝░░╚═╝╚═════╝░

The only thing that should go inside this file are things that ONLY need the company, trial,
period, and period version loaded and ready. If you add anything for other parts of the app,
like forecasting, this will likely not work as expected and possibly break our menu structure.

This is a XXXLayout instead of a container as we only want this to render once, and not unmount
when we stay within the accruals pages so we don't cause the menu to flicker.

If you have any questions, please ask in #engineering
*/

import { Outlet } from 'react-router-dom';

import AccrualsPageTitle from 'accruals/components/page-titles/AccrualsPageTitle';
import ClosedPeriodBanner from 'accruals/components/periods/ClosedPeriodBanner';
import AccrualsSidebar from 'accruals/components/sidebar/AccrualsSidebar';

import PageContainer from 'shared/containers/page-container/PageContainer';
import PageContentContainer from 'shared/containers/page-content-container/PageContentContainer';

import withAccrualPageRequiredData from 'accruals/api/hocs/withAccrualPageRequiredData';

function AccrualsLayout() {
  return (
    <PageContainer>
      <AccrualsSidebar />
      <PageContentContainer>
        <AccrualsPageTitle />
        <ClosedPeriodBanner />
        <Outlet />
      </PageContentContainer>
    </PageContainer>
  );
}

export default withAccrualPageRequiredData(AccrualsLayout);

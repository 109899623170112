import Box from '@mui/material/Box';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';

import MetricItem from './MetricItem';

function CohortMetrics() {
  return (
    <Box display="flex" gap={8}>
      <MetricItem
        label="Cohort edc name"
        value="Not mapped"
        icon={
          <WarningAmberOutlinedIcon color="warning" sx={{ fontSize: '18px' }} />
        }
      />
      <MetricItem
        label="EDc name mapping"
        value="0/10 names mapped"
        icon={
          <WarningAmberOutlinedIcon color="warning" sx={{ fontSize: '18px' }} />
        }
      />
    </Box>
  );
}

export default CohortMetrics;

import type { SxProps } from '@mui/material/styles';

import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';

import useOperationalForecastSitesColDefs from '../hooks/useOperationalForecastSitesColDefs';
import useOperationalForecastSitesGridOptions from '../hooks/useOperationalForecastSitesGridOptions';
import useOperationalForecastSitesRows from '../hooks/useOperationalForecastSitesRows';

type Props = {
  overlayNoRowsTemplate?: string;
  showActuals: boolean;
  sx?: SxProps;
};

function OperationalForecastSitesGrid({
  overlayNoRowsTemplate,
  showActuals,
  sx,
}: Props) {
  const colDefs = useOperationalForecastSitesColDefs(showActuals);
  const gridOptions = useOperationalForecastSitesGridOptions();
  const { loading, rows } = useOperationalForecastSitesRows();

  return (
    <CondorAgGrid
      columnDefs={colDefs}
      domLayout="autoHeight"
      gridOptions={gridOptions}
      loading={loading}
      overlayNoRowsTemplate={overlayNoRowsTemplate}
      rowData={rows}
      sx={sx}
    />
  );
}

export default OperationalForecastSitesGrid;

import type { ReactNode } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Empty from 'shared/assets/icons/Empty';
import Button from 'shared/ui/button/Button';

type Props = {
  buttonIcon?: ReactNode;
  buttonText: string;
  description: string;
  testId: string;
  title: string;
  onButtonClick: () => void;
};

function EmptyStateMessage(props: Props) {
  const { title, description, buttonText, buttonIcon, onButtonClick, testId } =
    props;
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center',
        mt: 10,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'column',
          alignItems: 'center',
        }}
      >
        <Empty sx={{ height: 80, width: 80 }} />
        <Typography mt={2} variant="h6">
          {title}
        </Typography>
        <Typography color="secondary" mt={1} variant="body1">
          {description}
        </Typography>
        <Button
          color="primary"
          startIcon={buttonIcon}
          sx={{ mt: 4 }}
          testId={testId}
          variant="outlined"
          onClick={onButtonClick}
        >
          {buttonText}
        </Button>
      </Box>
    </Box>
  );
}

export default EmptyStateMessage;

import { useMemo } from 'react';

import HistoricInvoiceListingHeader from './components/HistoricInvoiceListingHeader';
import HistoricInvoiceListingGrid from './grids/HistoricInvoiceListingGrid';

function HistoricInvoiceListing() {
  return (
    <>
      <HistoricInvoiceListingHeader />
      <HistoricInvoiceListingGrid
        overlayNoRowsTemplate="No historic invoices added."
        sx={useMemo(() => ({ height: '100%' }), [])}
      />
    </>
  );
}

export default HistoricInvoiceListing;

import { useEffect } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import GradientCircularProgress from 'shared/components/progress-bar/GradientCircularProgress';

import type { IntegrationResponse, PeriodResponse } from 'shared/lib/types';
import WizardStep from 'shared/wizards/steps/WizardStep';

import { useExportDataToIntegrationMutation } from 'shared/api/rtkq/integrations';

type Props = {
  integration: IntegrationResponse;
  period: PeriodResponse;
};

function ExportLoadingStep(props: Props) {
  const { integration, period } = props;

  const [exportData, { isLoading, isError, isSuccess }] =
    useExportDataToIntegrationMutation();

  useEffect(() => {
    void (async () => {
      await exportData({
        integration: integration.trace_id,
        period: period.trace_id,
      });
    })();
  }, [integration.trace_id, period.trace_id, exportData]);

  return (
    <WizardStep disableNextButton={isLoading || isError}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 10,
          width: '100%',
          height: '100%',
        }}
      >
        {isLoading && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              gap: 10,
            }}
          >
            <GradientCircularProgress />
            <Typography variant="h4">
              Exporting data to {integration.vendor_name}
            </Typography>
          </Box>
        )}
        {isSuccess && 'Success'}
        {isError && 'Error'}
      </Box>
    </WizardStep>
  );
}

export default ExportLoadingStep;

import type { CommentLocationType } from 'shared/lib/types';

import { useGetCommentsCountGroupedByRowIdQuery } from 'shared/api/rtkq/comments';

import type { CommentQuery } from './usePageCommentsCountLoader';
import { buildCommentQuery } from './usePageCommentsCountLoader';

export default function useRowCommentsCountLoader(
  location: CommentLocationType,
  locationSlug?: string,
) {
  const commentQuery: CommentQuery = buildCommentQuery(location, locationSlug);
  const { currentData } = useGetCommentsCountGroupedByRowIdQuery(commentQuery);

  return currentData ?? {};
}

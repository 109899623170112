import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import {
  PatientAssessmentTimelinePortionEnum,
  type PatientCohort,
  type TrialResponse,
} from 'shared/lib/types';
import { selectTrial } from 'shared/state/slices/trialSlice';

import { useGetPatientCohortsByTrialQuery } from 'shared/api/rtkq/patientcohorts';

import { mapPatientCohortFromApiResponse } from '../mapper';

const getEmptyCohort = (trial: TrialResponse): PatientCohort => ({
  name: '',
  trialId: trial.trace_id,
  orderIndex: 0,
  patientAssessments: [
    {
      name: '',
      patientCohort: '',
      isScreen: false,
      isScreenfail: false,
      isDroppedCompleted: false,
      isEnroll: false,
      orderIndex: 0,
      dayOfProtocol: null,
      timelinePortion: PatientAssessmentTimelinePortionEnum.OTHER,
    },
    {
      name: '',
      patientCohort: '',
      isScreen: false,
      isScreenfail: false,
      isDroppedCompleted: false,
      isEnroll: false,
      orderIndex: 1,
      dayOfProtocol: null,
      timelinePortion: PatientAssessmentTimelinePortionEnum.OTHER,
    },
    {
      name: '',
      patientCohort: '',
      isScreen: false,
      isScreenfail: false,
      isDroppedCompleted: false,
      isEnroll: false,
      orderIndex: 2,
      dayOfProtocol: null,
      timelinePortion: PatientAssessmentTimelinePortionEnum.OTHER,
    },
  ],
});

function usePatientCohorts(fallbackToEmptyCohorts = true) {
  const trial = useSelector(selectTrial);
  const { currentData: existingCohorts, isLoading } =
    useGetPatientCohortsByTrialQuery(trial.trace_id);

  return useMemo(() => {
    if (existingCohorts?.length) {
      const fetchedPatientCohorts = existingCohorts.map(
        mapPatientCohortFromApiResponse,
      );
      const cohorts = fetchedPatientCohorts.map((patientCohort) => {
        const cohort = { ...patientCohort };
        if (cohort.traceId) {
          if (cohort.pizzaName) {
            cohort.mappedName = cohort.pizzaName;
          }

          cohort.patientAssessments = cohort.patientAssessments.map(
            (patientAssessment) => {
              const assessment = { ...patientAssessment };
              if (assessment.traceId && assessment.pizzaName) {
                assessment.mappedName = assessment.pizzaName;
              }

              return assessment;
            },
          );
        }

        return cohort;
      });

      return { patientCohorts: [...cohorts], isLoading };
    }

    return {
      patientCohorts: fallbackToEmptyCohorts ? [getEmptyCohort(trial)] : [],
      isLoading,
    };
  }, [existingCohorts, fallbackToEmptyCohorts, trial]);
}

export default usePatientCohorts;

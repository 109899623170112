import type { ReactElement } from 'react';

import type { SxProps } from '@mui/material/styles';

import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import useGridColDefs from 'shared/components/ag-grid/hooks/useGridColDefs';
import useGridOptions from 'shared/components/ag-grid/hooks/useGridOptions';

import withPeriodSpecificGridWrapper from 'shared/lib/periods/withPeriodSpecificGridWrapper';
import { PeriodGridBlobType } from 'shared/lib/types';

import useOccContractBudgetColumnDefs from '../hooks/useOccContractBudgetColumnDefs';
import useOccContractBudgetGridOptions from '../hooks/useOccContractBudgetGridOptions';
import useOccContractBudgetRows from '../hooks/useOccContractBudgetRows';

type Props = { overlayNoRowsTemplate?: string; sx?: SxProps };

function OccContractBudgetGrid(props: Props): ReactElement {
  const { overlayNoRowsTemplate, sx } = props;
  const gridOptions = useGridOptions(useOccContractBudgetGridOptions);
  const columnDefs = useGridColDefs(useOccContractBudgetColumnDefs);
  const rows = useOccContractBudgetRows();

  return (
    <CondorAgGrid
      columnDefs={columnDefs}
      domLayout="autoHeight"
      gridOptions={gridOptions}
      overlayNoRowsTemplate={overlayNoRowsTemplate}
      rowData={rows}
      sx={sx}
    />
  );
}

export default withPeriodSpecificGridWrapper(
  OccContractBudgetGrid,
  PeriodGridBlobType.OCC_CONTRACT_BUDGET,
  true,
);

import Box from '@mui/material/Box';

import successIcon from '../icons/success.svg';

type Props = {
  importedRowsCount: number;
};

function UploadSuccessImportSection(props: Props) {
  const { importedRowsCount } = props;
  return (
    <>
      <img alt="Success" height={120} src={successIcon} />
      <Box color="success.dark" fontSize="18px" lineHeight="1.5">
        Import successfully processed!
      </Box>
      <Box color="grey.900" display="flex" gap={4}>
        <span>
          Rows imported: <b>{importedRowsCount}</b>
        </span>
      </Box>
    </>
  );
}

export default UploadSuccessImportSection;

import KabobMenu from 'shared/components/kabob-menu/KabobMenu';

import useSavePatientVisit from 'accruals/wizards/visit-schedules/hooks/useSavePatientVisit';
import type { PatientAssessment, PatientCohort } from 'shared/lib/types';
import { getNewCopyName } from 'shared/state/slices/visitSchedulesSlice';

import { useDeletePatientAssessmentMutation } from 'shared/api/rtkq/patientassessments';

function VisitsCohortMenu(data: PatientAssessment, cohort: PatientCohort) {
  const [savePatientVisit] = useSavePatientVisit();
  const [deleteVisit] = useDeletePatientAssessmentMutation();

  async function onDuplicateVisit() {
    const { orderIndex, name } = data;
    const { patientAssessments } = cohort;
    await savePatientVisit({
      ...data,
      name: getNewCopyName(
        name,
        patientAssessments.map((item) => item.name),
      ),
      orderIndex: orderIndex + 1,
      isNew: true,
    });
  }

  async function onDeleteVisit() {
    const { traceId } = data;

    if (traceId) {
      await deleteVisit(traceId);
    }
  }

  return (
    <KabobMenu
      size="small"
      options={[
        {
          label: 'Duplicate',
          onClick: () => void onDuplicateVisit(),
        },
        {
          label: 'Delete',
          onClick: () => void onDeleteVisit(),
        },
      ]}
    />
  );
}

export default VisitsCohortMenu;

import type {
  CellClassParams,
  CellClickedEvent,
  CellRendererSelectorResult,
  ColDef,
  IAggFuncParams,
  ICellRendererParams,
  IRowNode,
  SelectionChangedEvent,
  ValueFormatterParams,
} from '@ag-grid-community/core';
import * as Sentry from '@sentry/react';

import { getTrialMoneyCellConfig } from 'shared/components/ag-grid-cells/config';

import * as routes from 'routes';
import { isTopsideAdjustment } from 'shared/helpers/gridData';
import {
  CRO_CONTRACT_TABS,
  formatShortMonthYear,
  humanizeContractVersion_OLD,
  humanizeCostCategory,
} from 'shared/helpers/helpers';
import type { CurrencyViewMode } from 'shared/lib/currency-toggle-group/CurrencyToggleGroup';
import type { PaletteMode } from 'shared/lib/types';
import { addNullableFloats } from 'utils';

import type {
  CondorAggFunc,
  CondorCellRendererParams,
  CondorCellRendererSelector,
  CondorCellStyle,
  CondorColDef,
  CondorOnCellClicked,
  CondorRenderIf,
  CondorRenderIfCondition,
  CondorValueFormatter,
  CustomCellRendererParams,
} from '../types';
import {
  boldCell,
  highlightCell,
  highlightGroup,
  journalEntryGLAccountColumn,
  patientJourneyExpectedVisit,
  patientJourneyExpectedVisitV2,
} from './shared';

/*
██████╗░██╗░░░░░███████╗░█████╗░░██████╗███████╗  ██████╗░███████╗░█████╗░██████╗░
██╔══██╗██║░░░░░██╔════╝██╔══██╗██╔════╝██╔════╝  ██╔══██╗██╔════╝██╔══██╗██╔══██╗
██████╔╝██║░░░░░█████╗░░███████║╚█████╗░█████╗░░  ██████╔╝█████╗░░███████║██║░░██║
██╔═══╝░██║░░░░░██╔══╝░░██╔══██║░╚═══██╗██╔══╝░░  ██╔══██╗██╔══╝░░██╔══██║██║░░██║
██║░░░░░███████╗███████╗██║░░██║██████╔╝███████╗  ██║░░██║███████╗██║░░██║██████╔╝
╚═╝░░░░░╚══════╝╚══════╝╚═╝░░╚═╝╚═════╝░╚══════╝  ╚═╝░░╚═╝╚══════╝╚═╝░░╚═╝╚═════╝░

If you modify these, please ensure that you never remove a specific value
(even if you think it should renamed). Period closed blobs can still have the old
value and we don't want to break the older period closed blobs

If you have any questions, please ask in #engineering
*/

const CONDITIONS: Record<
  CondorRenderIfCondition,
  (params: ICellRendererParams) => boolean
> = {
  hasContractVersionTraceId: ({ data }: ICellRendererParams) =>
    data?.contract_version_trace_id !== undefined,
  hasContract: ({ data }: ICellRendererParams) => data?.hasContract === true,
  hasContractOrGroup: ({ data, node }: ICellRendererParams) =>
    data?.hasContract === true ||
    data?.hasGroup === true ||
    node.group === true,
  hasContractOrGroupOrBottomLineAdjustment: ({
    data,
    node,
  }: ICellRendererParams) =>
    data?.hasContract === true ||
    data?.hasGroup === true ||
    data?.isBottomLineAdjustment === true ||
    node.group === true,
  hasAiP: ({ data }: ICellRendererParams) => data?.hasAip === true,
  hasAiPOrGroup: ({ data, node }: ICellRendererParams) =>
    data?.hasAip === true || data?.hasGroup === true || node.group === true,
  hasAiPOrGroupOrBottomLineAdjustment: ({ data, node }: ICellRendererParams) =>
    data?.hasAip === true ||
    data?.hasGroup === true ||
    data?.isBottomLineAdjustment === true ||
    node.group === true,
  hasAipOrIsGridGroupOrBla: ({ data, node }: ICellRendererParams) =>
    data?.hasAip === true ||
    data?.isBottomLineAdjustment === true ||
    node.group === true,
  hasVendor: ({ data }: ICellRendererParams) => data?.hasVendor === true,
  hasVendorOrGroupOrBottomLineAdjustment: ({
    data,
    node,
  }: ICellRendererParams) =>
    data?.hasVendor === true ||
    data?.hasGroup === true ||
    data?.isBottomLineAdjustment === true ||
    node.group === true,
  hasVendorOrIsGridGroupOrBla: ({ data, node }: ICellRendererParams) =>
    data?.hasVendor === true ||
    data?.isBottomLineAdjustment === true ||
    node.group === true,
  hasVendorOrGroup: ({ data, node }: ICellRendererParams) =>
    data?.hasVendor === true || data?.hasGroup === true || node.group === true,
  isGroupOrRow: ({ data }: ICellRendererParams) =>
    data?.type === 'GROUP' || data?.type === 'ROW',
  isGroupOrRowOrTopsideAdjustment: (params: ICellRendererParams) =>
    params.data?.type === 'GROUP' ||
    params.data?.type === 'ROW' ||
    isTopsideAdjustment(params),
  isNotChild: ({ data }: ICellRendererParams) => data?.type !== 'CHILD',
  isNotDataGroup: ({ data }: ICellRendererParams) => data?.type !== 'GROUP',
  isFooter: ({ node }: ICellRendererParams) => !!node.footer,
  isNotTopsideAdjustment: (params: ICellRendererParams) =>
    !isTopsideAdjustment(params),
  isNeitherFooterNorTopsideAdjustment: (params: ICellRendererParams) =>
    !params.node.footer && !isTopsideAdjustment(params),
  isNotGroup: ({ node }: ICellRendererParams) => !node.group,
};

const poCompletenessCellRendererSelector = (
  params: ICellRendererParams,
): CellRendererSelectorResult => {
  if (params.node.level === 0 && !params.node.key) {
    return {
      component: 'AgGridCustomCellRenderer',
      params: {
        severity: 'error',
        value: 'Not found in contracts',
        startIcon: 'error-icon',
      },
    };
  }

  return { component: 'agGroupCellRenderer' };
};

const forecastParameterCellRendererSelector = (
  params: ICellRendererParams,
): CellRendererSelectorResult => {
  const { iconTooltipMessage } = params.data;

  if (iconTooltipMessage) {
    return {
      component: 'AgGridCustomCellRenderer',
      params: {
        endIcon: 'info-icon',
        iconTooltipMessage,
      },
    };
  }

  return { component: undefined };
};

const visitNameCellRendererSelector = (
  params: ICellRendererParams,
): CellRendererSelectorResult => {
  const { name } = params.data;

  if (!name) {
    return {
      component: 'AgGridCustomCellRenderer',
      params: {
        severity: 'error',
        startIcon: 'circle-icon',
        iconTooltipMessage:
          'The name you entered already exists. Enter a unique visit name.',
        placeholder: 'Add name',
      },
    };
  }

  return { component: undefined };
};

const siteMatrixContractDateRendererSelector = (
  params: ICellRendererParams,
): CellRendererSelectorResult => {
  const component = params.data.allow_change_contract_date
    ? 'AgGridCustomCellRenderer'
    : 'AgGridEmptyCellRenderer';

  if (params.data.contract_dates_invalid) {
    return {
      component,
      params: {
        severity: 'warning',
        startIcon: 'circle-icon',
        tooltipMessage:
          'There are either missing or overlapping dates to this site contract.',
        placeholder: 'Select date',
      },
    };
  }

  if (params.data.current_site_contract) {
    return {
      component,
      params: {
        severity: 'success',
        startIcon: 'circle-icon',
        tooltipMessage: 'This is the current site contract.',
        placeholder: 'Select date',
      },
    };
  }

  return { component, params: {} };
};

const currentContractValueRendererSelector = (
  params: ICellRendererParams,
): CellRendererSelectorResult => {
  const valueRendererParams = params.data?.contract_value_mismatch
    ? {
        severity: 'warning',
        startIcon: 'circle-icon',
        tooltipMessage:
          'The contract record and uploaded budget values do not match. Please review the contract record and budget.',
      }
    : {};
  const component = 'AgGridMoneyCellRenderer';
  return { component, params: valueRendererParams };
};

const poCompletenessCellRendererParams = (
  params: ICellRendererParams,
): Partial<CustomCellRendererParams> | undefined => {
  if (params.node.level !== 1) {
    return;
  }

  if (params.node.data.poAmount !== null) {
    return { severity: 'success', startIcon: 'check-icon' };
  }

  return { severity: 'error', startIcon: 'error-icon' };
};

const optionalCurrencyNumberCellRendererSelector = ({
  data,
}: ICellRendererParams): CellRendererSelectorResult => ({
  component: data?.isEmpty
    ? 'AgGridEmptyCellRenderer'
    : 'AgGridCustomCellRenderer',
  params: data?.trialCurrency
    ? getTrialMoneyCellConfig().cellRendererParams
    : undefined,
});

const convertCellStyle = (
  themeMode: PaletteMode,
  cellStyle?: CondorCellStyle,
) => {
  if (cellStyle === undefined) {
    return;
  }

  switch (cellStyle) {
    case 'boldCell':
      return { cellStyle: boldCell };
    case 'highlightCell':
      return {
        cellStyle: (params: CellClassParams) =>
          highlightCell(params, themeMode),
      };
    case 'highlightGroup':
      return { cellStyle: highlightGroup };
    case 'journalEntryGLAccountColumn':
      return { cellStyle: journalEntryGLAccountColumn };
    case 'patientJourneyExpectedVisit':
      return { cellStyle: patientJourneyExpectedVisit };
    case 'patientJourneyExpectedVisitV2':
      return { cellStyle: patientJourneyExpectedVisitV2 };
    default:
      return { cellStyle };
  }
};

const convertCellRendererSelector = (
  cellRendererSelector?: CondorCellRendererSelector,
) => {
  if (cellRendererSelector === undefined) {
    return;
  }

  switch (cellRendererSelector) {
    case 'currentContractValueRendererSelector':
      return { cellRendererSelector: currentContractValueRendererSelector };
    case 'poCompletenessAutoGroupConfig.cellRendererSelector':
    case 'poCompletenessCellRendererSelector':
      return { cellRendererSelector: poCompletenessCellRendererSelector };
    case 'forecastParameterCellRendererSelector':
      return { cellRendererSelector: forecastParameterCellRendererSelector };
    case 'visitNameCellRendererSelector':
      return { cellRendererSelector: visitNameCellRendererSelector };
    case 'siteMatrixContractDateRendererSelector': {
      return { cellRendererSelector: siteMatrixContractDateRendererSelector };
    }
    case 'optionalCurrencyNumberCellRendererSelector': {
      return {
        cellRendererSelector: optionalCurrencyNumberCellRendererSelector,
      };
    }
    default:
      return { cellRendererSelector };
  }
};

const convertRenderIf = (
  navigate: (path: string) => void,
  themeMode: PaletteMode,
  renderIf?: CondorRenderIf,
) => {
  if (renderIf === undefined) {
    return;
  }

  const callCondition = (
    condition: CondorRenderIfCondition,
    params: ICellRendererParams,
  ) => {
    if (typeof CONDITIONS[condition] === 'function') {
      return CONDITIONS[condition](params);
    }

    Sentry.captureMessage(
      `This shouldn't be possible. RenderIf is not callable for condition: ${condition}.`,
    );
    return false;
  };

  return {
    ...convertColDef(navigate, themeMode, renderIf.colDef),
    cellRenderer: undefined,
    cellRendererParams: undefined,
    cellRendererSelector: (params: ICellRendererParams) => {
      let isValid: boolean;
      if (Array.isArray(renderIf.condition)) {
        isValid = renderIf.condition.every((condition) =>
          callCondition(condition, params),
        );
      } else {
        isValid = callCondition(renderIf.condition, params);
      }

      return isValid
        ? {
            component: renderIf.colDef.cellRenderer,
            params: renderIf.colDef.cellRendererParams,
          }
        : { component: 'AgGridEmptyCellRenderer' };
    },
  };
};

const convertCellRendererParams = (
  cellRendererParams?: CondorCellRendererParams,
) => {
  if (cellRendererParams === undefined) {
    return;
  }

  switch (cellRendererParams) {
    case 'poCompletenessPoCheck':
      return { cellRendererParams: poCompletenessCellRendererParams };
    default:
      return { cellRendererParams };
  }
};

const convertAggFunc = (aggFunc?: CondorAggFunc) => {
  if (aggFunc === undefined) {
    return;
  }

  function getTotal(expenseValue: number, contractValue: number) {
    return expenseValue && contractValue
      ? (expenseValue * 100) / contractValue
      : 0;
  }

  // https://www.ag-grid.com/javascript-data-grid/aggregation-custom-functions/#multi-column-aggregation
  function percentRecognizedAggFunc(params: IAggFuncParams) {
    let reconciled_expenseSum = 0;
    let default_po_amountSum = 0;

    for (const childNode of params.rowNode.childrenAfterGroup ?? []) {
      reconciled_expenseSum +=
        (childNode.data ?? childNode.aggData)?.reconciled_expense ?? 0;
      default_po_amountSum +=
        (childNode.data ?? childNode.aggData)?.default_contract_value ?? 0;
    }

    return getTotal(reconciled_expenseSum, default_po_amountSum);
  }

  function underOverAggFunc(params: IAggFuncParams) {
    let underOverExpenseSum = 0;
    let default_po_amountSum = 0;

    for (const childNode of params.rowNode.childrenAfterGroup ?? []) {
      underOverExpenseSum +=
        (childNode.data ?? childNode.aggData)?.under_over ?? 0;
      default_po_amountSum +=
        (childNode.data ?? childNode.aggData)?.default_contract_value ?? 0;
    }

    return getTotal(underOverExpenseSum, default_po_amountSum);
  }

  function totalLabelAggFunc() {
    return 'Total';
  }

  function poCompleteness(params: IAggFuncParams<unknown, number>) {
    const sum = params.values.filter(Boolean).reduce((acc, el) => acc + el, 0);
    if (params.rowNode.level === 0) {
      return sum > 0 ? sum : undefined;
    }

    return sum;
  }

  /** @deprecated Use expensePercentRecognizedTotal instead */
  function croPercentRecognizedTotal(params: IAggFuncParams) {
    const calculateGrandTotal = !params.rowNode.parent;
    let totalPriceSum = 0;
    let monthlyExpenseValuesTotalSum = 0;

    for (const childNode of params.rowNode.childrenAfterGroup ?? []) {
      const source = calculateGrandTotal ? childNode.aggData : childNode.data;
      /** @deprecated totalPrice is no longer used — this is here to support older closed periods */
      totalPriceSum +=
        source?.totalPriceInCompanyCurrency ?? source?.totalPrice ?? 0;
      monthlyExpenseValuesTotalSum += source?.monthlyExpenseValuesTotal ?? 0;
    }

    return getTotal(monthlyExpenseValuesTotalSum, totalPriceSum);
  }

  /**
   * @deprecated
   * This had a bug and we now use new fields to calculate the total in occPercentRecognizedTotalV2.
   * Keeping this one for closed periods.
   */
  function occPercentRecognizedTotal(params: IAggFuncParams) {
    const calculateGrandTotal = !params.rowNode.parent;
    let totalPriceInCompanyCurrencySum = 0;
    let monthlyExpenseValuesTotalSum = 0;

    for (const childNode of params.rowNode.childrenAfterGroup ?? []) {
      if (calculateGrandTotal) {
        totalPriceInCompanyCurrencySum +=
          childNode.allLeafChildren?.reduce(
            (sum, leafChild) =>
              sum +
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- the value can clearly be there or can be undefined
              ((leafChild.data.totalPriceInCompanyCurrency as number) ?? 0),
            0,
          ) ?? 0;
        monthlyExpenseValuesTotalSum +=
          childNode.allLeafChildren?.reduce(
            (sum, leafChild) =>
              sum +
              (leafChild.data.monthlyExpenseValuesTotalToggleable
                ?.trial as number),
            0,
          ) ?? 0;
      } else {
        const source = childNode.data;
        totalPriceInCompanyCurrencySum +=
          source.totalPriceInCompanyCurrency ?? 0;
        monthlyExpenseValuesTotalSum +=
          source.monthlyExpenseValuesTotal ??
          source.monthlyExpenseValuesTotalToggleable?.trial ??
          0;
      }
    }

    return getTotal(
      monthlyExpenseValuesTotalSum,
      totalPriceInCompanyCurrencySum,
    );
  }

  function occPercentRecognizedTotalV2(params: IAggFuncParams) {
    const calculateGrandTotal = !params.rowNode.parent;
    let totalPriceInCompanyCurrencySum = 0;
    let monthlyExpenseValuesTotalSum = 0;

    for (const childNode of params.rowNode.childrenAfterGroup ?? []) {
      if (calculateGrandTotal) {
        totalPriceInCompanyCurrencySum +=
          childNode.aggData.totalPriceToggleable ?? 0;
        monthlyExpenseValuesTotalSum +=
          childNode.aggData.monthlyExpenseValuesTotalToggleable ?? 0;
      } else {
        const source = childNode.data;
        totalPriceInCompanyCurrencySum +=
          source.totalPriceToggleable?.native ?? 0;
        monthlyExpenseValuesTotalSum +=
          source.monthlyExpenseValuesTotal ??
          source.monthlyExpenseValuesTotalToggleable?.native ??
          0;
      }
    }

    return getTotal(
      monthlyExpenseValuesTotalSum,
      totalPriceInCompanyCurrencySum,
    );
  }

  function computeGrandTotal(
    childNode: IRowNode,
    key: string,
    currencyViewMode: CurrencyViewMode,
  ) {
    return (
      childNode.allLeafChildren?.reduce(
        (sum, leafChild) =>
          sum + (leafChild.data[key][currencyViewMode] as number),
        0,
      ) ?? 0
    );
  }

  function expensePercentRecognizedTotal(params: IAggFuncParams) {
    const calculateGrandTotal = !params.rowNode.parent;
    let totalPriceInCompanyCurrencySum = 0;
    let monthlyExpenseValuesTotalSum = 0;
    for (const childNode of params.rowNode.childrenAfterGroup ?? []) {
      if (calculateGrandTotal) {
        totalPriceInCompanyCurrencySum += computeGrandTotal(
          childNode,
          'current_contract_value',
          'trial',
        );
        monthlyExpenseValuesTotalSum += computeGrandTotal(
          childNode,
          'total_ltd_expense',
          'trial',
        );
      } else {
        const source = childNode.data;
        totalPriceInCompanyCurrencySum +=
          source.current_contract_value.trial ?? 0;
        monthlyExpenseValuesTotalSum += source.total_ltd_expense.trial ?? 0;
      }
    }

    return getTotal(
      monthlyExpenseValuesTotalSum,
      totalPriceInCompanyCurrencySum,
    );
  }

  function expenseToggleablePercentRecognizedTotal(params: IAggFuncParams) {
    const calculateGrandTotal = !params.rowNode.parent;

    let totalPriceNative = 0;
    let totalPriceTrial = 0;
    let totalMonthlyExpensesNative = 0;
    let totalMonthlyExpensesTrial = 0;

    for (const childNode of params.rowNode.childrenAfterGroup ?? []) {
      if (calculateGrandTotal) {
        totalPriceNative += computeGrandTotal(
          childNode,
          'current_contract_value',
          'native',
        );
        totalPriceTrial += computeGrandTotal(
          childNode,
          'current_contract_value',
          'trial',
        );
        totalMonthlyExpensesNative += computeGrandTotal(
          childNode,
          'total_ltd_expense',
          'native',
        );
        totalMonthlyExpensesTrial += computeGrandTotal(
          childNode,
          'total_ltd_expense',
          'trial',
        );
      } else {
        const { data } = childNode;
        totalPriceNative += data.current_contract_value.native ?? 0;
        totalMonthlyExpensesNative += data.total_ltd_expense.native ?? 0;
        totalPriceTrial += data.current_contract_value.trial ?? 0;
        totalMonthlyExpensesTrial += data.total_ltd_expense.trial ?? 0;
      }
    }

    return {
      trial: getTotal(totalMonthlyExpensesTrial, totalPriceTrial),
      native: getTotal(totalMonthlyExpensesNative, totalPriceNative),
    };
  }

  /** Handles the special custom aggregation function for the cro recon grid */
  function croReconTotal(params: IAggFuncParams) {
    const { field } = params.colDef;
    if (!field) {
      return undefined;
    }

    const calculateGrandTotal = !params.rowNode.parent;

    let sum = 0;
    for (const childNode of params.rowNode.childrenAfterGroup ?? []) {
      const source = calculateGrandTotal ? childNode.aggData : childNode.data;
      const { data } = childNode;
      if (data?.type !== 'CHILD') {
        sum = addNullableFloats(sum, source[field]);
      }
    }

    return sum !== 0 ? sum : null;
  }

  /** Handles the special custom aggregation function for the cro recon grid */
  function croReconTotalToggleable(params: IAggFuncParams) {
    const {
      colDef: { colId },
      context: { currencyViewMode },
    } = params;
    if (!colId || currencyViewMode === undefined) {
      return undefined;
    }

    const calculateGrandTotal = !params.rowNode.parent;
    let sum = 0;
    for (const childNode of params.rowNode.childrenAfterGroup ?? []) {
      if (childNode.data?.type !== 'CHILD') {
        sum = addNullableFloats(
          sum,
          calculateGrandTotal
            ? childNode.aggData[colId]
            : childNode.data[colId]?.[currencyViewMode],
        );
      }
    }

    return sum !== 0 ? sum : null;
  }

  function croReconPercentRecognizedTotal(params: IAggFuncParams) {
    const calculateGrandTotal = !params.rowNode.parent;
    let aipLtdExpensedSum = 0;
    let aipPercentRecognizedDenominator = 0;

    for (const childNode of params.rowNode.childrenAfterGroup ?? []) {
      const source = calculateGrandTotal ? childNode.aggData : childNode.data;
      aipLtdExpensedSum +=
        source?.aipLtdExpensedToggleable ?? source?.aipLtdExpensed ?? 0;
      aipPercentRecognizedDenominator +=
        source?.aipPercentRecognizedDenominator ?? 0;
    }

    return getTotal(aipLtdExpensedSum, aipPercentRecognizedDenominator);
  }

  switch (aggFunc) {
    case 'percentRecognizedAggFunc':
    case 'percentRecognized':
      return { aggFunc: percentRecognizedAggFunc };
    case 'underOver':
      return { aggFunc: underOverAggFunc };
    case 'totalLabelAggFunc':
    case 'totalLabel':
      return { aggFunc: totalLabelAggFunc };
    case 'poCompletenessConfig.poAmount.aggFunc':
    case 'poCompletenessConfig.totalContractValue.aggFunc':
      return { aggFunc: poCompleteness };
    case 'croPercentRecognizedTotal':
      return { aggFunc: croPercentRecognizedTotal };
    case 'occPercentRecognizedTotal':
      return { aggFunc: occPercentRecognizedTotal };
    case 'occPercentRecognizedTotalV2':
      return { aggFunc: occPercentRecognizedTotalV2 };
    case 'expensePercentRecognizedTotal':
      return { aggFunc: expensePercentRecognizedTotal };
    case 'expenseToggleablePercentRecognizedTotal':
      return { aggFunc: expenseToggleablePercentRecognizedTotal };
    case 'croReconTotal':
      return { aggFunc: croReconTotal };
    case 'croReconTotalToggleable':
      return { aggFunc: croReconTotalToggleable };
    case 'croReconPercentRecognizedTotal':
      return { aggFunc: croReconPercentRecognizedTotal };
    default:
      return { aggFunc };
  }
};

const convertOnCellClicked = (
  navigate: (path: string) => void,
  onCellClicked?: CondorOnCellClicked,
) => {
  if (onCellClicked === undefined) {
    return undefined;
  }

  function handleCroContractVersionRedirect({
    api,
  }: CellClickedEvent | SelectionChangedEvent) {
    const selectedRows = api.getSelectedRows();
    if (selectedRows.length === 1) {
      const { contract_container_trace_id, contract_version_trace_id, status } =
        selectedRows[0];
      switch (status) {
        case 'CURRENT':
          navigate(
            routes.getClinicalExpensesTabByContractContainer(
              contract_container_trace_id,
              CRO_CONTRACT_TABS.CURRENT_CONTRACT,
            ),
          );
          break;
        case 'AIP':
          navigate(
            routes.getClinicalExpensesTabByContractContainer(
              contract_container_trace_id,
              CRO_CONTRACT_TABS.AMENDMENT_IN_PROGRESS,
            ),
          );
          break;
        default:
          navigate(
            routes.getClinicalExpensesByContractVersion(
              contract_container_trace_id,
              contract_version_trace_id,
            ),
          );
          break;
      }
    }
  }

  function handleOccContractVersionRedirect({
    api,
  }: CellClickedEvent | SelectionChangedEvent) {
    const selectedRows = api.getSelectedRows();
    if (selectedRows.length === 1) {
      const { contract_container_trace_id, contract_version_trace_id } =
        selectedRows[0];

      if (contract_version_trace_id === undefined) {
        return;
      }

      navigate(
        routes.getOccByContractVersion(
          contract_container_trace_id,
          contract_version_trace_id,
        ),
      );
    }
  }

  function handleAnyContractVersionRedirect({
    api,
  }: CellClickedEvent | SelectionChangedEvent) {
    const selectedRows = api.getSelectedRows();
    if (selectedRows.length === 1) {
      const {
        contract_container_trace_id,
        contract_version_trace_id,
        vendor_type,
      } = selectedRows[0];
      if (vendor_type === 'CRO') {
        navigate(
          routes.getClinicalExpensesByContractVersion(
            contract_container_trace_id,
            contract_version_trace_id,
          ),
        );
      } else {
        navigate(
          routes.getOccByContractVersion(
            contract_container_trace_id,
            contract_version_trace_id,
          ),
        );
      }
    }
  }

  switch (onCellClicked) {
    case 'handleCroContractVersionRedirect':
      return {
        onCellClicked: (params: CellClickedEvent) =>
          handleCroContractVersionRedirect(params),
      };
    case 'handleOccContractVersionRedirect':
      return {
        onCellClicked: (params: CellClickedEvent) =>
          handleOccContractVersionRedirect(params),
      };
    case 'handleAnyContractVersionRedirect':
      return {
        onCellClicked: (params: CellClickedEvent) =>
          handleAnyContractVersionRedirect(params),
      };
    default:
      return { onCellClicked };
  }
};

const convertValueFormatter = (valueFormatter?: CondorValueFormatter) => {
  if (valueFormatter === undefined) {
    return;
  }

  switch (valueFormatter) {
    case 'humanizeCostCategory':
      return {
        valueFormatter: ({ value }: ValueFormatterParams) =>
          humanizeCostCategory(value),
      };
    case 'humanizeCostCategoryWithMixedVendors':
      return {
        valueFormatter: ({ node, value }: ValueFormatterParams) =>
          node?.data?.vendor_type === 'CRO'
            ? humanizeCostCategory(value)
            : value,
      };
    case 'humanizeContractVersion':
      // Not used anymore, but keeping it here for historical periods
      return {
        valueFormatter: ({ value }: ValueFormatterParams) =>
          humanizeContractVersion_OLD(value),
      };
    case 'humanizePeriodDate':
      return {
        valueFormatter: ({ value }: ValueFormatterParams) =>
          formatShortMonthYear(value),
      };
    default:
      return { valueFormatter };
  }
};

export default function convertColDef<T>(
  navigate: (path: string) => void,
  themeMode: PaletteMode,
  colDef?: CondorColDef<T>,
): ColDef<T> | undefined {
  if (colDef === undefined) {
    return undefined;
  }

  const {
    aggFunc,
    cellRendererSelector,
    cellStyle,
    cellRendererParams,
    onCellClicked,
    valueFormatter,
    renderIf,
    ...rest
  } = colDef;

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- // TODO: I can't figure this one out, but I suspect we might have a bug somewhere.
  return {
    ...rest,
    ...convertAggFunc(aggFunc),
    ...convertCellRendererSelector(cellRendererSelector),
    ...convertCellStyle(themeMode, cellStyle),
    ...convertCellRendererParams(cellRendererParams),
    ...convertOnCellClicked(navigate, onCellClicked),
    ...convertValueFormatter(valueFormatter),

    // this one needs to be at the end as it touches other properties
    ...convertRenderIf(navigate, themeMode, renderIf),
  } as ColDef<T>;
}

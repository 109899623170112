import { useMemo } from 'react';

import type {
  ForecastedGridsResponse,
  LineItemForecasting,
} from 'shared/lib/types';

import type { apiJSON } from 'shared/api/rtkq/apiJSON';

function processForecastedExpenseRowData(
  expenseDataRows: apiJSON[] | undefined,
  generatedForecast: ForecastedGridsResponse | undefined,
  forecasted_line_items: Record<string, LineItemForecasting | null> | undefined,
) {
  if (
    !forecasted_line_items ||
    !expenseDataRows ||
    !generatedForecast?.expenses.length
  ) {
    return expenseDataRows;
  }

  const forecastedMonths = Object.keys(
    generatedForecast.expenses[0] ?? {},
  ).filter((key) => key.startsWith('forecasted_month_'));

  let grossTotal = 0;

  for (const expenseRow of expenseDataRows) {
    if (expenseRow.type === 'BOTTOM_LINE_ADJUSTMENT') {
      continue;
    }

    grossTotal += expenseRow.monthlyExpenseValuesTotal;
  }

  return expenseDataRows.map((originalRow) => {
    if (originalRow.type === 'BOTTOM_LINE_ADJUSTMENT') {
      const forecastedMonthValues: Record<string, number> = {};
      let forecastedTotal = 0;
      for (const [index, month] of forecastedMonths.entries()) {
        const totalMonthlyForecast = Object.values(
          forecasted_line_items,
        ).reduce(
          (sum, lineItem) => sum + (lineItem?.forecasts_by_month[index] ?? 0),
          0,
        );
        const bla_percent = originalRow.monthlyExpenseValuesTotal / grossTotal;
        forecastedMonthValues[month] = totalMonthlyForecast * bla_percent;
        forecastedTotal += forecastedMonthValues[month];
      }
      return {
        ...originalRow,
        ...forecastedMonthValues,
        forecasted_total: forecastedTotal,
      };
    }

    const lineItemForecast = forecasted_line_items[originalRow.displayRowNum];
    if (!lineItemForecast) {
      return originalRow;
    }

    const { forecasts_by_month, ...otherFields } = lineItemForecast;
    const forecastFields: Record<
      string,
      Date | string[] | number | string | null
    > = Object.fromEntries(
      Object.entries(otherFields).filter(([key]) =>
        key.startsWith('forecast_'),
      ),
    );

    const forecastedMonthValues: Record<string, number> = {};
    let forecastedExpense = originalRow.monthlyExpenseValuesTotal ?? 0;

    for (const [index, month] of forecastedMonths.entries()) {
      const value = forecasts_by_month[index] ?? 0;
      forecastedMonthValues[month] = value;
      forecastedExpense += value;
    }

    if (forecastFields.forecast_activityDriverTraceId === null) {
      forecastFields.forecast_unitType = originalRow.unitType;
      forecastFields.forecast_unitDetail = originalRow.unitDetail;
      forecastFields.forecast_startDate = originalRow.startDate;
      forecastFields.forecast_endDate = originalRow.endDate;
      forecastFields.forecast_assignedRegion = originalRow.assignedRegion;
      forecastFields.forecast_assignedRegionGroup =
        originalRow.assignedRegionGroup;
    }

    return {
      ...originalRow,
      ...forecastFields,
      ...forecastedMonthValues,
      forecasted_total: forecastedExpense,
    };
  });
}

export default function useForecastedExpenseRowData(
  expenseData: apiJSON[] | undefined,
  generatedForecast: ForecastedGridsResponse | undefined,
  forecasted_line_items: Record<string, LineItemForecasting | null> | undefined,
) {
  return useMemo(
    () =>
      processForecastedExpenseRowData(
        expenseData,
        generatedForecast,
        forecasted_line_items,
      ),
    [expenseData, generatedForecast, forecasted_line_items],
  );
}

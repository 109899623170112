import { useMemo } from 'react';

import type { CondorGridOptions } from 'shared/components/ag-grid/types';

import type { IntegrationAuditLogResponse } from 'shared/lib/types';

// Integration audit log is intentionally not period aware as it would be too much data to save
export default function useIntegrationAuditLogGridOptions(): CondorGridOptions<IntegrationAuditLogResponse> {
  return useMemo(
    () => ({
      getRowId: 'trace_id',
      rowModelType: 'serverSide',
      excelStyles: [
        {
          id: 'multiline',
          alignment: {
            wrapText: true,
          },
        },
      ],
    }),
    [],
  );
}

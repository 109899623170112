import type { LockGridResponse, LockGridUpsert } from 'shared/lib/types';

import constructApi from './constructApi';

const LOCK_GRIDS = 'LOCK_GRIDS';

const apiEndpointFactory = constructApi<LockGridResponse, LockGridUpsert>(
  'lockgrids',
).withTags(LOCK_GRIDS);

const api = apiEndpointFactory.inject(() => ({
  getLockGridWithFilters: apiEndpointFactory.getWithFilters('-updated_at'),
  upsertLockGrid: apiEndpointFactory.create<LockGridUpsert>([], 'upsert'),
}));

export const { useGetLockGridWithFiltersQuery, useUpsertLockGridMutation } =
  api;

import { useMemo } from 'react';

import type {
  ForecastedGridsResponse,
  InvestigatorFeesMonthlyValues,
} from 'shared/lib/types';

export default function useForecastedInvestigatorFeesRows(
  generatedForecast: ForecastedGridsResponse | undefined,
  contractContainerSlug: string | undefined,
  forecastedMonths: string[],
  monthly_actuals: InvestigatorFeesMonthlyValues | undefined,
) {
  return useMemo(() => {
    if (!contractContainerSlug) return undefined;
    const investigatorLineItems =
      generatedForecast?.line_items_forecasting[contractContainerSlug]
        ?.investigator_line_items;
    if (!investigatorLineItems) return undefined;

    const patientCosts: Record<string, number[] | undefined> = {};
    const adminCosts: Record<string, number[] | undefined> = {};
    const procedureCosts: Record<string, number[] | undefined> = {};
    for (const {
      patient_costs,
      admin_costs,
      procedure_costs,
    } of investigatorLineItems) {
      for (const [region, cost] of Object.entries(patient_costs)) {
        patientCosts[region] ||= [];
        patientCosts[region]?.push(cost);
      }

      for (const [region, cost] of Object.entries(procedure_costs)) {
        procedureCosts[region] ||= [];
        procedureCosts[region]?.push(cost);
      }

      const totalAdminCosts: Record<string, number> = {};
      for (const [_assumptionGroup, regionCosts] of Object.entries(
        admin_costs,
      )) {
        for (const [region, cost] of Object.entries(regionCosts)) {
          totalAdminCosts[region] ||= 0;
          totalAdminCosts[region] += cost;
        }
      }
      for (const [region, cost] of Object.entries(totalAdminCosts)) {
        adminCosts[region] ||= [];
        adminCosts[region]?.push(cost);
      }
    }

    const rows = [];

    for (const [region, costs] of Object.entries(patientCosts)) {
      rows.push({
        id: `${region}-visit_cost`,
        costCategory: 'visit_cost',
        region,
        forecasted_total: costs?.reduce(
          (prev: number, next: number) => prev + next,
          0,
        ),
        total_ltd_expense: Object.values(
          monthly_actuals?.visit_costs[region] ?? {},
        ).reduce((prev: number, next: number) => prev + next, 0),
        ...Object.fromEntries(
          [...forecastedMonths.entries()].map(
            ([i, month]: [number, string]) => [month, costs?.[i]],
          ),
        ),
        ...Object.fromEntries(
          Object.entries(monthly_actuals?.visit_costs[region] ?? {}).map(
            ([key, value]) => [`actual_month_${key}`, value],
          ),
        ),
      });
    }

    for (const [region, costs] of Object.entries(procedureCosts)) {
      rows.push({
        id: `${region}-procedure_cost`,
        costCategory: 'procedure_cost',
        region,
        forecasted_total: costs?.reduce(
          (prev: number, next: number) => prev + next,
          0,
        ),
        total_ltd_expense: Object.values(
          monthly_actuals?.procedure_costs[region] ?? {},
        ).reduce((prev: number, next: number) => prev + next, 0),
        ...Object.fromEntries(
          [...forecastedMonths.entries()].map(
            ([i, month]: [number, string]) => [month, costs?.[i]],
          ),
        ),
        ...Object.fromEntries(
          Object.entries(monthly_actuals?.procedure_costs[region] ?? {}).map(
            ([key, value]) => [`actual_month_${key}`, value],
          ),
        ),
      });
    }

    for (const [region, costs] of Object.entries(adminCosts)) {
      rows.push({
        id: `${region}-admin_cost`,
        costCategory: 'admin_cost',
        region,
        forecasted_total: costs?.reduce(
          (prev: number, next: number) => prev + next,
          0,
        ),
        total_ltd_expense: Object.values(
          monthly_actuals?.admin_costs[region] ?? {},
        ).reduce((prev: number, next: number) => prev + next, 0),
        ...Object.fromEntries(
          [...forecastedMonths.entries()].map(
            ([i, month]: [number, string]) => [month, costs?.[i]],
          ),
        ),
        ...Object.fromEntries(
          Object.entries(monthly_actuals?.admin_costs[region] ?? {}).map(
            ([key, value]) => [`actual_month_${key}`, value],
          ),
        ),
      });
    }

    return rows;
  }, [
    generatedForecast,
    contractContainerSlug,
    forecastedMonths,
    monthly_actuals,
  ]);
}

import { useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import TertiaryNavigationTabs from 'shared/components/tabs/TertiaryNavigationTabs';

import useCohortAndPeriodWithVersions from 'accruals/pages/clinical-expenses/shared/hooks/useCohortAndPeriodWithVersions';
import { getAssociatedVisitsCohort } from 'routes';

function AssociatedVisitsCohortsTabs() {
  const navigate = useNavigate();
  const { activeTab, activeSubTab, cohortId } = useParams<{
    activeTab: string;
    activeSubTab: string;
    cohortId: string;
  }>();
  const { periodSpecificTabItems: finalCohortTabs } =
    useCohortAndPeriodWithVersions();

  const tabs =
    finalCohortTabs?.map((item) => ({
      key: item.key,
      link: getAssociatedVisitsCohort(activeTab, activeSubTab, item.key),
      label: item.title,
      disabled: false,
    })) ?? [];

  useEffect(() => {
    const initialTab = cohortId ?? tabs[0]?.key;

    if (initialTab && !cohortId) {
      navigate(getAssociatedVisitsCohort(activeTab, activeSubTab, initialTab));
    }
  }, [tabs, navigate, cohortId]);

  if (!tabs.length || !cohortId) {
    return null;
  }

  return (
    <TertiaryNavigationTabs
      activeTab={cohortId}
      tabs={tabs}
      generateUrl={(id) =>
        getAssociatedVisitsCohort(activeTab, activeSubTab, id)
      }
    />
  );
}

export default AssociatedVisitsCohortsTabs;

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Radio from '@mui/material/Radio';

type Props = {
  disabled?: boolean;
  radio?: boolean;
  selected?: boolean;
  text: string;
  onClick: (event: React.SyntheticEvent) => void;
};

function ParametersMenuItem({
  text,
  onClick,
  radio,
  selected,
  disabled,
}: Props) {
  return (
    <ListItem disablePadding>
      <ListItemButton disabled={disabled} sx={{ py: 0.5 }} onClick={onClick}>
        {radio && (
          <Radio
            checked={selected}
            disabled={disabled}
            sx={{ p: 0.5, '& .MuiSvgIcon-root': { fontSize: 18 } }}
          />
        )}
        <ListItemText
          primary={text}
          primaryTypographyProps={{
            variant: 'body1',
            ml: radio ? 1.5 : 4.5,
            color: disabled ? 'text.disabled' : 'text.primary',
          }}
        />
      </ListItemButton>
    </ListItem>
  );
}

export default ParametersMenuItem;

import { useMemo } from 'react';

import { skipToken } from '@reduxjs/toolkit/query';

import { applyAdjustments } from 'accruals/wizards/contract-version-wizard/AccountingInfoForm';
import { getBottomLineAdjustmentFromApiResponse } from 'accruals/wizards/contract-version-wizard/helpers';
import type {
  BottomLineAdjustmentResponse,
  ContractCostCategoryInfoResponse,
} from 'shared/lib/types';

import { useGetBottomLineAdjustmentsByContractQuery } from 'shared/api/rtkq/bottomlineadjustments';
import { useGetContractCostCategoryInfoByContractVersionQuery } from 'shared/api/rtkq/contractcostcategoryinfo';

import useContractAndPeriodWithVersions from '../../shared/hooks/useContractAndPeriodWithVersions';

export function processContractBudget(
  costCategoryInfos: ContractCostCategoryInfoResponse[] | undefined,
  bottomLineAdjustments: BottomLineAdjustmentResponse[] | undefined,
  nativeCurrency: string,
) {
  const costCategories = costCategoryInfos?.map((info) => ({
    nativeCurrency,
    traceId: info.trace_id,
    costCategory: info.category,
    glAccount: info.gl_account?.account_number,
    grossValue: Number(info.gross_value),
    netValue: Number(info.gross_value),
  }));

  const blas = bottomLineAdjustments?.map(
    getBottomLineAdjustmentFromApiResponse,
  );

  if (costCategories?.length && blas?.length) {
    const totalGrossValue = costCategories.reduce(
      (acc, record) => acc + record.grossValue,
      0,
    );
    const totalBla = applyAdjustments(totalGrossValue, blas) - totalGrossValue;
    costCategories.push({
      nativeCurrency,
      traceId: 'bottom-line-adjustments',
      costCategory: 'Bottom line adjustments',
      grossValue: totalBla,
      netValue: totalBla,
      glAccount: '',
    });
  }

  return costCategories;
}

export default function useOccContractBudgetRows() {
  const { contractVersionTraceId, contractVersion, contractContainer } =
    useContractAndPeriodWithVersions();

  const { currentData: contractCostCategoryInfos } =
    useGetContractCostCategoryInfoByContractVersionQuery(
      contractVersionTraceId ?? skipToken,
    );

  const { currentData: bottomLineAdjustments } =
    useGetBottomLineAdjustmentsByContractQuery(
      contractVersion?.trace_id ?? skipToken,
    );

  const nativeCurrency = contractContainer?.currency ?? 'USD';

  return useMemo(
    () =>
      processContractBudget(
        contractCostCategoryInfos,
        bottomLineAdjustments,
        nativeCurrency,
      ),
    [bottomLineAdjustments, contractCostCategoryInfos, nativeCurrency],
  );
}

import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import Badge, { badgeClasses } from '@mui/material/Badge';

import IconButton from 'shared/ui/icon-button/IconButton';

type Props = {
  commentsCount: number;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

function CommentsWidgetIcon(props: Props) {
  const { onClick, commentsCount } = props;

  return (
    <Badge
      badgeContent={commentsCount}
      color="primary"
      sx={{
        m: 1,
        cursor: 'pointer',
        [`& .${badgeClasses.badge}`]: {
          right: 8,
          top: 8,
        },
      }}
      onClick={onClick}
    >
      <IconButton>
        {commentsCount ? (
          <CommentOutlinedIcon aria-label="Add comment" />
        ) : (
          <AddCommentOutlinedIcon aria-label="Add comment" />
        )}
      </IconButton>
    </Badge>
  );
}

export default CommentsWidgetIcon;

import type { ComponentType } from 'react';

import Typography from '@mui/material/Typography';

import { CRO_CONTRACT_TABS } from 'shared/helpers/helpers';

import ForecastedDirectFeesExpenseGrid from './ForecastedDirectFeesExpenseGrid';
import type { PeriodSpecificProps } from './ForecastedExpenseGridProps';
import ForecastedInvestigatorFeesExpenseGrid from './ForecastedInvestigatorFeesExpenseGrid';
import ForecastedPassthroughsExpenseGrid from './ForecastedPassthroughsExpenseGrid';

export default function ForecastedCroExpenseGrid(
  props: PeriodSpecificProps & { activeSubTab: string },
) {
  const { activeSubTab, ...rest } = props;

  let Grid: ComponentType<PeriodSpecificProps>;
  switch (activeSubTab) {
    case CRO_CONTRACT_TABS.DIRECT_FEES:
      Grid = ForecastedDirectFeesExpenseGrid;
      break;
    case CRO_CONTRACT_TABS.PASS_THROUGHS:
      Grid = ForecastedPassthroughsExpenseGrid;
      break;
    case CRO_CONTRACT_TABS.INVESTIGATOR_FEES:
      Grid = ForecastedInvestigatorFeesExpenseGrid;
      break;
    default:
      return (
        <Typography variant="body1">Invalid subtab: {activeSubTab}</Typography>
      );
  }
  return <Grid {...rest} />;
}

import type { ReactElement } from 'react';

import type { SxProps } from '@mui/material/styles';

import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import useGridOptions from 'shared/components/ag-grid/hooks/useGridOptions';

import { ForecastParameterType } from 'shared/lib/types';

import useForecastParamEditorColDefs from '../hooks/useForecastParamEditorColDefs';
import useForecastParamEditorGridOptions from '../hooks/useForecastParamEditorGridOptions';
import useForecastParamEditorRows from '../hooks/useForecastParamEditorRows';

type Props = { overlayNoRowsTemplate?: string; sx?: SxProps };

function PatientEnrollmentParamsGrid(props: Props): ReactElement {
  const { sx, overlayNoRowsTemplate } = props;

  const columnDefs = useForecastParamEditorColDefs(
    ForecastParameterType.ENROLLMENT_RATE,
    'New Patients Enrolled',
    'Patients per site per month',
  );
  const gridOptions = useGridOptions(useForecastParamEditorGridOptions);
  const rowData = useForecastParamEditorRows(
    ForecastParameterType.ENROLLMENT_RATE,
  );

  return (
    <CondorAgGrid
      columnDefs={columnDefs}
      domLayout="autoHeight"
      gridOptions={gridOptions}
      overlayNoRowsTemplate={overlayNoRowsTemplate}
      rowData={rowData}
      sx={sx}
    />
  );
}

export default PatientEnrollmentParamsGrid;

import { type ForwardedRef, type ReactElement, forwardRef } from 'react';

import type { SxProps } from '@mui/material/styles';

import type { AgGridRef } from 'shared/components/ag-grid/CondorAgGrid';
import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import useGridColDefs from 'shared/components/ag-grid/hooks/useGridColDefs';
import useGridOptions from 'shared/components/ag-grid/hooks/useGridOptions';

import withPeriodSpecificGridWrapper from 'shared/lib/periods/withPeriodSpecificGridWrapper';
import { PeriodGridBlobType } from 'shared/lib/types';

import type { apiJSON } from 'shared/api/rtkq/apiJSON';

import usePatientActivityColumnDefs from '../hooks/usePatientActivityColumnDefs';
import usePatientActivityGridOptions from '../hooks/usePatientActivityGridOptions';
import usePatientActivityRows from '../hooks/usePatientActivityRows';

type Props = { overlayNoRowsTemplate?: string; sx?: SxProps };

function PatientActivityGrid(
  props: Props,
  ref: ForwardedRef<AgGridRef<apiJSON> | undefined>,
): ReactElement {
  const { sx, overlayNoRowsTemplate } = props;

  const columnDefs = useGridColDefs(usePatientActivityColumnDefs);
  const gridOptions = useGridOptions(usePatientActivityGridOptions);
  const rowData = usePatientActivityRows();

  return (
    <CondorAgGrid
      ref={ref}
      columnDefs={columnDefs}
      gridOptions={gridOptions}
      overlayNoRowsTemplate={overlayNoRowsTemplate}
      rowData={rowData}
      sx={sx}
    />
  );
}

export default withPeriodSpecificGridWrapper(
  forwardRef(PatientActivityGrid),
  PeriodGridBlobType.PATIENT_ACTIVITY,
);

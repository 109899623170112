import type { SxProps } from '@mui/material/styles';

import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';

import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';

import useContracts from '../hooks/useContracts';
import useContractsColumnDefs from '../hooks/useContractsColumnDefs';
import useContractsGridOptions from '../hooks/useContractsGridOptions';

type Props = { overlayNoRowsTemplate?: string; sx?: SxProps };

function CurrentContractsGrid(props: Props) {
  const { overlayNoRowsTemplate, sx } = props;

  const columnDefs = useContractsColumnDefs();
  const gridOptions = useContractsGridOptions();
  const { loading, contracts } = useContracts();

  return (
    <FlexGridContainer>
      <CondorAgGrid
        columnDefs={columnDefs}
        domLayout="autoHeight"
        gridOptions={gridOptions}
        loading={loading}
        overlayNoRowsTemplate={overlayNoRowsTemplate}
        rowData={contracts}
        sx={sx}
      />
    </FlexGridContainer>
  );
}

export default CurrentContractsGrid;

import { useEffect, useMemo, useRef } from 'react';

import type { AgGridRef } from 'shared/components/ag-grid/CondorAgGrid';
import { useCommentsPopup } from 'shared/components/comments/CommentsPopupContext';

import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';

import type { apiJSON } from 'shared/api/rtkq/apiJSON';

import PatientActivityHeader from './components/PatientActivityHeader';
import PatientActivityGrid from './grids/PatientActivityGrid';

function PatientActivityPage() {
  const gridRef = useRef<AgGridRef<apiJSON>>(null);
  const { setGridRef } = useCommentsPopup();

  useEffect(() => {
    setGridRef(gridRef.current);
  }, [setGridRef]);

  return (
    <FlexGridContainer>
      <PatientActivityHeader gridRef={gridRef.current} />
      <PatientActivityGrid
        ref={gridRef}
        overlayNoRowsTemplate="No sites added."
        sx={useMemo(() => ({ height: '100%' }), [])}
      />
    </FlexGridContainer>
  );
}

export default PatientActivityPage;

import type { LockGrid } from 'shared/lib/types';

import useLockGrid from './useLockGrid';

function useLockedGridStatus(grid: LockGrid, locationSlug: string | null) {
  const { isLocked } = useLockGrid(grid, locationSlug);

  return isLocked;
}

export default useLockedGridStatus;

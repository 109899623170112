import type { GenericData, InvoiceSnapshotsResponse } from 'shared/lib/types';

import constructApi from './constructApi';
import { HISTORIC_INVOICE_LISTING_RECORDS_TAG } from './historicinvoicerecords';

const PATH = 'historicinvoicesnapshots';

const apiEndpointFactory = constructApi<InvoiceSnapshotsResponse, GenericData>(
  PATH,
).withTags('HISTORIC_INVOICE_LISTING_SNAPSHOTS', [
  HISTORIC_INVOICE_LISTING_RECORDS_TAG,
]);

const api = apiEndpointFactory.inject(() => ({
  createHistoricInvoiceListingsSnapshotFromCsv: apiEndpointFactory.create(
    [HISTORIC_INVOICE_LISTING_RECORDS_TAG],
    'new_from_csv',
  ),
  deleteHistoricInvoiceListingsSnapshot: apiEndpointFactory.delete(),
}));

export const {
  useCreateHistoricInvoiceListingsSnapshotFromCsvMutation,
  useDeleteHistoricInvoiceListingsSnapshotMutation,
} = api;

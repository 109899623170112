import type {
  CellValueChangedEvent,
  GridReadyEvent,
  ModelUpdatedEvent,
} from '@ag-grid-community/core';
import type { SxProps } from '@mui/material/styles';

import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import useGridOptions from 'shared/components/ag-grid/hooks/useGridOptions';
import type { CondorColDef } from 'shared/components/ag-grid/types';

import type { IntegrationResponse } from 'shared/lib/types';

import useIntegrationGridOptions from '../hooks/useIntegrationGridOptions';
import useIntegrationRows from '../hooks/useIntegrationRows';

type Props = {
  colDefs: CondorColDef[];
  handleCellValueChangedEvent: (event: CellValueChangedEvent) => void;
  handleGridReady: (event: GridReadyEvent) => void;
  handleModelUpdatedEvent: (event: ModelUpdatedEvent) => void;
  integration: IntegrationResponse;
  overlayNoRowsTemplate?: string;
  sx?: SxProps;
};

const defaultColDef: CondorColDef = { sortable: false };

function IntegrationGrid(props: Props) {
  const {
    integration,
    overlayNoRowsTemplate,
    sx,
    handleGridReady,
    handleCellValueChangedEvent,
    handleModelUpdatedEvent,
    colDefs,
  } = props;

  const rowData = useIntegrationRows(integration);
  const gridOptions = useGridOptions(useIntegrationGridOptions);

  return (
    <CondorAgGrid
      columnDefs={colDefs}
      defaultColDef={defaultColDef}
      gridOptions={gridOptions}
      overlayNoRowsTemplate={overlayNoRowsTemplate}
      rowData={rowData}
      sx={sx}
      stopEditingWhenCellsLoseFocus
      onCellValueChanged={handleCellValueChangedEvent}
      onGridReady={handleGridReady}
      onModelUpdated={handleModelUpdatedEvent}
    />
  );
}

export default IntegrationGrid;

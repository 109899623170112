import { useMemo } from 'react';

import { getTrialMoneyCellConfig } from 'shared/components/ag-grid-cells/config';
import type { CondorColDef } from 'shared/components/ag-grid/types';

const useSiteAveragesGridColDefs = (): CondorColDef[] =>
  useMemo(
    () => [
      {
        field: 'row_title',
        headerName: 'Site averages',
        flex: 1,
      },
      {
        field: 'actual_average',
        headerName: 'Actual',
        flex: 1,
        ...getTrialMoneyCellConfig(),
      },
      {
        field: 'forecasted_average',
        headerName: 'Scenario',
        flex: 1,
        ...getTrialMoneyCellConfig(),
      },
    ],
    [],
  );

export default useSiteAveragesGridColDefs;

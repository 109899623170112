import { useEffect, useRef } from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { useSelector } from 'react-redux';

import {
  missingPeriodVersion,
  selectPeriodVersion,
} from 'accruals/state/slices/periodVersionSlice'; // eslint-disable-line import/no-restricted-paths -- this files knows how to handle this empty correctly
import useHasPermission from 'shared/lib/permissions/useHasPermission';
import type { CommentLocationType } from 'shared/lib/types';
import { selectCompany } from 'shared/state/slices/companySlice';

import useIsClosedPeriodVersion from 'shared/api/hooks/useIsClosedPeriodVersion';

import CommentEditor from './CommentEditor';
import CommentListItem from './CommentListItem';
import CommentsHeader from './CommentsHeader';
import EmptyState from './EmptyState';
import useCommentsLoader from './hooks/useCommentsLoader';
import LoadingState from './LoadingState';

type Props = {
  fullHeight?: boolean;
  location: CommentLocationType;
  locationRowId?: string;
  locationSlug: string;
  onClose?: () => void;
};

function CommentsContainer(props: Props) {
  const { location, locationSlug, locationRowId, onClose, fullHeight } = props;
  const anchorRef = useRef<HTMLDivElement>();
  const { comments, isLoading } = useCommentsLoader(
    location,
    locationSlug,
    locationRowId,
  );
  const isPeriodClosed = useIsClosedPeriodVersion();
  const isInitialized = useRef<boolean>(false);
  const userCanComment = useHasPermission(['canAddComments']);

  const currentPeriodVersion = useSelector(selectPeriodVersion);
  const currentCompany = useSelector(selectCompany);

  useEffect(() => {
    if (!comments?.length || isInitialized.current) {
      return;
    }

    isInitialized.current = true;
    anchorRef.current?.scrollIntoView({ behavior: 'instant', block: 'end' });
  }, [comments?.length, isInitialized.current]);

  return (
    <Container sx={{ width: 400 }} disableGutters>
      <Box
        borderRadius={10}
        sx={{
          ...(fullHeight && {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }),
        }}
      >
        <CommentsHeader
          isForTrial={currentPeriodVersion !== missingPeriodVersion}
          onClose={onClose}
        />
        <Divider flexItem />
        <Stack
          divider={<Divider />}
          spacing={0}
          sx={{
            ...(fullHeight
              ? {
                  flex: 1,
                }
              : {
                  height: '50vh',
                  maxHeight: '50vh',
                }),
            overflowY: 'auto',
            '& *': { overflowAnchor: 'none' },
            '& hr:last-of-type': { border: 'none' },
          }}
        >
          {isLoading ? (
            <LoadingState />
          ) : (
            comments?.map((comment) => (
              <CommentListItem
                key={comment.trace_id}
                created_at={comment.created_at}
                files={comment.files}
                location_row_id={comment.location_row_id}
                location_type={comment.location_type}
                text={comment.text}
                trace_id={comment.trace_id}
                user={comment.user}
              />
            ))
          )}
          {!comments?.length && !isLoading && <EmptyState />}
          <Box
            ref={anchorRef}
            sx={{
              overflowAnchor: 'auto',
              height: '10px',
              marginTop: '-10px',
              flexShrink: 0,
            }}
          />
        </Stack>
        {!isPeriodClosed && userCanComment && (
          <>
            <Divider flexItem />
            <CommentEditor
              currentCompany={currentCompany}
              currentPeriodVersion={currentPeriodVersion}
              location={location}
              locationRowId={locationRowId}
              locationSlug={locationSlug}
            />
          </>
        )}
      </Box>
    </Container>
  );
}

export default CommentsContainer;

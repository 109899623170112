import { type ReactElement } from 'react';

import { useParams } from 'react-router-dom';

import PageContentLoadingContainer from 'shared/containers/page-content-loading-container/PageContentLoadingContainer';
import useFeatureFlag from 'shared/helpers/useFeatureFlag';

import withAccrualPageRequiredData from 'accruals/api/hocs/withAccrualPageRequiredData';

import ScheduleOfAssessmentsProceduresSubTabs, {
  SCHEDULE_SUB_TABS,
} from './ScheduleOfAssessmentsProceduresSubTabs';
import ScheduleOfAssessmentsTabs, {
  SCHEDULE_TABS,
} from './ScheduleOfAssessmentsTabs';
import AssociatedVisitsPage from './associated-visits/AssociatedVisitsPage';
import VisitsCohortsTabs from './visits/VisitsCohortsTabs';
import VisitsPage from './visits/VisitsPage';

function ScheduleOfAssessmentsPage(): ReactElement {
  const isScheduleOfAssessmentsNonProceduresEnabled = useFeatureFlag(
    'schedule_of_assessments_non_procedures',
  );
  const { activeTab, activeSubTab } = useParams<{
    activeTab: string;
    activeSubTab: string;
  }>();
  return isScheduleOfAssessmentsNonProceduresEnabled ? (
    <PageContentLoadingContainer
      tabs={
        <>
          <ScheduleOfAssessmentsTabs />
          {activeTab === SCHEDULE_TABS.PROCEDURES && (
            <ScheduleOfAssessmentsProceduresSubTabs />
          )}
          {activeTab === SCHEDULE_TABS.VISITS && <VisitsCohortsTabs />}
        </>
      }
    >
      {activeTab === SCHEDULE_TABS.PROCEDURES &&
        activeSubTab === SCHEDULE_SUB_TABS.ASSOCIATED_VISITS && (
          <AssociatedVisitsPage />
        )}
      {activeTab === SCHEDULE_TABS.VISITS && <VisitsPage />}
    </PageContentLoadingContainer>
  ) : (
    <PageContentLoadingContainer>
      <AssociatedVisitsPage />
    </PageContentLoadingContainer>
  );
}

export default withAccrualPageRequiredData(ScheduleOfAssessmentsPage);

import { useMemo } from 'react';

import type {
  SetFilterValuesFuncParams,
  ValueFormatterParams,
} from '@ag-grid-community/core';
import { format } from 'date-fns/format';
import { useSelector } from 'react-redux';

import { getRelevantChanges } from 'shared/components/ag-grid-cells/renderers/ag-grid-record-cell-renderer/utils';
import type { CondorColDef } from 'shared/components/ag-grid/types';

import { formatShortMonthYear, humanize } from 'shared/helpers/helpers';
import type { AuditLogResponse } from 'shared/lib/types';
import { selectCompany } from 'shared/state/slices/companySlice';

import { useGetContentTypesQuery } from 'shared/api/rtkq/companies';
import { useGetTrialsByCompanyQuery } from 'shared/api/rtkq/trials';

import {
  mapActionsToFilterValues,
  mapContentTypesToFilterValues,
  mapTrialsToFilterValues,
} from './utils';

// Audit log is intentionally not period aware as it would be too much data to save
export default function useAuditLogColumnDefs(
  showAllChanges: boolean,
): Array<CondorColDef<AuditLogResponse>> {
  // this is intentionally done to obfuscate the dev mode in the url a little bit
  const showAllQueryParam = showAllChanges ? 1 : 0;

  const currentCompany = useSelector(selectCompany);

  const { currentData: trials } = useGetTrialsByCompanyQuery(
    currentCompany.trace_id,
  );

  const { currentData: contentTypes } = useGetContentTypesQuery({
    trace_id: currentCompany.trace_id,
    otherParameter: showAllQueryParam.toString(),
  });

  return useMemo(
    () => [
      {
        colId: 'trial',
        headerName: 'Trial',
        valueGetter: `
          return node.data?.trial ? \`\${node.data.trial.indication} • \${node.data.trial.study_id}\` : '';
        `,
        filter: true,
        filterParams: {
          suppressSorting: true,
          values: (params: SetFilterValuesFuncParams) => {
            if (trials) {
              params.success(mapTrialsToFilterValues(trials));
            }
          },
        },
        suppressHeaderMenuButton: true,
        menuTabs: ['filterMenuTab'],
        width: 300,
      },
      {
        colId: 'period',
        headerName: 'Period',
        valueGetter: ({ data }) =>
          data?.period ? formatShortMonthYear(data.period.end_date) : '',
        suppressHeaderMenuButton: true,
        menuTabs: [],
        width: 100,
      },
      {
        colId: 'content_type',
        headerName: 'Type',
        field: 'content_type.name',
        width: 250,
        filter: true,
        filterParams: {
          values: (params: SetFilterValuesFuncParams) => {
            if (contentTypes) {
              params.success(mapContentTypesToFilterValues(contentTypes));
            }
          },
        },
        suppressHeaderMenuButton: true,
        menuTabs: ['filterMenuTab'],
      },
      {
        colId: 'object_representation',
        headerName: 'Object',
        field: 'object_representation',
        flex: 1,
        minWidth: 500,
        suppressHeaderMenuButton: true,
        menuTabs: [],
      },
      {
        colId: 'action',
        headerName: 'Action',
        valueGetter: `
          return node.data?.action === 0 ? 'Created' : (node.data?.action === 1 ? 'Updated' : 'Deleted');
        `,
        filter: true,
        filterParams: {
          suppressSorting: true,
          values: (params: SetFilterValuesFuncParams) => {
            params.success(mapActionsToFilterValues());
          },
        },
        sortable: false,
        menuTabs: ['filterMenuTab'],
        suppressHeaderMenuButton: true,
        width: 80,
      },
      {
        colId: 'changes',
        headerName: 'Changes',
        width: 600,
        field: 'changes',
        sortable: false,
        cellClass: 'multiline',
        valueFormatter: ({ node }: ValueFormatterParams<AuditLogResponse>) => {
          if (node?.data?.changes != null) {
            const changes = getRelevantChanges(node.data.changes);
            return changes
              .reduce<string[]>((acc, change, index) => {
                const hasPreviousValue = change.previousValue !== undefined;
                const hasNewValue = change.newValue !== undefined;

                if (change.key) {
                  acc.push(humanize(change.key));
                }
                acc.push('\n');
                acc.push(
                  `${hasPreviousValue ? (change.previousValue === '' ? '""' : change.previousValue) : ''}${hasNewValue ? `${hasPreviousValue ? ' → ' : ''}${change.newValue === '' ? '""' : change.newValue}` : ''}`,
                );

                if (index !== changes.length - 1) {
                  acc.push('\n');
                  acc.push('\n');
                }

                return acc;
              }, [])
              .join('');
          }
          return '';
        },
        cellDataType: 'text',
        cellRenderer: 'AgGridRecordCellRenderer',
        suppressHeaderMenuButton: true,
        menuTabs: [],
      },
      {
        colId: 'actor',
        headerName: 'User',
        valueGetter: `
          return node.data?.actor ? \`\${node.data.actor.first_name} \${node.data.actor.last_name}\` : '';
        `,
        suppressHeaderMenuButton: true,
        menuTabs: [],
      },
      {
        colId: 'created_at',
        headerName: 'Date',
        valueGetter: ({ data }) =>
          data ? format(data.created_at, 'P p O') : '',
        suppressHeaderMenuButton: true,
        menuTabs: [],
      },
    ],
    [contentTypes, trials],
  );
}

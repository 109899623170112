import { skipToken } from '@reduxjs/toolkit/query';

import type { SnapshotRecordType } from 'shared/lib/types';
import SnapshotRecordsTable from 'shared/wizards/csv-upload-wizards/validate-and-check-table/SnapshotRecordsTable';
import columns from 'shared/wizards/invoice-upload-wizard/columns';
import WizardStep from 'shared/wizards/steps/WizardStep';

import { useGetHistoricInvoiceListingRecordsBySnapshotQuery } from 'shared/api/rtkq/historicinvoicerecords';

type Props = { snapshotId?: string };

function HistoricInvoiceValidationStep(props: Props) {
  const { snapshotId } = props;
  const { currentData: allRecords } =
    useGetHistoricInvoiceListingRecordsBySnapshotQuery(snapshotId ?? skipToken);

  return (
    <WizardStep header="No issues detected. Click on Save & Continue to proceed">
      <SnapshotRecordsTable
        headers={columns}
        rows={allRecords as SnapshotRecordType[]}
      />
    </WizardStep>
  );
}

export default HistoricInvoiceValidationStep;

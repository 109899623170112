import { numberToCurrencyFormat } from 'shared/helpers/formatters';
import { humanize, humanizeCostCategory } from 'shared/helpers/helpers';
import {
  CRUDAction,
  type ContractInfo,
  type GlAccountResponse,
  type VendorType,
} from 'shared/lib/types';
import type { SummaryItem } from 'shared/wizards/review-step/ReviewStep';
import ReviewStep from 'shared/wizards/review-step/ReviewStep';

import { calculateContractValues } from './AccountingInfoForm';

type Props = {
  contractInfo: ContractInfo;
  existingGlAccounts: GlAccountResponse[];
  formErrors: string[];
  header: string;
  isLoading?: boolean;
  vendorType: VendorType;
  onNext?: () => void;
  onNextAsync?: () => Promise<void>;
};

function FinalReview(props: Props) {
  const {
    header,
    formErrors,
    onNext,
    contractInfo,
    onNextAsync,
    existingGlAccounts,
    vendorType,
    isLoading,
  } = props;
  const data: SummaryItem[][] = getFormattedSummaryData(
    contractInfo,
    existingGlAccounts,
    vendorType,
  );

  return (
    <ReviewStep
      formErrors={formErrors}
      formattedSummaryData={data}
      header={header}
      isLoading={isLoading}
      onNext={onNext}
      onNextAsync={onNextAsync}
    />
  );
}

function getGlAccountNumberFromId(
  glAccountId: string | undefined,
  existingGlAccounts: GlAccountResponse[],
): string {
  if (!glAccountId) {
    return '';
  }
  const glAccount = existingGlAccounts.find(
    (account) => account.trace_id === glAccountId,
  );
  return glAccount?.account_number ?? '';
}

function getFormattedSummaryData(
  contractInfo: ContractInfo,
  existingGlAccounts: GlAccountResponse[],
  vendorType: VendorType,
): SummaryItem[][] {
  const { totalNetValue } = calculateContractValues(contractInfo, vendorType);

  return [
    [
      {
        sectionHeader: 'Contract Info',
        editPageNumber: 1,
        rows: [
          { label: 'Version', values: [contractInfo.contractVersion] },
          { label: 'Version name', values: [contractInfo.versionName] },
          { label: 'Effective Date', values: [contractInfo.effectiveDate] },
          {
            label: 'AIP Effective Date',
            values: [contractInfo.aipEffectiveDate],
          },
          { label: 'Void Date', values: [contractInfo.voidedDate] },
          { label: 'PO number', values: [contractInfo.poNumber] },
          {
            label: 'Regions',
            values: [
              contractInfo.trialRegions?.map(({ name }) => name).join(', '),
            ],
          },
          {
            label: 'Region groups',
            values: [
              contractInfo.regionGroups
                ?.filter(({ action }) => action !== CRUDAction.DELETE)
                .map(({ name }) => name)
                .join(', '),
            ],
          },
        ],
      },
      {
        sectionHeader: 'Accounting Information',
        editPageNumber: 2,
        rows: [
          ...(contractInfo.accountInfo.costCategoryInfos
            ?.filter(Boolean)
            .flatMap((costCategoryInfo) => {
              const costCategoryDisplayItem = {
                label: humanizeCostCategory(costCategoryInfo.category),
                values: [
                  `G/L Account- ${getGlAccountNumberFromId(costCategoryInfo.gl_account, existingGlAccounts)}`,
                  `$${numberToCurrencyFormat(costCategoryInfo.gross_value ?? 0)}`,
                ],
              };
              if (costCategoryInfo.category !== 'DIRECT_FEES') {
                return costCategoryDisplayItem;
              }

              const bottomLineAdjustmentDisplayItem = {
                label: 'Bottom Line Adjustments',
                values: contractInfo.accountInfo.bottomLineAdjustments.map(
                  (adjustment) =>
                    adjustment.amountType === 'ABSOLUTE'
                      ? `${humanize(adjustment.adjustmentType?.toLowerCase() ?? '')} $${adjustment.amount}`
                      : `${humanize(adjustment.adjustmentType?.toLowerCase() ?? '')} ${adjustment.amountPercentage}%`,
                ),
              };
              return [costCategoryDisplayItem, bottomLineAdjustmentDisplayItem];
            }) ?? []),
          {
            label: 'Total net contract value',
            values: [`$${numberToCurrencyFormat(totalNetValue)}`],
          },
          {
            label: 'Bonuses and Penalties',
            values: [contractInfo.accountInfo.bonusesAndPenalties ?? '-'],
          },
        ],
      },
      {
        sectionHeader: 'Files',
        editPageNumber: 3,
        rows: contractInfo.documents.map((document) => ({
          label: document.tag,
          values: [document.file_name ?? document.file?.name ?? ''],
        })),
      },
    ],
  ];
}

export default FinalReview;

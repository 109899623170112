import { useRef } from 'react';

import Box from '@mui/system/Box';
import { useDispatch } from 'react-redux';

import type { CommentLocationType } from 'shared/lib/types';
import {
  updateHeader,
  updateIsOpen,
  updateLocation,
  updateLocationRowId,
  updateLocationSlug,
  updateTitle,
} from 'shared/state/slices/commentSlice';

import type { apiJSON } from 'shared/api/rtkq/apiJSON';

import type { AgGridRef } from '../ag-grid/CondorAgGrid';
import { useCommentsPopup } from './CommentsPopupContext';
import CommentsWidgetIcon from './CommentsWidgetIcon';

type Props = {
  commentsCount: number;
  commentsForPage?: boolean;
  gridRef?: AgGridRef<apiJSON> | null;
  header?: string;
  location: CommentLocationType;
  locationRowId?: string;
  locationSlug?: string;
  smallerButton?: boolean;
  title?: string;
};

function CommentsButton(props: Props) {
  const {
    location,
    commentsForPage,
    locationSlug = '',
    locationRowId = '',
    header = 'Comments',
    title = null,
    commentsCount,
    smallerButton = false,
    gridRef,
  } = props;
  const dispatch = useDispatch();
  const buttonRef = useRef<HTMLElement>(null);
  const { setCommentPopupAnchorEl, setGridRef } = useCommentsPopup();
  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    // closing the popup first prevents it from jumping around the screen
    dispatch(updateIsOpen({ isOpen: false }));
    dispatch(updateHeader({ header }));
    dispatch(updateTitle({ title }));
    dispatch(updateLocation({ location }));
    dispatch(updateLocationSlug({ locationSlug }));
    dispatch(updateLocationRowId({ locationRowId }));
    setCommentPopupAnchorEl(buttonRef.current);
    setGridRef(gridRef ?? null);
    dispatch(updateIsOpen({ isOpen: true }));
  };

  return (
    <Box
      ref={buttonRef}
      sx={
        commentsForPage
          ? { position: 'fixed', right: '35px', bottom: '25px' }
          : smallerButton
            ? { scale: 0.79 }
            : {}
      }
    >
      <CommentsWidgetIcon commentsCount={commentsCount} onClick={onClick} />
    </Box>
  );
}

export default CommentsButton;

import Add from '@mui/icons-material/Add';

import Button from 'shared/ui/button/Button';
import LockGridButton from 'shared/components/lock-grid/LockGridButton';
import useLockedGridStatus from 'shared/components/lock-grid/useLockedGridStatus';
import CommentsButton from 'shared/components/comments/CommentsButton';
import usePageCommentsCountLoader from 'shared/components/comments/hooks/usePageCommentsCountLoader';

import withPeriodSpecificGenericWrapper from 'shared/lib/periods/withPeriodSpecificGenericWrapper';
import HasPermission from 'shared/lib/permissions/HasPermission';
import { CommentLocationType, LockGrid } from 'shared/lib/types';

type Props = {
  cohortId: string;
  onAddNewVisit: () => void;
};

function VisitsGridHeader(props: Props) {
  const { cohortId, onAddNewVisit } = props;
  const isGridLocked = useLockedGridStatus(LockGrid.SOA_VISITS, cohortId);
  const commentsCount = usePageCommentsCountLoader(
    CommentLocationType.SOA_VISITS,
    cohortId,
  );

  return (
    <>
      {!isGridLocked && (
        <Button
          startIcon={<Add />}
          testId="add_visit"
          variant="outlined"
          onClick={onAddNewVisit}
        >
          Add visit
        </Button>
      )}
      <HasPermission permissions={['canAddComments']}>
        <CommentsButton
          commentsCount={commentsCount}
          location={CommentLocationType.SOA_VISITS}
          locationSlug={cohortId}
        />
      </HasPermission>
      <LockGridButton grid={LockGrid.SOA_VISITS} locationSlug={cohortId} />
    </>
  );
}

export default withPeriodSpecificGenericWrapper(VisitsGridHeader);

import { useMemo } from 'react';

import type { CellClassParams } from '@ag-grid-community/core';

import {
  getMoneyCellConfig,
  makeEditableIf,
} from 'shared/components/ag-grid-cells/config';
import type { CondorColDef } from 'shared/components/ag-grid/types';

import type { UnprocessedPOResponse } from 'shared/lib/types';
import { IntegrationType } from 'shared/lib/types';

export default function useInvoiceListingIntegrationGridColDefs(): CondorColDef[] {
  return useMemo(() => {
    const ignoredCheck = (data: UnprocessedPOResponse) => !data.ignored;
    const studyIdInvalidCheck = (params: CellClassParams) =>
      (params.data.study_id === '' || params.data.study_id === null) &&
      ignoredCheck(params.data);
    const invoiceAmountInvalidCheck = (params: CellClassParams) =>
      (params.data.invoice_amount === '' ||
        params.data.invoice_amount === '0.00' ||
        params.data.invoice_amount === null ||
        params.data.invoice_amount === 0) &&
      ignoredCheck(params.data);

    return [
      { field: 'trace_id', hide: true, flex: 1 },
      {
        field: 'error',
        headerName: '',
        flex: 0,
        width: 10,
        cellRenderer: 'AgGridIntegrationsErrorRenderer',
      },
      {
        headerName: 'Ignore',
        field: 'ignored',
        flex: 0,
        width: 75,
        resizable: false,
        ...makeEditableIf(({ data }) => data.parent === null),
        cellEditor: 'AgGridCheckboxCellEditor',
        cellRenderer: 'AgGridIntegrationsIgnoreRenderer',
      },
      {
        headerName: 'Split',
        field: 'split',
        resizable: false,
        width: 65,
        flex: 0,
        cellRenderer: 'AgGridIntegrationsSplitRenderer',
        cellRendererParams: {
          integrationType: IntegrationType.INVOICE_SNAPSHOT,
        },
      },
      {
        width: 100,
        headerName: 'Trial ID',
        field: 'study_id',
        flex: 2,
        cellRenderer: 'AgGridIntegrationsTrialIdRenderer',
        cellRendererParams: {
          integrationType: IntegrationType.INVOICE_SNAPSHOT,
        },
        wrapText: true,
        autoHeight: true,
        cellClassRules: {
          'ag-cell-error-selected': studyIdInvalidCheck,
        },
        ...makeEditableIf(({ data }) => ignoredCheck(data)),
      },
      { headerName: 'PO #', field: 'po_number', flex: 1 },
      {
        headerName: 'Invoice amount',
        field: 'invoice_amount',
        valueGetter: 'Number(node.data.invoice_amount)',
        flex: 1,
        cellEditor: 'AgGridTextEditor',
        cellEditorParams: { inputType: 'number' },
        cellRenderer: 'AgGridMoneyCellRenderer',
        cellClassRules: {
          'ag-cell-error-selected': invoiceAmountInvalidCheck,
        },
        ...makeEditableIf(
          // Allow PO Amount editing in two conditions:
          // 1. If it is parent and split is False
          // 2. If it is a child and split is true
          // Since above is a XOR, A !== B is a valid check
          ({ data }) => ignoredCheck(data) && data.isParent !== data.split,
        ),
      },
      { headerName: 'Vendor', field: 'vendor', aggFunc: 'totalLabel', flex: 1 },
      { headerName: 'G/L account', field: 'gl_account', flex: 1 },
      { headerName: 'Description', field: 'description', flex: 1 },
      { headerName: 'Invoice date', field: 'invoice_date', flex: 1 },
      { headerName: 'Invoice paid date', field: 'invoice_paid_date', flex: 1 },
      {
        headerName: 'Invoice paid amount',
        field: 'invoice_point_amount',
        ...getMoneyCellConfig({ currencyFooterStyle: 'decimal' }),
        valueGetter: 'Number(node.data.invoice_paid_amount)',
        aggFunc: 'sum',
      },
      { headerName: 'Status', field: 'status', flex: 1 },
      { headerName: 'Currency', field: 'currency', flex: 1 },
    ];
  }, []);
}

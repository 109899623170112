import type {
  DefaultLabeledParameterResponse,
  DefaultParameterResponse,
  ForecastFolderResponse,
  ForecastRequest,
  ForecastResponse,
  ForecastedGridsResponse,
} from 'shared/lib/types';

import constructApi from './constructApi';

export const FORECASTS_TAG = 'FORECASTS';
export const GENERATE_FORECASTS_TAG = 'GENERATE_FORECASTS';
export const FORECAST_MENU_ITEMS_TAG = 'FORECAST_MENU_ITEMS';

const FORECAST_DEFAULT_PARAMETERS_TAG = 'FORECAST_DEFAULT_PARAMETERS';

const apiEndpointFactory = constructApi<ForecastResponse>('forecasts').withTags(
  FORECASTS_TAG,
  [
    GENERATE_FORECASTS_TAG,
    FORECAST_MENU_ITEMS_TAG,
    FORECAST_DEFAULT_PARAMETERS_TAG,
  ],
);

export const api = apiEndpointFactory.inject(() => ({
  getForecastsByTrial: apiEndpointFactory.getBy('trial'),
  createForecast: apiEndpointFactory.create<ForecastRequest>(
    [FORECAST_MENU_ITEMS_TAG],
    '',
    false,
  ),
  duplicateForecast: apiEndpointFactory.update(
    [FORECAST_MENU_ITEMS_TAG],
    'duplicate_forecast',
    false,
  ),
  updateForecast: apiEndpointFactory.update([FORECAST_MENU_ITEMS_TAG]),
  deleteForecast: apiEndpointFactory.delete([
    FORECASTS_TAG,
    FORECAST_MENU_ITEMS_TAG,
  ]),
  defaultParameters: apiEndpointFactory.getExtraRoute<DefaultParameterResponse>(
    'default_parameters',
    [FORECAST_DEFAULT_PARAMETERS_TAG],
  ),
  generateForecast: apiEndpointFactory.getExtraRoute<ForecastedGridsResponse>(
    'generate_forecast',
    [GENERATE_FORECASTS_TAG],
  ),
  getForecastMenuItems: apiEndpointFactory.getExtraRoute<{
    forecasts: ForecastResponse[];
    folders: ForecastFolderResponse[];
  }>('menu_forecast_items', [FORECAST_MENU_ITEMS_TAG]),
}));

export function isDefaultLabeledParameterResponse(
  parameter: unknown,
): parameter is DefaultLabeledParameterResponse {
  return (
    parameter !== null &&
    typeof parameter === 'object' &&
    Object.hasOwn(parameter, 'value')
  );
}

export const {
  useGetForecastsByTrialQuery,
  useCreateForecastMutation,
  useDuplicateForecastMutation,
  useUpdateForecastMutation,
  useDeleteForecastMutation,
  useDefaultParametersQuery,
  useGenerateForecastQuery,
  useGetForecastMenuItemsQuery,
} = api;

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import type { SxProps, Theme } from '@mui/material/styles';

import DashboardCard from 'shared/ui/dashboard-card/DashboardCard';

import { TRIAL_SPEND_HEIGHT } from 'forecasting/components/graphing/trial-spend-forecast-graph/TrialSpendForecastGraph';

import BudgetForecastGraph from './summary-tab/BudgetForecastGraph';
import CostAndTimeForecastGraph from './summary-tab/CostAndTimeForecastGraph';
import FinancialTabularData from './summary-tab/FinancialTabularData';
import OperationalTabularData from './summary-tab/OperationalTabularData';
import PatientCostGraph from './summary-tab/PatientCostGraph';
import RunRateGraph from './summary-tab/RunRateGraph';
import TrialSpendGraph from './summary-tab/TrialSpendGraph';

const ROW_SPACING = 4;
const HEADER_HEIGHT = 90;

const FULL_WIDTH_GRAPH_ROW_SX: SxProps<Theme> = {
  height: (theme) =>
    `calc(${TRIAL_SPEND_HEIGHT + HEADER_HEIGHT}px + ${theme.spacing(ROW_SPACING)})`,
  mt: 3,
};
const FULL_WIDTH_GRAPH_ROW_INNER_SX: SxProps<Theme> = {
  height: (theme) =>
    `calc(${TRIAL_SPEND_HEIGHT}px + ${theme.spacing(ROW_SPACING)})`,
  overflow: 'hidden',
  overflowX: 'auto',
};

function SummaryTab() {
  return (
    <>
      <Stack spacing={3}>
        <DashboardCard sx={{ overflowY: 'auto' }}>
          <FinancialTabularData />
        </DashboardCard>
        <DashboardCard sx={{ overflowY: 'auto' }}>
          <OperationalTabularData />
        </DashboardCard>
      </Stack>
      <DashboardCard sx={FULL_WIDTH_GRAPH_ROW_SX} title="Cost & time forecast">
        <Box sx={FULL_WIDTH_GRAPH_ROW_INNER_SX}>
          <CostAndTimeForecastGraph />
        </Box>
      </DashboardCard>
      <DashboardCard
        sx={FULL_WIDTH_GRAPH_ROW_SX}
        title="Budget vs. forecast per vendor"
      >
        <Box sx={FULL_WIDTH_GRAPH_ROW_INNER_SX}>
          <BudgetForecastGraph />
        </Box>
      </DashboardCard>
      <DashboardCard sx={FULL_WIDTH_GRAPH_ROW_SX} title="Trial spend">
        <Box sx={FULL_WIDTH_GRAPH_ROW_INNER_SX}>
          <TrialSpendGraph />
        </Box>
      </DashboardCard>
      <DashboardCard
        sx={FULL_WIDTH_GRAPH_ROW_SX}
        title="Run rate vs. enrollment"
      >
        <Box sx={FULL_WIDTH_GRAPH_ROW_INNER_SX}>
          <RunRateGraph />
        </Box>
      </DashboardCard>{' '}
      <DashboardCard
        sx={FULL_WIDTH_GRAPH_ROW_SX}
        title="Cost per patient over time"
      >
        <Box sx={FULL_WIDTH_GRAPH_ROW_INNER_SX}>
          <PatientCostGraph />
        </Box>
      </DashboardCard>
    </>
  );
}

export default SummaryTab;

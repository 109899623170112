import { useEffect, useState } from 'react';

import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import { useLocation } from 'react-router-dom';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { getDialogUtilityClass } from '@mui/material/Dialog';

import IconButton from 'shared/ui/icon-button/IconButton';

import TrialUploadsPopper from './TrialUploadsPopper';
import TrialUploadsContent from './TrialUploadsContent';

function TrialUploads() {
  const [showFrequentUploadsDropdown, setShowFrequentUploadsDropdown] =
    useState(false);
  const location = useLocation();

  useEffect(() => {
    setShowFrequentUploadsDropdown(false);
  }, [location]);

  function onClickAway() {
    if (document.querySelector(`.${getDialogUtilityClass('root')}`)) {
      // an upload wizard is opened from the popup
      // do not handle events from it and its children
      return;
    }

    setShowFrequentUploadsDropdown(false);
  }

  return (
    <TrialUploadsPopper
      open={showFrequentUploadsDropdown}
      content={
        <ClickAwayListener onClickAway={onClickAway}>
          <span>
            <TrialUploadsContent />
          </span>
        </ClickAwayListener>
      }
    >
      <span>
        <IconButton onClick={() => setShowFrequentUploadsDropdown(true)}>
          <UploadOutlinedIcon />
        </IconButton>
      </span>
    </TrialUploadsPopper>
  );
}

export default TrialUploads;

import { useCallback, useMemo, useState } from 'react';

import type {
  GridApi,
  GridReadyEvent,
  ModelUpdatedEvent,
} from '@ag-grid-community/core';
import type { SxProps } from '@mui/material/styles';

import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import CustomHeaderTooltip from 'shared/components/ag-grid/headers/CustomHeaderTooltip';
import useGridColDefs from 'shared/components/ag-grid/hooks/useGridColDefs';
import useGridOptions from 'shared/components/ag-grid/hooks/useGridOptions';
import type { CondorColDef } from 'shared/components/ag-grid/types';
import UserManagementGridPopup from 'shared/components/user-permissions/components/UserManagementGridPopup';

import PartnerLevelTrialAccess from 'partner/components/PartnerLevelTrialAccess';
import type { UserManagementGridPopupData } from 'shared/lib/types';

import usePartnerUserColumnDefs from '../hooks/usePartnerUserColumnDefs';
import usePartnerUserDataRows, {
  processPartnerUserRows,
} from '../hooks/usePartnerUserDataRows';
import usePartnerUserGridOptions from '../hooks/usePartnerUserGridOptions';

type Props = {
  overlayNoRowsTemplate?: string;
  setPartnerUserGridApi: (gridApi: GridApi) => void;
  setShowAddUserDialog: (showAddUserDialog: boolean) => void;
  showActiveUsers: boolean;
  sx?: SxProps;
};

const defaultColDef: CondorColDef = { tooltipComponent: CustomHeaderTooltip };
const onModelUpdated = (event: ModelUpdatedEvent) => {
  event.api.getDisplayedRowCount() === 0
    ? event.api.showNoRowsOverlay()
    : event.api.hideOverlay();
};

function PartnerUserGrid(props: Props) {
  const {
    overlayNoRowsTemplate,
    sx,
    showActiveUsers,
    setPartnerUserGridApi,
    setShowAddUserDialog,
  } = props;

  const defaultUserDeactivatedState: UserManagementGridPopupData = {
    buttonLabel: '',
    title: '',
    message: '',
    trace_id: '',
    state: false,
    dataToUpdate: {},
  };
  const [showDeactivateUserPopupData, setShowDeactivateUserPopupData] =
    useState<UserManagementGridPopupData>(defaultUserDeactivatedState);

  const [showTrialAccessData, setShowTrialAccessData] = useState({
    trace_id: '',
    name: '',
    state: false,
    trials: [] as string[],
    permissions: {},
  });
  const columnDefs = useGridColDefs(usePartnerUserColumnDefs, [
    setShowTrialAccessData,
    setShowAddUserDialog,
  ]);
  const gridOptions = useGridOptions(usePartnerUserGridOptions, [
    setShowDeactivateUserPopupData,
  ]);

  const result = usePartnerUserDataRows(showActiveUsers);

  const onCloseUserPopup = async () => {
    const { data } = await result.usersRefetch();
    result.rowData = processPartnerUserRows(
      { rows: data ?? [] },
      showActiveUsers,
    );
    setShowDeactivateUserPopupData(defaultUserDeactivatedState);
  };

  const onGridReady = useCallback(
    (event: GridReadyEvent) => {
      setPartnerUserGridApi(event.api);
    },
    [setPartnerUserGridApi],
  );

  const rowData = useMemo(
    () =>
      showActiveUsers
        ? result.rowData?.filter((row) => row.status === 'Active')
        : result.rowData,
    [showActiveUsers, result.rowData],
  );

  return (
    <>
      {showDeactivateUserPopupData.state && (
        <UserManagementGridPopup
          buttonLabel={showDeactivateUserPopupData.buttonLabel}
          dataToUpdate={showDeactivateUserPopupData.dataToUpdate}
          message={showDeactivateUserPopupData.message}
          title={showDeactivateUserPopupData.title}
          trace_id={showDeactivateUserPopupData.trace_id}
          onClose={() => {
            void onCloseUserPopup();
          }}
        />
      )}
      {showTrialAccessData.state && (
        <PartnerLevelTrialAccess
          isOpen={showTrialAccessData.state}
          name={showTrialAccessData.name}
          title="Company & Trial Access"
          userId={showTrialAccessData.trace_id}
          userPermissions={showTrialAccessData.permissions}
          userTrials={showTrialAccessData.trials}
          handleClose={() =>
            setShowTrialAccessData({
              state: false,
              trace_id: '',
              name: '',
              trials: [],
              permissions: {},
            })
          }
        />
      )}

      <CondorAgGrid
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        gridOptions={gridOptions}
        overlayNoRowsTemplate={overlayNoRowsTemplate}
        rowData={rowData}
        sx={sx}
        tooltipShowDelay={0}
        tooltipInteraction
        onGridReady={onGridReady}
        onModelUpdated={onModelUpdated}
      />
    </>
  );
}

export default PartnerUserGrid;

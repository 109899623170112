import Box from '@mui/material/Box';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import errorIcon from '../icons/error.svg';

type Props = {
  error?: FetchBaseQueryError | SerializedError;
};

function getErrorMessage(
  error: FetchBaseQueryError | SerializedError | undefined,
): string {
  if (!error) return 'An unknown error occurred';

  if ('status' in error) {
    // FetchBaseQueryError
    return typeof error.data === 'string'
      ? error.data
      : JSON.stringify(error.data);
  }

  // SerializedError
  return error.message ?? 'An unknown error occurred';
}

function UploadErrorImportSection(props: Props) {
  const { error } = props;
  const errorMessage = getErrorMessage(error);

  return (
    <>
      <img alt="Error" height={120} src={errorIcon} />

      <Box
        color="error.main"
        fontSize="18px"
        lineHeight="1.5"
        textAlign="center"
      >
        {errorMessage}
      </Box>

      <Box color="grey.900" display="flex" gap={4}>
        <span>
          Row count: <b>0</b>
        </span>
      </Box>
    </>
  );
}

export default UploadErrorImportSection;

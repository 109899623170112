import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { selectForecast } from 'forecasting/state/slices/forecastSlice';
import { ForecastParameterType } from 'shared/lib/types';

import { useDefaultParametersQuery } from 'shared/api/rtkq/forecasts';

function createTimelineRow(
  assumption: string,
  actual: number,
  forecast: number,
  isTotal = false,
) {
  return { assumption, actual, forecast, isTotal };
}

function useForecastTimelineRows() {
  const forecast = useSelector(selectForecast);
  const { currentData: defaultParameters } = useDefaultParametersQuery(
    forecast.trace_id,
  );

  return useMemo(() => {
    if (!defaultParameters) {
      return [];
    }

    const parameters = [
      { type: ForecastParameterType.START_UP, label: 'Start up' },
      { type: ForecastParameterType.CONDUCT, label: 'Conduct' },
      { type: ForecastParameterType.ENROLLMENT, label: 'Enrollment' },
      { type: ForecastParameterType.TREATMENT, label: 'Treatment' },
      { type: ForecastParameterType.FOLLOWUP, label: 'Follow-up' },
      { type: ForecastParameterType.CLOSE, label: 'Close' },
    ];

    const rows = parameters.flatMap(({ type, label }) => {
      const param = defaultParameters[type];
      return [
        createTimelineRow(`${label} start`, param.START, param.START),
        createTimelineRow(`${label} end`, param.END, param.END),
        createTimelineRow(
          `${label} months total`,
          param.months,
          param.months,
          true,
        ),
      ];
    });

    const overallMonths = parameters.reduce(
      (acc, { type }) => acc + defaultParameters[type].months,
      0,
    );
    const overallStart =
      defaultParameters[ForecastParameterType.START_UP].START;
    const overallEnd = defaultParameters[ForecastParameterType.CLOSE].END;

    rows.push(
      createTimelineRow('Overall start', overallStart, overallStart),
      createTimelineRow('Overall end', overallEnd, overallEnd),
      createTimelineRow(
        'Overall months total',
        overallMonths,
        overallMonths,
        true,
      ),
    );

    return rows;
  }, [defaultParameters]);
}

export default useForecastTimelineRows;

import type { ReactNode } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

type Props = {
  icon: ReactNode;
  label: string;
  value: string;
};

function MetricItem(props: Props) {
  const { label, icon, value } = props;
  return (
    <Box>
      <Typography mb={2} variant="overline">
        {label}
      </Typography>
      <Typography
        display="flex"
        fontWeight={700}
        gap={0.5}
        mt={0.5}
        variant="subtitle2"
      >
        {icon}
        {value}
      </Typography>
    </Box>
  );
}

export default MetricItem;

import { type ProcedureOccurrenceUpsert } from 'shared/lib/types';

import constructApi from './constructApi';

const PROCEDURE_OCCURRENCE = 'PROCEDURE_OCCURRENCE';

const apiEndpointFactory = constructApi('procedureoccurrence').withTags(
  PROCEDURE_OCCURRENCE,
);
const api = apiEndpointFactory.inject(() => ({
  upsertProcedureOccurrence:
    apiEndpointFactory.create<ProcedureOccurrenceUpsert>([], 'upsert'),
  getProcedureOccurrenceByCohort: apiEndpointFactory.getExtraRoute(
    'procedure_occurrence_grid',
    [PROCEDURE_OCCURRENCE],
  ),
}));

export const {
  useUpsertProcedureOccurrenceMutation,
  useGetProcedureOccurrenceByCohortQuery,
} = api;

import { useMemo } from 'react';

import Skeleton from '@mui/material/Skeleton';
import { useSelector } from 'react-redux';

import withPeriodSpecificRecordWrapper from 'shared/lib/periods/withPeriodSpecificRecordWrapper';
import RecordViewer, {
  RecordStyle,
} from 'shared/lib/record-viewer/RecordViewer';
import { PeriodRecordBlobType } from 'shared/lib/types';
import { selectTrial } from 'shared/state/slices/trialSlice';

import { useGetRegionsMetadataQuery } from 'shared/api/rtkq/trialregions';

function TrialRegionsCardContents() {
  const record = useTrialRegionsRecord(true);

  if (!record) return <Skeleton variant="rectangular" />;
  return <RecordViewer record={record} recordStyle={RecordStyle.HORIZONTAL} />;
}

export function useTrialRegionsRecord(_isOpenPeriod: boolean) {
  const trial = useSelector(selectTrial);
  const { currentData: regions } = useGetRegionsMetadataQuery(trial.trace_id);

  return useMemo(
    () =>
      regions
        ?.filter(({ is_hidden }) => !is_hidden)
        .map(({ name, contract_count }) => ({
          key: name,
          value: `Used in ${contract_count} contract${contract_count !== 1 ? 's' : ''}`,
        })),
    [regions],
  );
}

export default withPeriodSpecificRecordWrapper(
  TrialRegionsCardContents,
  PeriodRecordBlobType.TRIAL_REGIONS,
);

import { useMemo } from 'react';

import type {
  CellValueChangedEvent,
  RowDragEndEvent,
} from '@ag-grid-community/core';

import usePatientCohorts from 'accruals/wizards/visit-schedules/hooks/usePatientCohorts';
import type {
  PatientAssessmentEditor,
  PatientCohort,
  TraceId,
} from 'shared/lib/types';
import useSavePatientVisit from 'accruals/wizards/visit-schedules/hooks/useSavePatientVisit';

function useVisitsCohortGridOptions(cohortId: TraceId) {
  const { patientCohorts } = usePatientCohorts(false);
  const [savePatientVisit] = useSavePatientVisit();

  async function onInsertNewVisit(visit: PatientAssessmentEditor) {
    await savePatientVisit({
      ...visit,
      patientCohort: cohortId,
    });
  }

  function onRowDragEnd(event: RowDragEndEvent) {
    const { api } = event;

    const visits: PatientAssessmentEditor[] = [];
    api.forEachNode((node, index) => {
      if (node.data.orderIndex !== index) {
        visits.push({
          ...node.data,
          orderIndex: index,
        });
      }
    });

    for (const visit of visits) {
      void savePatientVisit(visit);
    }
  }

  const cohort: PatientCohort | undefined = patientCohorts.find(
    (cohort) => cohort.traceId === cohortId,
  );

  return useMemo(
    () => ({
      getRowId: 'traceId',
      rowDragManaged: true,
      suppressMoveWhenRowDragging: true,
      onRowDragEnd,
      onCellValueChanged: (event: CellValueChangedEvent) => {
        const { data, api: gridApi, node } = event;

        let isUnique = true;
        gridApi.forEachNode((rowNode) => {
          if (
            rowNode.data.name === data.name &&
            rowNode.data.orderIndex !== data.orderIndex
          ) {
            isUnique = false;
          }
        });

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- the condition makes
        if (!isUnique) {
          node.setDataValue('name', null);
          return;
        }

        if (data.name?.trim().length) {
          void onInsertNewVisit(data);
        }
      },
      context: {
        cohort,
      },
    }),
    [cohort, cohortId],
  );
}

export default useVisitsCohortGridOptions;

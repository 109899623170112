import { LockGridStatus } from 'shared/lib/types';
import type { LockGrid, LockGridResponse } from 'shared/lib/types';
import useHasPermission from 'shared/lib/permissions/useHasPermission';

import {
  useGetLockGridWithFiltersQuery,
  useUpsertLockGridMutation,
} from 'shared/api/rtkq/lockgrids';

import useLockGridQueryParams from './useLockGridQueryParams';

type UseLockGridReturn = {
  lockInfo: LockGridResponse | undefined;
  isLocked: boolean;
  userCanLockGrids: boolean;
  toggleLock: () => void;
  isLoading: boolean;
};

function useLockGrid(
  grid: LockGrid,
  locationSlug: string | null,
): UseLockGridReturn {
  const params = useLockGridQueryParams(grid, locationSlug);
  const userCanLockGrids = useHasPermission(['canLockGrids']);
  const { currentData, isLoading } = useGetLockGridWithFiltersQuery(params);
  const [upsertLockGrid] = useUpsertLockGridMutation();

  const lockInfo = currentData?.[0];
  const isLocked =
    lockInfo?.status === LockGridStatus.LOCK_GRID &&
    (!locationSlug || locationSlug === lockInfo.location_slug);

  const toggleLock = () => {
    void upsertLockGrid({
      ...params,
      status: isLocked ? LockGridStatus.UNLOCK_GRID : LockGridStatus.LOCK_GRID,
    });
  };

  return {
    lockInfo,
    isLocked,
    userCanLockGrids,
    toggleLock,
    isLoading,
  };
}

export default useLockGrid;

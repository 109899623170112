import { useState } from 'react';

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Box from '@mui/material/Box';

import type { CustomCellRendererParams } from 'shared/components/ag-grid/types';
import IconButton from 'shared/ui/icon-button/IconButton';

import {
  handleAddNewDataRows,
  handleDeleteRowFromGrid,
} from '../../helpers/cell';

function AgGridIntegrationsTrialIdRenderer(params: CustomCellRendererParams) {
  const { data, value, api, integrationType, column, node } = params;
  const [isHovered, setIsHovered] = useState(false);

  const handleDeleteRow = (event: MouseEvent) => {
    event.stopPropagation();
    const parentTraceId = data.parent;
    const parentRow = api.getRowNode(data.parent);
    const currentRowToDelete = api.getRowNode(data.row_id);
    handleDeleteRowFromGrid(api, parentTraceId, parentRow, currentRowToDelete);
  };

  const handleAddRow = (event: MouseEvent) => {
    event.stopPropagation();
    handleAddNewDataRows(1, api, data.row_id, data.trace_id, integrationType);
  };

  let child: React.ReactNode;

  const elementIsParent = data.parent === null || data.parent === undefined;

  if (data.split) {
    if (elementIsParent) {
      child = (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <IconButton
            ref={(ref) => {
              if (!ref) return;
              ref.onclick = handleAddRow;
            }}
          >
            <AddCircleOutlineOutlinedIcon sx={{ fontSize: 'medium' }} />
          </IconButton>
        </Box>
      );
    } else {
      child = (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <IconButton
            ref={(ref) => {
              if (!ref) return;
              ref.onclick = handleDeleteRow;
            }}
          >
            <DeleteOutlineOutlinedIcon sx={{ fontSize: 'medium' }} />
          </IconButton>
        </Box>
      );
    }
  }

  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
      onBlur={(event) => {
        event.preventDefault();
      }}
      onFocus={(event) => {
        event.preventDefault();
      }}
      onMouseOut={(event) => {
        event.preventDefault();
        setIsHovered(false);
      }}
      onMouseOver={(event) => {
        event.preventDefault();
        setIsHovered(true);
      }}
    >
      {value ? (
        <span style={{ width: '80%' }}>{value}</span>
      ) : (
        <span style={{ color: 'grey' }}>Enter Trial ID</span>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        {!data.ignored && isHovered && !(data.isParent && data.split) && (
          <IconButton
            ref={(ref) => {
              if (!ref) return;
              ref.onclick = () => {
                api.startEditingCell({
                  rowIndex: node.rowIndex ?? -1,
                  colKey: column?.getColId() ?? '-1',
                });
              };
            }}
          >
            <EditOutlinedIcon sx={{ fontSize: 'medium' }} />
          </IconButton>
        )}
        {child}
      </Box>
    </Box>
  );
}

export default AgGridIntegrationsTrialIdRenderer;

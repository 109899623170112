import { useMemo } from 'react';

import usePatientCohorts from 'accruals/wizards/visit-schedules/hooks/usePatientCohorts';
import {
  PatientAssessmentTimelinePortionEnum,
  type TraceId,
} from 'shared/lib/types';

function useVisitsCohortRows(cohortId: TraceId) {
  const { patientCohorts, isLoading } = usePatientCohorts(false);

  return useMemo(() => {
    const patientAssessments = patientCohorts.find(
      (cohort) => cohort.traceId === cohortId,
    )?.patientAssessments;

    if (isLoading || !patientAssessments?.length) {
      return [
        {
          traceId: 'dummy',
          name: '',
          patientCohort: '',
          isScreen: false,
          isScreenfail: false,
          isDroppedCompleted: false,
          isEnroll: false,
          orderIndex: 0,
          dayOfProtocol: null,
          timelinePortion: PatientAssessmentTimelinePortionEnum.OTHER,
        },
      ];
    }

    return patientAssessments;
  }, [patientCohorts, isLoading, cohortId]);
}

export default useVisitsCohortRows;

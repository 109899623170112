import { useMemo } from 'react';

import { useParams } from 'react-router-dom';

import TertiaryNavigationTabs from 'shared/components/tabs/TertiaryNavigationTabs';

import { CRO_CONTRACT_TABS } from 'shared/helpers/helpers';

import ForecastedCroExpenseGrid from '../grids/ForecastedCroExpenseGrid';
import ForecastedOccExpenseGrid from '../grids/ForecastedOccExpenseGrid';

type Props = {
  activeSubTab: string;
  setActiveSubTab: (value: string) => void;
  showActuals: boolean;
};

const FORECASTED_OCC_EXPENSE_PATH = 'occ';

function ExpensesTab(props: Props) {
  const { activeSubTab, setActiveSubTab, showActuals } = props;
  const { contractContainerSlug } = useParams<{
    contractContainerSlug?: string;
  }>();
  const sx = useMemo(() => ({ height: 600 }), []);

  if (contractContainerSlug === FORECASTED_OCC_EXPENSE_PATH) {
    return <ForecastedOccExpenseGrid showActuals={showActuals} sx={sx} />;
  }
  if (!contractContainerSlug) {
    return null;
  }

  return (
    <>
      <TertiaryNavigationTabs
        activeTab={activeSubTab}
        tabs={[
          { key: CRO_CONTRACT_TABS.DIRECT_FEES, label: 'Direct Fees' },
          { key: CRO_CONTRACT_TABS.PASS_THROUGHS, label: 'Pass-throughs' },
          {
            key: CRO_CONTRACT_TABS.INVESTIGATOR_FEES,
            label: 'Investigator Fees',
          },
        ]}
        onChange={(_event, newValue) => setActiveSubTab(newValue)}
      />
      <ForecastedCroExpenseGrid
        activeSubTab={activeSubTab}
        contractContainerSlug={contractContainerSlug}
        showActuals={showActuals}
        sx={sx}
      />
    </>
  );
}

export default ExpensesTab;
export { FORECASTED_OCC_EXPENSE_PATH };

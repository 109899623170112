import type { ChangeEvent } from 'react';
import { memo, useEffect, useMemo, useState } from 'react';

import groupBy from 'lodash/groupBy';
import { useSelector } from 'react-redux';

import type { TrialCheckbox } from 'shared/lib/types';
import { selectCompany } from 'shared/state/slices/companySlice';

import { useGetTrialsByCompanyQuery } from 'shared/api/rtkq/trials';

import TrialAccessCheckboxComponent from './TrialAccessCheckboxComponent';
import TrialAccessDialog from './TrialAccessDialog';

type Props = {
  handleClose: () => void;
  isOpen: boolean;
  name: string;
  title: string;
  userId: string;
  userPermissions: Record<string, boolean>;
  userTrials: string[];
};

function CompanyLevelTrialAccessDialog(props: Props) {
  const {
    handleClose,
    isOpen,
    name,
    title,
    userId,
    userTrials,
    userPermissions,
  } = props;
  const currentCompany = useSelector(selectCompany);
  const { data: trialsByCompany } = useGetTrialsByCompanyQuery(
    currentCompany.trace_id,
  );

  const trialsByProgram = useMemo(
    () => groupBy(trialsByCompany ?? [], (trial) => trial.program.trace_id),
    [trialsByCompany],
  );

  const finalTrialsByProgram = Object.entries(trialsByProgram).map(
    ([programTraceId, trialsOfProgram]) => {
      const programName = trialsOfProgram[0].program.name;
      return {
        programTraceId,
        programName,
        trials: trialsOfProgram,
      };
    },
  );

  const [trialCheckboxes, setTrialCheckboxes] = useState<TrialCheckbox[]>([]);
  const [checkBoxDisabled, setCheckBoxDisabled] = useState(
    userTrials.includes('All Trials') ||
      userPermissions.canEditCompanyUsersAndPermissions,
  );
  const [specificTrials, setSpecificTrials] = useState<boolean>(true);

  useEffect(() => {
    if (isOpen) {
      const initialTrialChecked: TrialCheckbox[] =
        trialsByCompany?.map((trial) => ({
          trialTraceId: trial.trace_id,
          programTraceId: trial.program.trace_id,
          companyTraceId: trial.company,
          checked:
            userTrials.includes(trial.trace_id) ||
            userTrials.includes('All Trials'),
        })) ?? [];

      setTrialCheckboxes(initialTrialChecked);
    }
  }, [isOpen, trialsByCompany, userTrials]);

  const handleTrialCheckboxOnChange = (
    event: ChangeEvent<HTMLInputElement>,
    trialTraceId: string,
  ) => {
    setTrialCheckboxes((prev) =>
      prev.map((trial) =>
        trial.trialTraceId === trialTraceId
          ? { ...trial, checked: event.target.checked }
          : trial,
      ),
    );
  };

  const handleProgramCheckboxOnChange = (
    event: ChangeEvent<HTMLInputElement>,
    programTraceId: string,
  ) => {
    trialsByProgram[programTraceId].map((trial) =>
      handleTrialCheckboxOnChange(event, trial.trace_id),
    );
  };

  return (
    <TrialAccessDialog
      checkboxDisabled={checkBoxDisabled}
      handleClose={handleClose}
      isOpen={isOpen}
      name={name}
      setCheckBoxDisabled={setCheckBoxDisabled}
      setSpecificTrials={setSpecificTrials}
      setTrialCheckboxes={setTrialCheckboxes}
      specificTrials={specificTrials}
      title={title}
      trialCheckboxes={trialCheckboxes}
      trialsByProgram={trialsByProgram}
      userId={userId}
      userPermissions={userPermissions}
      userTrials={userTrials}
    >
      <TrialAccessCheckboxComponent
        checkBoxDisabled={checkBoxDisabled}
        handleProgramCheckboxOnChange={handleProgramCheckboxOnChange}
        handleTrialCheckboxOnChange={handleTrialCheckboxOnChange}
        moveRight={false}
        trialCheckboxes={trialCheckboxes}
        trialsByProgram={finalTrialsByProgram}
        allowScroll
      />
    </TrialAccessDialog>
  );
}

export default memo(CompanyLevelTrialAccessDialog);

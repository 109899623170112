import type { ForwardedRef, ReactElement } from 'react';
import { forwardRef, useEffect, useState } from 'react';

import type { SxProps } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { useSelector } from 'react-redux';

import type {
  AgGridRef,
  UserDisplayOptions,
} from 'shared/components/ag-grid/CondorAgGrid';
import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import type {
  CondorColDef,
  CondorGridOptions,
} from 'shared/components/ag-grid/types';

import { selectPeriodVersion } from 'accruals/state/slices/periodVersionSlice';
import { getJSONFromFile } from 'shared/helpers/helpers';
import type {
  PeriodGridBlobResponse,
  PeriodGridBlobType,
  TraceId,
} from 'shared/lib/types';

import type { apiJSON } from 'shared/api/rtkq/apiJSON';
import { useGetPeriodGridBlobQuery } from 'shared/api/rtkq/periodgridblobs';

type Props = {
  autoHeight?: boolean;
  overlayNoRowsTemplate?: string;
  parentMenuItem?: TraceId;
  savedObjectType: PeriodGridBlobType;
  sx?: SxProps;
  userDisplayOptions?: UserDisplayOptions;
};

function PeriodGridBlobGrid(
  props: Props,
  ref: ForwardedRef<AgGridRef<apiJSON> | undefined>,
): ReactElement {
  const [columnDefs, setColumnDefs] = useState<CondorColDef[]>();
  const [gridOptions, setGridOptions] = useState<CondorGridOptions>();

  // we have no way to properly type this, and being this is just a glorified pass thru, we can specify this component will never need to care
  const [rowData, setRowData] = useState<never>();

  const {
    parentMenuItem,
    overlayNoRowsTemplate,
    savedObjectType,
    userDisplayOptions,
    sx,
    autoHeight,
  } = props;

  const periodVersion = useSelector(selectPeriodVersion);
  const { currentData: gridSnapshots } = useGetPeriodGridBlobQuery({
    saved_object_type: savedObjectType,
    period_version: periodVersion.trace_id,
    parent_menu_item: parentMenuItem,
  });

  let gridSnapshot: PeriodGridBlobResponse | undefined;
  if (gridSnapshots) {
    gridSnapshot = gridSnapshots[0];

    if (gridSnapshots.length > 1) {
      Sentry.captureMessage(
        "There should only ever be one grid snapshot, but we're getting more than one. This likely means something is missing a parentMenuItem",
      );
    }
  }

  useEffect(() => {
    // TODO: Error Handling
    void (async () => {
      if (gridSnapshot === undefined) {
        return;
      }

      if (gridSnapshot.grid_options) {
        setGridOptions(
          await getJSONFromFile<CondorGridOptions>(gridSnapshot.grid_options),
        );
      }

      setColumnDefs(
        await getJSONFromFile<CondorColDef[]>(gridSnapshot.col_defs),
      );
      setRowData(await getJSONFromFile(gridSnapshot.rows));
    })();
  }, [gridSnapshot]);

  // don't render until we have grid options if we are expecting them,
  // as ag-grid won't honor them once it renders
  if (
    gridSnapshot === undefined ||
    (gridSnapshot.grid_options && gridOptions === undefined)
  ) {
    return <div />;
  }

  return (
    <CondorAgGrid
      ref={ref}
      columnDefs={columnDefs}
      domLayout={autoHeight ? 'autoHeight' : 'normal'}
      gridOptions={gridOptions}
      overlayNoRowsTemplate={overlayNoRowsTemplate}
      rowData={rowData}
      sx={sx}
      userDisplayOptions={userDisplayOptions}
    />
  );
}

export default forwardRef(PeriodGridBlobGrid);

import type {
  ContractContainerRequest,
  ContractContainerResponse,
  ContractVersionResponse,
} from 'shared/lib/types';

import { ALL_CONTRACTS, CRO_EXPENSE_SUMMARY } from './apiTags';
import constructApi from './constructApi';
import { VENDOR_TAG } from './vendors';

const PATH = 'contractcontainers';

export const CONTRACT_CONTAINERS = 'CONTRACT_CONTAINERS';

const apiEndpointFactory = constructApi<
  ContractContainerResponse,
  ContractContainerRequest
>(PATH).withTags(CONTRACT_CONTAINERS, [ALL_CONTRACTS, VENDOR_TAG]);
export const api = apiEndpointFactory.inject(() => ({
  createContractContainer: apiEndpointFactory.create([
    ALL_CONTRACTS,
    VENDOR_TAG,
  ]),
  deleteContractContainer: apiEndpointFactory.delete([
    ALL_CONTRACTS,
    CONTRACT_CONTAINERS,
    CRO_EXPENSE_SUMMARY,
    VENDOR_TAG,
  ]),
  getContractContainer: apiEndpointFactory.get(),
  getContractContainersByTrial: apiEndpointFactory.getBy('trial'),
  getContractContainersByCompany: apiEndpointFactory.getBy('company'),
  getContractContainersWithFilters: apiEndpointFactory.getWithFilters(),
  updateContractContainer: apiEndpointFactory.update([
    ALL_CONTRACTS,
    VENDOR_TAG,
  ]),
  getCurrentContractVersion:
    apiEndpointFactory.getExtraRouteTwoParameters<ContractVersionResponse | null>(
      'current_contract',
      [CONTRACT_CONTAINERS],
    ),
  getAipContractVersion:
    apiEndpointFactory.getExtraRouteTwoParameters<ContractVersionResponse | null>(
      'aip_contract',
      [CONTRACT_CONTAINERS],
    ),
  getAllContractVersionsForContractContainer:
    apiEndpointFactory.getExtraRouteTwoParameters('all_contracts', [
      CONTRACT_CONTAINERS,
    ]),
}));

export const {
  useCreateContractContainerMutation,
  useDeleteContractContainerMutation,
  useGetContractContainersByTrialQuery,
  useGetContractContainerQuery,
  useGetContractContainersWithFiltersQuery,
  useLazyGetContractContainersWithFiltersQuery,
  useUpdateContractContainerMutation,
  useGetContractContainersByCompanyQuery,
  useGetCurrentContractVersionQuery,
  useGetAipContractVersionQuery,
  useGetAllContractVersionsForContractContainerQuery,
  useLazyGetAllContractVersionsForContractContainerQuery,
} = api;

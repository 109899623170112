import { useMemo } from 'react';

import type { CondorGridOptions } from 'shared/components/ag-grid/types';

import useEditActivityDriver from 'shared/api/hooks/useEditActivityDriver';

export default function useCroExpenseGridOptions(
  isOpenPeriod: boolean,
): CondorGridOptions {
  const [onCellValueChanged] = useEditActivityDriver({ vendorType: 'CRO' });

  return useMemo(
    () => ({
      groupDisplayType: 'groupRows',
      // this is used for row-level-comments as rowId, so ideally should not be changed
      getRowId: 'id',
      getRowStyle: 'highlightRow',
      suppressStickyTotalRow: 'group',
      groupTotalRow: 'bottom',
      grandTotalRow: 'bottom',
      suppressAggFuncInHeader: true,
      ...(isOpenPeriod && { onCellValueChanged }),
    }),
    [isOpenPeriod, onCellValueChanged],
  );
}

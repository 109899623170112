import type { ReactNode } from 'react';
import { cloneElement, isValidElement, useState } from 'react';

import type { SxProps } from '@mui/material/styles';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import PatientActivityGrid from '../grids/PatientActivityGrid';

type Props = {
  gridSx?: SxProps;
  header: ReactNode;
};

function PatientActivityContainer(props: Props) {
  const { gridSx, header } = props;
  const [showMonthlyActuals, setShowMonthlyActuals] = useState<boolean>(false);

  const { mt, ...restProps } = isValidElement(header)
    ? header.props
    : { mt: null };
  const modifiedHeader = isValidElement(header)
    ? cloneElement(header, {
        ...restProps,
        mt: null,
      })
    : header;

  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        mt={mt}
      >
        {modifiedHeader}
        <FormControlLabel
          label="Show monthly actuals"
          control={
            <Switch
              checked={showMonthlyActuals}
              onChange={(_, checked) => setShowMonthlyActuals(checked)}
            />
          }
        />
      </Box>
      <PatientActivityGrid sx={gridSx} />
    </>
  );
}

export default PatientActivityContainer;

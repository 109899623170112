import type { ReactNode } from 'react';
import { useMemo, useState } from 'react';

import type {
  CellValueChangedEvent,
  GridReadyEvent,
  ModelUpdatedEvent,
} from '@ag-grid-community/core';
import Box from '@mui/material/Box';

import {
  validateChildTotals,
  validateRowData,
} from 'shared/components/ag-grid-cells/helpers/cell';
import type { CondorColDef } from 'shared/components/ag-grid/types';

import {
  type IntegrationResponse,
  type UnprocessedRecordResponse,
} from 'shared/lib/types';
import WizardStep from 'shared/wizards/steps/WizardStep';

import IntegrationGrid from '../grids/IntegrationGrid';
type Props = {
  childTotalValidationKey?: keyof UnprocessedRecordResponse;
  colDefs: CondorColDef[];
  fieldsToValidate: Array<keyof UnprocessedRecordResponse>;
  handleGridReady: (event: GridReadyEvent) => void;
  handleGridSave: () => void;
  headerText: ReactNode;
  integration: IntegrationResponse;
  overlayNoRowsTemplate: string;
};

function GridStep(props: Props) {
  const {
    handleGridSave,
    integration,
    handleGridReady,
    headerText,
    fieldsToValidate,
    childTotalValidationKey,
    overlayNoRowsTemplate,
    colDefs,
  } = props;

  const [disableSave, setDisableSave] = useState(true);

  const handleChangeEventForCellOrModel = (
    event: CellValueChangedEvent | ModelUpdatedEvent,
    columnCondition: boolean | undefined,
  ) => {
    // The code is similar for both cell and model update events.
    // The key difference is that for cell updates, we check if the updated column
    // is the childTotalValidationKey to validate child totals only when necessary.

    // Example: If childTotalValidationKey is 'po_amount', we validate child totals
    // only when 'po_amount' is updated. For other columns, we just check if the data is valid.
    const { api } = event;
    const rowData: UnprocessedRecordResponse[] = [];
    api.forEachNode((node) => rowData.push(node.data));

    let amountTotalsForChildrenIsValid = true;
    if (childTotalValidationKey && columnCondition) {
      amountTotalsForChildrenIsValid = validateChildTotals(
        rowData,
        api,
        childTotalValidationKey,
      );
    }
    const rowDataValid =
      validateRowData(rowData, fieldsToValidate) &&
      amountTotalsForChildrenIsValid;

    // Disable if rowData is invalid or totals are invalid
    setDisableSave(!rowDataValid);
  };

  // Works when updating cell values
  const handleCellValueChangedEvent = (event: CellValueChangedEvent) => {
    handleChangeEventForCellOrModel(
      event,
      childTotalValidationKey &&
        event.column.getColId() === childTotalValidationKey,
    );
  };

  // Works for splitting, adding new rows and deleting rows in the grid
  const handleModelUpdatedEvent = (event: ModelUpdatedEvent) => {
    handleChangeEventForCellOrModel(
      event,
      childTotalValidationKey !== undefined,
    );
  };

  return (
    <WizardStep
      contentSx={{ overflow: 'hidden' }}
      disableNextButton={disableSave}
      disableBackButton
      onNext={handleGridSave}
    >
      <Box
        sx={{
          gap: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {headerText}
        <Box height="10px" />
      </Box>
      <IntegrationGrid
        colDefs={colDefs}
        handleCellValueChangedEvent={handleCellValueChangedEvent}
        handleGridReady={handleGridReady}
        handleModelUpdatedEvent={handleModelUpdatedEvent}
        integration={integration}
        overlayNoRowsTemplate={overlayNoRowsTemplate}
        sx={useMemo(() => ({ height: '90%' }), [])}
      />
    </WizardStep>
  );
}

export default GridStep;

import type { ICellRendererParams } from '@ag-grid-community/core';
import DownloadIcon from '@mui/icons-material/DownloadOutlined';
import Link from '@mui/material/Link';

function AgGridFileDownloadCellRenderer(params: ICellRendererParams) {
  const { value } = params;

  if (!value) {
    return null;
  }

  // target and rel are for older browsers who don't honor `download`
  return (
    <Link
      href={value}
      rel="noreferrer"
      target="_blank"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
      }}
      download
    >
      <DownloadIcon color="action" />
    </Link>
  );
}

export default AgGridFileDownloadCellRenderer;

import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import type {
  CondorColDef,
  CondorColGroupDef,
} from 'shared/components/ag-grid/types';
import useRowCommentsCountLoader from 'shared/components/comments/hooks/useRowCommentsCountLoader';

import type {
  AdministrativeOrProcedureCategoryResponse,
  CommentsCountsGroupedByRowId,
  PatientCohortResponse,
} from 'shared/lib/types';
import { CommentLocationType } from 'shared/lib/types';
import { selectTrial } from 'shared/state/slices/trialSlice';

import { useGetAdministrativeOrProcedureCategoriesByTrialQuery } from 'shared/api/rtkq/administrativeorprocedurecategories';
import { useGetPatientCohortsByTrialQuery } from 'shared/api/rtkq/patientcohorts';

import getPatientJourneyColumnDefs from './getPatientJourneyColumnDefs';

export function processPatientJourneyColDefs(
  cohortTraceId: string,
  adminOrProcedureCategories: AdministrativeOrProcedureCategoryResponse[],
  patientCohorts: PatientCohortResponse[],
  commentCountsByRowId: CommentsCountsGroupedByRowId | undefined,
) {
  const procedureCategories = adminOrProcedureCategories.filter(
    (category) => category.category_type === 'INV',
  );
  return getPatientJourneyColumnDefs(
    procedureCategories,
    patientCohorts,
    commentCountsByRowId,
    cohortTraceId,
  );
}

// isOpenPeriod is here so this works the same as all the other defs.
// if adding a NON READ ONLY field, please ensure you honor this flag
// else period close might not work correctly
export default function usePatientJourneyColumnDefs(
  _isOpenPeriod: boolean,
  cohortTraceId?: string,
): Array<CondorColDef | CondorColGroupDef> {
  const trial = useSelector(selectTrial);
  const { currentData: adminOrProcedureCategories } =
    useGetAdministrativeOrProcedureCategoriesByTrialQuery(trial.trace_id);

  const { currentData: patientCohorts } = useGetPatientCohortsByTrialQuery(
    trial.trace_id,
  );

  const procedureCategories = adminOrProcedureCategories?.filter(
    (category) => category.category_type === 'INV',
  ); // TODO: Filter in database by passing filter param to UI

  const commentCountsByRowId = useRowCommentsCountLoader(
    CommentLocationType.PATIENT_JOURNEY,
    undefined,
  );

  return useMemo(
    () =>
      getPatientJourneyColumnDefs(
        procedureCategories,
        patientCohorts,
        commentCountsByRowId,
        cohortTraceId,
      ),
    [patientCohorts, procedureCategories, cohortTraceId, commentCountsByRowId],
  );
}

import type { ChangeEvent } from 'react';
import { memo, useEffect, useMemo, useState } from 'react';

import groupBy from 'lodash/groupBy';
import { useSelector } from 'react-redux';

import TrialAccessDialog from 'shared/components/user-permissions/components/TrialAccessDialog';

import type { TrialCheckbox } from 'shared/lib/types';
import { selectPartner } from 'shared/state/slices/partnerSlice';

import { useGetTrialsByPartnerQuery } from 'shared/api/rtkq/trials';

import PartnerTrialAccessCheckbox from './PartnerTrialAccessCheckbox';

type Props = {
  handleClose: () => void;
  isOpen: boolean;
  name: string;
  title: string;
  userId: string;
  userPermissions: Record<string, boolean>;
  userTrials: string[];
};

function PartnerLevelTrialAccessDialog(props: Props) {
  const {
    handleClose,
    isOpen,
    name,
    title,
    userId,
    userTrials,
    userPermissions,
  } = props;
  const currentPartner = useSelector(selectPartner);
  const { data: trialsByPartner } = useGetTrialsByPartnerQuery(
    currentPartner.trace_id,
  );

  const trialsByProgram = useMemo(
    () => groupBy(trialsByPartner ?? [], (trial) => trial.program.trace_id),
    [trialsByPartner],
  );

  const trialsByCompany = useMemo(
    () => groupBy(trialsByPartner ?? [], (trial) => trial.company),
    [trialsByPartner],
  );

  const companyProgramsGroup = useMemo(
    () =>
      Object.entries(trialsByCompany).map(([companyTraceId, trials]) => {
        const companyName =
          currentPartner.companies.find(
            (company) => company.trace_id === companyTraceId,
          )?.name ?? '';

        const tbp = groupBy(trials, (trial) => trial.program.trace_id);

        return {
          companyTraceId,
          companyName,
          programs: Object.entries(tbp).map(
            ([programTraceId, trialsOfProgram]) => {
              const programName = trials[0].program.name;
              return {
                programTraceId,
                programName,
                trials: trialsOfProgram,
              };
            },
          ),
        };
      }),
    [trialsByCompany, currentPartner],
  );

  const [trialCheckboxes, setTrialCheckboxes] = useState<TrialCheckbox[]>([]);
  const [checkBoxDisabled, setCheckBoxDisabled] = useState(
    userTrials.includes('All Trials') ||
      userPermissions.canEditCompanyUsersAndPermissions,
  );
  const [specificTrials, setSpecificTrials] = useState<boolean>(true);

  useEffect(() => {
    if (isOpen) {
      const initialTrialChecked: TrialCheckbox[] =
        trialsByPartner?.map((trial) => ({
          trialTraceId: trial.trace_id,
          programTraceId: trial.program.trace_id,
          companyTraceId: trial.company,
          checked:
            userTrials.includes(trial.trace_id) ||
            userTrials.includes('All Trials'),
        })) ?? [];

      setTrialCheckboxes(initialTrialChecked);
    }
  }, [isOpen, trialsByPartner, userTrials]);

  const handleTrialCheckboxOnChange = (
    event: ChangeEvent<HTMLInputElement>,
    trialTraceId: string,
  ) => {
    setTrialCheckboxes((prev) =>
      prev.map((trial) =>
        trial.trialTraceId === trialTraceId
          ? { ...trial, checked: event.target.checked }
          : trial,
      ),
    );
  };

  const handleCompanyCheckboxOnChange = (
    event: ChangeEvent<HTMLInputElement>,
    companyTraceId: string,
  ) => {
    trialsByCompany[companyTraceId].map((trial) =>
      handleTrialCheckboxOnChange(event, trial.trace_id),
    );
  };

  const handleProgramCheckboxOnChange = (
    event: ChangeEvent<HTMLInputElement>,
    programTraceId: string,
  ) => {
    trialsByProgram[programTraceId].map((trial) =>
      handleTrialCheckboxOnChange(event, trial.trace_id),
    );
  };

  return (
    <TrialAccessDialog
      checkboxDisabled={checkBoxDisabled}
      handleClose={handleClose}
      isOpen={isOpen}
      name={name}
      setCheckBoxDisabled={setCheckBoxDisabled}
      setSpecificTrials={setSpecificTrials}
      setTrialCheckboxes={setTrialCheckboxes}
      specificTrials={specificTrials}
      title={title}
      trialCheckboxes={trialCheckboxes}
      trialsByProgram={trialsByProgram}
      userId={userId}
      userPermissions={userPermissions}
      userTrials={userTrials}
    >
      <PartnerTrialAccessCheckbox
        checkBoxDisabled={checkBoxDisabled}
        companyProgramsGroup={companyProgramsGroup}
        handleCompanyCheckboxOnChange={handleCompanyCheckboxOnChange}
        handleProgramCheckboxOnChange={handleProgramCheckboxOnChange}
        handleTrialCheckboxOnChange={handleTrialCheckboxOnChange}
        trialCheckboxes={trialCheckboxes}
      />
    </TrialAccessDialog>
  );
}

export default memo(PartnerLevelTrialAccessDialog);

import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import type { IntegrationResponse, IntegrationType } from 'shared/lib/types';
import { selectCompany } from 'shared/state/slices/companySlice';

import { useGetIntegrationsByCompanyQuery } from 'shared/api/rtkq/integrations';

export default function useIntegration(
  integrationType: IntegrationType,
): IntegrationResponse | undefined {
  const currentCompany = useSelector(selectCompany);

  const { currentData: integrations } = useGetIntegrationsByCompanyQuery({
    company: currentCompany.trace_id,
  });

  return useMemo(
    () =>
      integrations?.find(
        ({ integration_type }) => integration_type === integrationType,
      ),
    [integrationType, integrations],
  );
}

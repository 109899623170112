import { useMemo, useRef } from 'react';

import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';

import type { AgGridRef } from 'shared/components/ag-grid/CondorAgGrid';

import usePatientCohorts from 'accruals/wizards/visit-schedules/hooks/usePatientCohorts';
import { PatientAssessmentTimelinePortionEnum } from 'shared/lib/types';
import type {
  PatientAssessment,
  PatientAssessmentEditor,
} from 'shared/lib/types';

import EmptyVisits from './EmptyVisits';
import VisitsGridHeader from './VisitsGridHeader';
import VisitsCohortGrid from './VisitsCohortGrid';
import CohortMetrics from './components/CohortMetrics';

function VisitsPage() {
  const { patientCohorts, isLoading } = usePatientCohorts(false);
  const { cohortId } = useParams<{
    cohortId: string;
  }>();

  const gridRef =
    useRef<AgGridRef<PatientAssessment | PatientAssessmentEditor>>(null);

  const gridSx = useMemo(() => ({ height: 400 }), []);

  if (!cohortId) {
    return null;
  }

  const needToShowEmptyState =
    isLoading ||
    !patientCohorts.length ||
    patientCohorts.some((cohort) => !cohort.patientAssessments.length);

  if (needToShowEmptyState) {
    return <EmptyVisits />;
  }

  function onAddNewVisit() {
    const { api: gridApi } = gridRef.current ?? {};
    if (!gridApi) {
      return;
    }

    let hasEmptyName = false;
    let rowsCount = 0;
    gridApi.forEachNode((rowNode) => {
      rowsCount += 1;
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- the value is set to null in case it's empty in col def
      if (!rowNode.data?.name?.trim().length) {
        hasEmptyName = true;
      }
    });

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- the condition makes
    if (hasEmptyName) {
      // prevent adding new visit if there is an empty name
      return;
    }

    const defaultVisit: PatientAssessmentEditor = {
      traceId: 'new-visit',
      isNew: true,
      name: '',
      orderIndex: rowsCount,
      patientCohort: cohortId!,
      mappedName: undefined,
      isScreen: false,
      isEnroll: false,
      isScreenfail: false,
      isDroppedCompleted: false,
      dayOfProtocol: null,
      timelinePortion: PatientAssessmentTimelinePortionEnum.OTHER,
    };

    gridApi.applyTransaction({
      add: [defaultVisit],
    });

    gridApi.ensureIndexVisible(rowsCount, 'bottom');
  }

  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        mb={2}
        mt={3}
      >
        <CohortMetrics />
        <Box alignItems="center" display="flex">
          <VisitsGridHeader cohortId={cohortId} onAddNewVisit={onAddNewVisit} />
        </Box>
      </Box>
      <VisitsCohortGrid ref={gridRef} cohortId={cohortId} sx={gridSx} />
    </>
  );
}

export default VisitsPage;

import { type SyntheticEvent, useMemo } from 'react';

import FilterIcon from '@mui/icons-material/Filter';
import TuneIcon from '@mui/icons-material/Tune';
import ViewTimelineOutlinedIcon from '@mui/icons-material/ViewTimelineOutlined';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';

import useFeatureFlag from 'shared/helpers/useFeatureFlag';

import ParametersMenuHeader from './ParametersMenuHeader';
import ParametersMenuItem from './ParametersMenuItem';

const PATIENT_ENROLLMENT = 'Patient enrollment';
const PATIENT_ACTIVITY = 'Patient activity';
const SITE_ACTIVATION = 'Site activation';
const SITE_ACTIVITY = 'Site activity';

const CONTRACT_ASSUMPTIONS = [
  'Patients',
  'Patient Months',
  'Sites',
  'Site months',
  'Timeline',
];

const FORECAST_OPTIONS = [
  'Patients per region',
  'Region & cohort',
  'Timeline',
] as const;

type Props = {
  selectedForecastType: ForecastType;
  onForecastTypeChange: (type: ForecastType) => void;
  onItemClick: (index: number) => void;
};

function ParametersMenu(props: Props) {
  const { onItemClick, selectedForecastType, onForecastTypeChange } = props;
  const isParametersNewSectionsEnabled = useFeatureFlag(
    'forecasting_parameters_new_sections',
  );
  const isTrialAveragesEnabled = useFeatureFlag(
    'forecasting_parameters_trial_averages',
  );
  const isForecastByTimelineEnabled = useFeatureFlag('forecast_by_timeline');
  const isForecastByCohortEnabled = useFeatureFlag('forecast_by_cohort');
  const isForecastingParametersManualPatientActivityEnabled = useFeatureFlag(
    'forecasting_parameters_manual_patient_activity',
  );
  const isForecastingParametersManualSiteActivityEnabled = useFeatureFlag(
    'forecasting_parameters_manual_site_activity',
  );

  const scenarioPlanning = useMemo(() => {
    const patientActivityOrEnrollment =
      isForecastingParametersManualPatientActivityEnabled
        ? PATIENT_ACTIVITY
        : PATIENT_ENROLLMENT;

    const siteActivityOrActivation =
      isForecastingParametersManualSiteActivityEnabled
        ? SITE_ACTIVITY
        : SITE_ACTIVATION;

    return [
      ...(selectedForecastType === 'Timeline' ? ['Timeline'] : []),
      patientActivityOrEnrollment,
      siteActivityOrActivation,
      ...(isTrialAveragesEnabled ? ['Trial averages'] : []),
    ];
  }, [
    isForecastingParametersManualPatientActivityEnabled,
    isForecastingParametersManualSiteActivityEnabled,
    isTrialAveragesEnabled,
    selectedForecastType,
  ]);

  function handleItemClick(event: SyntheticEvent) {
    const liElement = (event.target as HTMLElement).closest('li')!;
    const ulElement = liElement.parentElement as HTMLUListElement;
    const liElements = [...ulElement.children].filter(
      (element) => element.tagName === 'LI',
    );
    // Subtract 1 for the "Forecast by" section title and each of the options in that section
    onItemClick(liElements.indexOf(liElement) - 1 - FORECAST_OPTIONS.length);
  }

  return (
    <Box
      bgcolor=""
      sx={{
        padding: 2,
        bgcolor: 'common.white',
        borderRadius: 1,
        border: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      <List>
        <ParametersMenuHeader
          icon={TuneIcon}
          text="Forecast by"
          onClick={handleItemClick}
        />
        {FORECAST_OPTIONS.map((option) => (
          <ParametersMenuItem
            key={option}
            selected={selectedForecastType === option}
            text={option}
            disabled={
              (option === 'Timeline' && !isForecastByTimelineEnabled) ||
              (option === 'Region & cohort' && !isForecastByCohortEnabled)
            }
            radio
            onClick={() => onForecastTypeChange(option)}
          />
        ))}

        <Divider sx={{ my: 1 }} />

        <ParametersMenuHeader
          icon={FilterIcon}
          text="Scenario planning"
          onClick={handleItemClick}
        />
        {scenarioPlanning.map((text) => (
          <ParametersMenuItem
            key={text}
            text={text}
            onClick={handleItemClick}
          />
        ))}

        {isParametersNewSectionsEnabled && (
          <>
            <Divider sx={{ my: 1 }} />
            <ParametersMenuHeader
              icon={ViewTimelineOutlinedIcon}
              text="Contract assumptions"
              onClick={handleItemClick}
            />
            {CONTRACT_ASSUMPTIONS.filter(
              (text) =>
                text !== 'Timeline' || selectedForecastType !== 'Timeline',
            ).map((text) => (
              <ParametersMenuItem
                key={text}
                text={text}
                onClick={handleItemClick}
              />
            ))}
          </>
        )}
      </List>
    </Box>
  );
}

export type ForecastType = (typeof FORECAST_OPTIONS)[number];
export default ParametersMenu;

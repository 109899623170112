import type { ChangeEvent } from 'react';
import { useState } from 'react';

import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';

import type { TrialRegionResponse } from 'shared/lib/types';
import { selectTrial } from 'shared/state/slices/trialSlice';

import {
  useCreateRegionMutation,
  useGetRegionsByTrialQuery,
} from 'shared/api/rtkq/trialregions';

import Modal from '../modal/Modal';
import CondorTextField from '../text-field/CondorTextField';

type Props = {
  onClose: () => void;
  onSave: (region: TrialRegionResponse) => void;
};

const REGION_EXISTS_ERROR = 'This region already exists';
const HIDDEN_REGION_EXISTS_ERROR =
  'This region already exists, but is hidden. Please unhide the region in the trial record.';

function AddTrialRegionDialog(props: Props) {
  const { onClose, onSave } = props;
  const trial = useSelector(selectTrial);
  const [regionName, setRegionName] = useState('');
  const { currentData: regions } = useGetRegionsByTrialQuery(trial.trace_id);
  const [createRegion, { isLoading }] = useCreateRegionMutation();

  function validateRegionName(): string | undefined {
    const existingRegion = regions?.find(
      (region) => region.name === regionName,
    );

    if (!existingRegion) {
      return undefined;
    }

    return existingRegion.is_hidden
      ? HIDDEN_REGION_EXISTS_ERROR
      : REGION_EXISTS_ERROR;
  }

  async function handleSave() {
    await createRegion({
      name: regionName,
      trial: trial.trace_id,
    })
      .unwrap()
      .then((region) => {
        onSave(region);
        onClose();
      });
  }

  function handleChangeRegionName(event: ChangeEvent<HTMLInputElement>) {
    setRegionName(event.target.value);
  }

  const error = validateRegionName();

  return (
    <Modal
      handleClose={onClose}
      title="Add trial region"
      ButtonProps={{
        label: 'Save',
        testId: 'add_trial_region',
        size: 'small',
        onClick: () => void handleSave(),
        loading: isLoading,
        disabled: !!error || regionName.trim().length === 0,
      }}
      isOpen
    >
      <Typography>
        Regions added at the contract level will be added as a trial region.
      </Typography>
      <br />
      <CondorTextField
        errors={error}
        label="Region name"
        placeholder="Add name"
        fullWidth
        required
        onChange={handleChangeRegionName}
      />
    </Modal>
  );
}

export default AddTrialRegionDialog;

import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { useSelector } from 'react-redux';

import * as routes from 'routes';
import useFeatureFlag from 'shared/helpers/useFeatureFlag';
import { selectSidebarCollapsed } from 'shared/state/slices/sidebarSlice';
import BackToCompany from 'shared/lib/sidebar/BackToCompany';
import SidebarContainer, { ICON_SX } from 'shared/lib/sidebar/SidebarContainer';
import SidebarMenuItems from 'shared/lib/sidebar/SidebarMenuItems';
import SidebarTitle from 'shared/lib/sidebar/SidebarTitle';

const SETTINGS_MENU_ITEMS_BASE = [
  {
    title: 'Company settings',
    link: routes.getCompanySettings(),
    icon: <SettingsOutlinedIcon sx={ICON_SX} />,
  },
];

function SettingsSidebar() {
  const collapsed = useSelector(selectSidebarCollapsed);
  const isUsersAndPermissionsEnabled = useFeatureFlag('users_and_permissions');
  const isControlListingEnabled = useFeatureFlag('control_listing');

  const items = [...SETTINGS_MENU_ITEMS_BASE];
  if (isUsersAndPermissionsEnabled) {
    items.push({
      title: 'Users & permissions',
      link: routes.getUsersAndPermissions(),
      icon: <GroupsOutlinedIcon sx={ICON_SX} />,
    });
  }

  if (isControlListingEnabled) {
    items.push({
      title: 'Control listing',
      link: routes.getControlListing(),
      icon: <GppGoodOutlinedIcon sx={ICON_SX} />,
    });
  }

  return (
    <SidebarContainer>
      <BackToCompany collapsed={collapsed} label="Settings" />
      <SidebarTitle
        collapsed={collapsed}
        testId="settings-section-name"
        title=""
      />
      <SidebarMenuItems collapsed={collapsed} items={items} />
    </SidebarContainer>
  );
}

export default SettingsSidebar;

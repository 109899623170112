import type { ForwardedRef } from 'react';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';

import type { SxProps } from '@mui/material/styles';
import Box from '@mui/material/Box';

import type { AgGridRef } from 'shared/components/ag-grid/CondorAgGrid';
import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import LockGridReminder from 'shared/components/lock-grid/LockGridReminder';
import useLockedGridStatus from 'shared/components/lock-grid/useLockedGridStatus';
import useGridColDefs from 'shared/components/ag-grid/hooks/useGridColDefs';

import { LockGrid } from 'shared/lib/types';
import type {
  PatientAssessment,
  PatientAssessmentEditor,
  TraceId,
} from 'shared/lib/types';
import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';

import useVisitsCohortRows from './useVisitsCohortRows';
import useVisitsCohortColumnDefs from './useVisitsCohortColumnDefs';
import useVisitsCohortGridOptions from './useVisitsCohortGridOptions';

type Props = {
  cohortId: TraceId;
  overlayNoRowsTemplate?: string;
  sx?: SxProps;
};

function VisitsCohortGrid(
  props: Props,
  ref: ForwardedRef<
    AgGridRef<PatientAssessment | PatientAssessmentEditor> | undefined
  >,
) {
  const { cohortId, overlayNoRowsTemplate, sx } = props;

  const gridRef =
    useRef<AgGridRef<PatientAssessment | PatientAssessmentEditor>>();
  useImperativeHandle(ref, () => gridRef.current ?? undefined, []);

  const isGridLocked = useLockedGridStatus(LockGrid.SOA_VISITS, cohortId);

  useEffect(() => {
    const { api: gridApi } = gridRef.current ?? {};
    if (!gridApi) {
      return;
    }

    gridApi.forEachNode((rowNode) => {
      if (rowNode.data && 'isNew' in rowNode.data && rowNode.data.isNew) {
        gridApi.applyTransaction({
          remove: [rowNode.data],
        });
      }
    });
  }, [gridRef.current, isGridLocked]);

  const columnDefs = useGridColDefs(useVisitsCohortColumnDefs, [
    cohortId,
    isGridLocked,
  ]);
  const rows = useVisitsCohortRows(cohortId);
  const gridOptions = useVisitsCohortGridOptions(cohortId);

  return (
    <FlexGridContainer>
      <CondorAgGrid
        ref={gridRef}
        columnDefs={columnDefs}
        domLayout="normal"
        gridOptions={gridOptions}
        overlayNoRowsTemplate={overlayNoRowsTemplate}
        rowData={rows}
        sx={sx}
      />
      <Box mt={1}>
        <LockGridReminder grid={LockGrid.SOA_VISITS} locationSlug={cohortId} />
      </Box>
    </FlexGridContainer>
  );
}

export default forwardRef(VisitsCohortGrid);

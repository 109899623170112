import { type ChangeEvent, useEffect, useState } from 'react';

import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import FormControlLabel, {
  formControlLabelClasses,
} from '@mui/material/FormControlLabel';

import TrialAccessCheckboxComponent from 'shared/components/user-permissions/components/TrialAccessCheckboxComponent';
import Checkbox from 'shared/ui/checkbox/Checkbox';
import IconButton from 'shared/ui/icon-button/IconButton';

import type { TraceId, TrialCheckbox, TrialResponse } from 'shared/lib/types';

type CompositeCompanyProgramTrial = {
  companyTraceId: string;
  companyName: string;
  programs: Array<{
    programTraceId: string;
    programName: string;
    trials: TrialResponse[];
  }>;
};

type PartnerTrialAccessCheckboxProps = {
  checkBoxDisabled: boolean;
  companyProgramsGroup: CompositeCompanyProgramTrial[];
  handleCompanyCheckboxOnChange: (
    event: ChangeEvent<HTMLInputElement>,
    programTraceId: string,
  ) => void;
  handleProgramCheckboxOnChange: (
    event: ChangeEvent<HTMLInputElement>,
    programTraceId: string,
  ) => void;
  handleTrialCheckboxOnChange: (
    event: ChangeEvent<HTMLInputElement>,
    trialTraceId: string,
  ) => void;
  trialCheckboxes: TrialCheckbox[];
};

export default function PartnerTrialAccessCheckbox(
  props: PartnerTrialAccessCheckboxProps,
) {
  const {
    checkBoxDisabled,
    handleCompanyCheckboxOnChange,
    handleProgramCheckboxOnChange,
    handleTrialCheckboxOnChange,
    trialCheckboxes,
    companyProgramsGroup,
  } = props;

  const [companyCheckBoxes, setCompanyCheckBoxes] = useState<
    Record<TraceId, boolean>
  >({});

  useEffect(() => {
    setCompanyCheckBoxes(
      Object.fromEntries(
        companyProgramsGroup.map(({ companyTraceId }) => [
          String(companyTraceId),
          true,
        ]),
      ),
    );
  }, [companyProgramsGroup]);

  const handleCompanyCheckboxDropdown = (companyTraceId: TraceId) => {
    setCompanyCheckBoxes({
      ...companyCheckBoxes,
      [companyTraceId]: !companyCheckBoxes[companyTraceId],
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '500px',
        overflowY: 'scroll',
      }}
    >
      {companyProgramsGroup.map(({ companyTraceId, companyName, programs }) => {
        const trialCheckboxesForCompany = trialCheckboxes.filter(
          (trial) => trial.companyTraceId === companyTraceId,
        );
        const companyChecked = trialCheckboxesForCompany.every(
          (trial) => trial.checked,
        );
        const companyIndeterminate =
          !companyChecked &&
          trialCheckboxesForCompany.some((trial) => trial.checked);

        const companyIsOpen = companyCheckBoxes[companyTraceId];

        return (
          <Box key={companyTraceId}>
            <Divider
              sx={{ paddingTop: '4px', borderBottom: '2px lightgrey solid' }}
            />
            <Box
              sx={{
                display: 'flex',
                flexFlow: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <FormControlLabel
                label={companyName}
                control={
                  <Checkbox
                    checked={companyChecked}
                    disabled={checkBoxDisabled}
                    indeterminate={companyIndeterminate}
                    onChange={(event) =>
                      handleCompanyCheckboxOnChange(event, companyTraceId)
                    }
                  />
                }
                sx={{
                  [`.${formControlLabelClasses.label}`]: { fontWeight: 'bold' },
                }}
              />
              <IconButton
                onClick={() => handleCompanyCheckboxDropdown(companyTraceId)}
              >
                {companyIsOpen ? (
                  <ArrowDropUpOutlinedIcon />
                ) : (
                  <ArrowDropDownOutlinedIcon />
                )}
              </IconButton>
            </Box>
            <Collapse in={companyIsOpen}>
              <TrialAccessCheckboxComponent
                allowScroll={false}
                checkBoxDisabled={checkBoxDisabled}
                handleProgramCheckboxOnChange={handleProgramCheckboxOnChange}
                handleTrialCheckboxOnChange={handleTrialCheckboxOnChange}
                trialCheckboxes={trialCheckboxes}
                trialsByProgram={programs}
                moveRight
              />
            </Collapse>
          </Box>
        );
      })}
    </Box>
  );
}

import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import {
  missingForecast,
  selectForecast,
} from 'forecasting/state/slices/forecastSlice';
import * as routes from 'routes';
import PageContentLoadingContainer from 'shared/containers/page-content-loading-container/PageContentLoadingContainer';
import { CRO_CONTRACT_TABS } from 'shared/helpers/helpers';

import withForecastingPageRequiredData from 'forecasting/api/hocs/withForecastingPageRequiredData';

import ContractContainerTabs from './components/ContractContainerTabs';
import DetailTab from './components/DetailTab';
import ExpensesTab from './components/ExpensesTab';
import ForecastingTabs from './components/ForecastingTabs';
import ParametersTab from './components/ParametersTab';
import ShowActuals from './components/ShowActuals';
import SummaryTab from './components/SummaryTab';

function ForecastingPage() {
  const { activeTab } = useParams<{
    activeTab: 'assumptions' | 'detail' | 'expenses' | 'parameters' | 'summary';
  }>();
  const forecast = useSelector(selectForecast);
  const navigate = useNavigate();

  useEffect(() => {
    // If we dont have forecast, but we are still in a tab, assume we should navigate out of forecast details
    if (activeTab && forecast === missingForecast) {
      navigate(routes.getForecast());
    }
    // if we have a forecast but no active tab, assume we should navigate to the summary tab
    else if (!activeTab && forecast !== missingForecast) {
      navigate(routes.getForecastSummaryTab(forecast.trace_id));
    }
  }, [activeTab, forecast, navigate]);

  const [activeSubTab, setActiveSubTab] = useState(
    CRO_CONTRACT_TABS.DIRECT_FEES,
  );
  const [showActuals, setShowActuals] = useState<boolean>(true);

  // we have to wait for the above `useEffect` to do its thing
  if (!activeTab) {
    return null;
  }

  return (
    <PageContentLoadingContainer
      sx={{
        p: 0,
        display: 'flex',
        flexFlow: 'column',
      }}
      tabs={
        <Box sx={{ display: 'flex', alignItems: 'stretch' }}>
          <Box sx={{ flex: 1 }}>
            <ForecastingTabs />
            {activeTab === 'expenses' && <ContractContainerTabs />}
          </Box>
        </Box>
      }
    >
      <Box
        sx={{
          p: 4,
          transition: ({ transitions }) =>
            transitions.create(['margin-bottom']),
          ...(activeTab === 'parameters' && {
            minHeight: 0,
            display: 'flex',
            flex: 1,
          }),
        }}
      >
        {activeTab === 'summary' && <SummaryTab />}
        {activeTab === 'detail' && <DetailTab />}
        {activeTab === 'parameters' && <ParametersTab />}
        {activeTab === 'expenses' && (
          <>
            <ShowActuals
              setShowActuals={setShowActuals}
              showActuals={showActuals}
            />
            <ExpensesTab
              activeSubTab={activeSubTab}
              setActiveSubTab={setActiveSubTab}
              showActuals={showActuals}
            />
          </>
        )}
      </Box>
    </PageContentLoadingContainer>
  );
}

export default withForecastingPageRequiredData(ForecastingPage);

import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { LockGrid } from 'shared/lib/types';
import { selectTrial } from 'shared/state/slices/trialSlice';
// eslint-disable-next-line import/no-restricted-paths -- this files knows how to handle this empty correctly
import { selectPeriod } from 'accruals/state/slices/periodSlice';

function getLockGridTracking(grid: LockGrid): LockGridTrackBy {
  switch (grid) {
    case LockGrid.CRO_DIRECT_FEES_EXPENSE_GRID:
    case LockGrid.CRO_DIRECT_FEES_RECONCILIATION_GRID:
    case LockGrid.CRO_PASS_THROUGHS_EXPENSE_GRID:
    case LockGrid.CRO_PASS_THROUGHS_RECONCILIATION_GRID:
    case LockGrid.CRO_INVESTIGATOR_FEES_RECONCILIATION_GRID:
    case LockGrid.OCC_EXPENSE_GRID:
    case LockGrid.OCC_RECONCILIATION_GRID:
    case LockGrid.ACCRUALS_GRID:
      return 'period';
    case LockGrid.SITE_COST_MATRIX:
    case LockGrid.LAB_COST_MATRIX:
    case LockGrid.SOA_VISITS:
      return 'trial';
  }
}

function useLockGridQueryParams(grid: LockGrid, locationSlug: string | null) {
  const trial = useSelector(selectTrial);
  const period = useSelector(selectPeriod);

  const params: Record<string, string | undefined> = {
    grid,
    trial: trial.trace_id,
  };

  const trackBy = getLockGridTracking(grid);
  if (trackBy === 'period') {
    params.period = period.trace_id;
  }

  if (locationSlug) {
    params.location_slug = locationSlug;
  }

  return useMemo(() => params, [grid, trial, period, locationSlug]);
}

type LockGridTrackBy = 'period' | 'trial';

export default useLockGridQueryParams;

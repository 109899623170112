import { skipToken } from '@reduxjs/toolkit/query';

import SnapshotRecordsTable from 'shared/wizards/csv-upload-wizards/validate-and-check-table/SnapshotRecordsTable';
import columns from 'shared/wizards/invoice-upload-wizard/columns';
import WizardStep from 'shared/wizards/steps/WizardStep';

import { useGetHistoricInvoiceListingRecordsBySnapshotQuery } from 'shared/api/rtkq/historicinvoicerecords';

type InvoiceReviewStepProps = { snapshotId?: string; onComplete: () => void };

function HistoricInvoiceReviewStep(props: InvoiceReviewStepProps) {
  const { onComplete, snapshotId } = props;
  const { currentData: allRecords } =
    useGetHistoricInvoiceListingRecordsBySnapshotQuery(snapshotId ?? skipToken);

  return (
    <WizardStep
      description="Check that all information looks right before saving your historic invoice"
      header="Last step, make sure everything looks right."
      onNext={onComplete}
    >
      <SnapshotRecordsTable headers={columns} rows={allRecords} />
    </WizardStep>
  );
}

export default HistoricInvoiceReviewStep;

import CircularProgress from '@mui/material/CircularProgress';

function GradientCircularProgress() {
  return (
    <>
      <svg height={0} width={0}>
        <defs>
          <linearGradient id="my_gradient" x1="0%" x2="0%" y1="0%" y2="100%">
            <stop offset="0%" stopColor="#000000" />
            <stop offset="100%" stopColor="#613df0" />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress
        size="8rem"
        sx={{
          'svg circle': { stroke: 'url(#my_gradient)' },
        }}
      />
    </>
  );
}
export default GradientCircularProgress;

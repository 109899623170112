import FullWidthDialog from 'shared/lib/dialog/FullWidthDialog';
import type { IntegrationResponse, PeriodResponse } from 'shared/lib/types';
import Wizard from 'shared/lib/wizard/Wizard';

import ExportLoadingStep from '../components/ExportLoadingStep';

type Props = {
  integration: IntegrationResponse;
  period: PeriodResponse;
  setShowIntegration: (showIntegration: boolean) => void;
  title: string;
};

function IntegrationExportWizard(props: Props) {
  const { integration, setShowIntegration, period, title } = props;

  return (
    <FullWidthDialog open>
      <Wizard
        stepNames={['Data Export']}
        title={title}
        onClose={() => setShowIntegration(false)}
      >
        <ExportLoadingStep integration={integration} period={period} />
      </Wizard>
    </FullWidthDialog>
  );
}

export default IntegrationExportWizard;

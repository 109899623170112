import { type ChangeEvent, type SyntheticEvent } from 'react';

import type { AutocompleteChangeReason } from '@mui/base/useAutocomplete/useAutocomplete';
import Delete from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';

import CondorTextField from 'shared/components/text-field/CondorTextField';
import Autocomplete from 'shared/ui/autocomplete/Autocomplete';
import Chip from 'shared/ui/chip/Chip';
import IconButton from 'shared/ui/icon-button/IconButton';

import type {
  DropdownOption,
  RegionGroupListItemType,
  RegionListItemType,
} from 'shared/lib/types';
import { CRUDAction } from 'shared/lib/types';

import { validateRegionGroup, validateRegions } from './validators';

type Props = {
  canDelete?: boolean;
  disabled?: boolean;
  label: string;
  regionGroup: RegionGroupListItemType;
  regionGroups: RegionGroupListItemType[];
  regions: RegionListItemType[];
  onRegionGroupChange: (value: RegionGroupListItemType) => void;
};

function RegionGroupListItem(props: Props) {
  const {
    canDelete,
    regions,
    regionGroup,
    regionGroups,
    onRegionGroupChange,
    label,
    disabled = false,
  } = props;
  function handleDelete() {
    onRegionGroupChange({ ...regionGroup, action: CRUDAction.DELETE });
  }

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    onRegionGroupChange({
      ...regionGroup,
      action:
        regionGroup.action === CRUDAction.CREATE
          ? CRUDAction.CREATE
          : CRUDAction.UPDATE,
      name: event.target.value,
    });
  };

  const handleSelectChange = (
    _: SyntheticEvent,
    value: Array<DropdownOption<string>>,
    reason: AutocompleteChangeReason,
  ) => {
    onRegionGroupChange({
      ...regionGroup,
      regions: value.map((region) => region.label),
      action: reason === 'selectOption' ? CRUDAction.CREATE : CRUDAction.UPDATE,
    });
  };

  const inputValue: Array<DropdownOption<string>> | undefined =
    regionGroup.global
      ? regions.map((region) => ({
          value: region.name,
          label: region.name,
        }))
      : regionGroup.regions
          ?.filter((regionName) =>
            regions.map((region) => region.name).includes(regionName),
          )
          .map((regionName) => ({ value: regionName, label: regionName }));

  return (
    <ListItem disableGutters>
      <Box flex={1}>
        <CondorTextField
          disabled={regionGroup.global}
          label={label}
          type="text"
          value={regionGroup.name}
          errors={validateRegionGroup(
            regionGroup,
            regions.map((dbRegion) => ({ ...dbRegion, new: false })),
            regionGroups,
          )}
          fullWidth
          onChange={onChange}
        />
      </Box>
      <Box display="flex" flex={1}>
        <Box flex={1}>
          <Autocomplete
            disabled={regionGroup.global}
            errorMsg={validateRegions(regionGroup)}
            getOptionLabel={(region) => region.label}
            label="Regions"
            sx={{ ml: 2 }}
            value={inputValue}
            isOptionEqualToValue={(
              option: DropdownOption<string>,
              value: DropdownOption<string>,
            ) => option.label === value.label}
            options={regions.map((region) => ({
              value: region.name,
              label: region.name,
            }))}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                const { key, ...tagProps } = getTagProps({ index });
                return <Chip key={key} label={option.label} {...tagProps} />;
              })
            }
            filterSelectedOptions
            fullWidth
            multiple
            onChange={handleSelectChange}
          />
        </Box>
        <Box
          alignItems="center"
          display="flex"
          justifyContent="center"
          sx={{ width: ({ spacing }) => spacing(9) }}
        >
          <IconButton
            disabled={disabled || canDelete === false}
            edge="end"
            sx={{ ml: 2, height: 42, width: 42 }}
            onClick={handleDelete}
          >
            <Delete />
          </IconButton>
        </Box>
      </Box>
    </ListItem>
  );
}

export default RegionGroupListItem;

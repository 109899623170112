import type { InvoiceRecordResponse } from 'shared/lib/types';

import constructApi from './constructApi';

const PATH = 'historicinvoicerecords';

export const HISTORIC_INVOICE_LISTING_RECORDS_TAG =
  'HISTORIC_INVOICE_LISTING_RECORDS_TAG';

const apiEndpointFactory = constructApi<InvoiceRecordResponse>(PATH).withTags(
  HISTORIC_INVOICE_LISTING_RECORDS_TAG,
);

const api = apiEndpointFactory.inject(() => ({
  getHistoricInvoiceListingRecordsByCompany:
    apiEndpointFactory.getBy('company'),
  getHistoricInvoiceListingRecordsBySnapshot: apiEndpointFactory.getBy(
    'snapshot',
    'snapshot_row',
  ),
}));

export const { useGetHistoricInvoiceListingRecordsBySnapshotQuery } = api;

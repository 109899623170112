import { useMemo } from 'react';

import type { CellValueChangedEvent } from '@ag-grid-community/core';

import type { TraceId } from 'shared/lib/types';

import { useUpsertProcedureOccurrenceMutation } from 'shared/api/rtkq/procedureoccurrence';

const PART_OF_VISIT_PREFIX = 'part_of_visit_';
const OCCURRENCE_PREFIX = 'occurrence_';

function useAssociatedVisitsGridOptions() {
  const [upsertProcedureOccurrence] = useUpsertProcedureOccurrenceMutation();

  return useMemo(() => {
    const onCellValueChanged = (params: CellValueChangedEvent) => {
      const columnName = params.colDef.field ?? '';
      if (
        columnName.startsWith(PART_OF_VISIT_PREFIX) ||
        columnName.startsWith(OCCURRENCE_PREFIX)
      ) {
        const [, visitTraceId] = columnName.split(
          new RegExp(`${PART_OF_VISIT_PREFIX}|${OCCURRENCE_PREFIX}`),
        );
        const partOfVisit =
          params.data[`${PART_OF_VISIT_PREFIX}${visitTraceId}`];
        const occurrence = params.data[`${OCCURRENCE_PREFIX}${visitTraceId}`];

        void upsertProcedureOccurrence({
          procedure_category: params.data.procedure as TraceId,
          patient_assessment: visitTraceId as TraceId,
          part_of_visit: partOfVisit,
          occurrence:
            partOfVisit && (occurrence === undefined || occurrence === null)
              ? 100
              : occurrence, // 100% by default
        });
      }

      params.api.redrawRows();
    };

    return {
      getRowId: 'procedure',
      onCellValueChanged,
    };
  }, [upsertProcedureOccurrence]);
}

export default useAssociatedVisitsGridOptions;

import { useMemo } from 'react';

import Grid from '@mui/material/Grid';

import DashboardCard from 'shared/ui/dashboard-card/DashboardCard';

import EnrollmentTrialActivityGrid from 'accruals/pages/trial/grids/EnrollmentTrialActivityGrid';
import SitesTrialActivityGrid from 'accruals/pages/trial/grids/SitesTrialActivityGrid';

export default function TrialActivityCard() {
  const gridSx = useMemo(() => ({ mb: 5 }), []);

  return (
    <Grid xs={12} item>
      <DashboardCard title="Trial activity">
        <EnrollmentTrialActivityGrid sx={gridSx} />
        <SitesTrialActivityGrid />
      </DashboardCard>
    </Grid>
  );
}

import { skipToken } from '@reduxjs/toolkit/query';
import { useParams } from 'react-router-dom';

import useContractAndPeriodWithVersions from 'accruals/pages/clinical-expenses/shared/hooks/useContractAndPeriodWithVersions';
import { processRegionsAndGroupsListInGrids } from 'shared/hook-helpers/processRegionsAndGroupsListInGrids';
import type { RegionAndRegionGroupResponse } from 'shared/lib/types';

import { useGetAipContractVersionQuery } from 'shared/api/rtkq/contractcontainers';
import { useGetRegionGroupsByContractVersionQuery } from 'shared/api/rtkq/regiongroups';
import { useGetRegionsByContractVersionQuery } from 'shared/api/rtkq/regions';

export default function useRegionsAndGroupsListInGrids(): RegionAndRegionGroupResponse[] {
  const { contractContainer, contractVersionTraceId, period } =
    useContractAndPeriodWithVersions();
  const { activeSubTab } = useParams();
  let contractVersionId = contractVersionTraceId;
  const { data: aipContract } = useGetAipContractVersionQuery(
    contractContainer?.trace_id && period
      ? {
          trace_id: contractContainer.trace_id,
          otherParameter: period.trace_id,
        }
      : skipToken,
  );

  // For recon grids we are only updating aipAssignedRegion for AiP Section
  // so that's why we are loading the AiP's Contract Version's regions and region groups
  if (activeSubTab === 'recon') {
    contractVersionId = aipContract?.trace_id;
  }

  const { currentData: regions } = useGetRegionsByContractVersionQuery(
    contractVersionId ?? skipToken,
  );
  const { currentData: regionGroups } =
    useGetRegionGroupsByContractVersionQuery(contractVersionId ?? skipToken);
  return processRegionsAndGroupsListInGrids(regions, regionGroups);
}

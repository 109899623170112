import { useState } from 'react';

import { skipToken } from '@reduxjs/toolkit/query';

import type { SnapshotRecordType } from 'shared/lib/types';
import SnapshotRecordsTable from 'shared/wizards/csv-upload-wizards/validate-and-check-table/SnapshotRecordsTable';
import WizardStep from 'shared/wizards/steps/WizardStep';

import { useGetHistoricPurchaseOrderRecordsBySnapshotQuery } from 'shared/api/rtkq/historicpurchaseorderrecords';

import columns from '../../purchase-order/columns'; // these always need to match

type Props = { snapshotId?: string };

function HistoricPurchaseOrdersConfirmationStep(props: Props) {
  const { snapshotId } = props;
  const [selectedErrorIndex, setSelectedErrorIndex] = useState<number>(0);
  const { currentData: allRecords } =
    useGetHistoricPurchaseOrderRecordsBySnapshotQuery(snapshotId ?? skipToken);

  return (
    <WizardStep header="No issues detected. Click on Save & Continue to proceed">
      <SnapshotRecordsTable
        headers={columns}
        rows={allRecords as SnapshotRecordType[]}
        selectedErrorIndex={selectedErrorIndex}
        setSelectedErrorIndex={setSelectedErrorIndex}
        showValidationSidebar
      />
    </WizardStep>
  );
}

export default HistoricPurchaseOrdersConfirmationStep;

import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { processForecastParameters } from 'forecasting/pages/forecasting/utils/forecastUtils';
import { selectForecast } from 'forecasting/state/slices/forecastSlice';
import { ForecastParameterType } from 'shared/lib/types';

import { useGetForecastParametersByForecastQuery } from 'shared/api/rtkq/forecastparameters';
import { useDefaultParametersQuery } from 'shared/api/rtkq/forecasts';

function useForecastPatientsRows() {
  const forecast = useSelector(selectForecast);
  const { currentData: defaultParameters } = useDefaultParametersQuery(
    forecast.trace_id,
  );

  const { currentData: forecastParameters } =
    useGetForecastParametersByForecastQuery(forecast.trace_id);

  return useMemo(() => {
    if (!defaultParameters || !forecastParameters) {
      return [];
    }

    const screenedRows = processForecastParameters(
      defaultParameters,
      forecastParameters,
      'Patients screened',
      ForecastParameterType.PATIENTS_SCREENED_RATE,
    );

    const enrolledRows = processForecastParameters(
      defaultParameters,
      forecastParameters,
      'Patients enrolled',
      ForecastParameterType.PATIENTS_ENROLLED_RATE,
    );

    const completedRows = processForecastParameters(
      defaultParameters,
      forecastParameters,
      'Patients completed',
      ForecastParameterType.PATIENTS_COMPLETED_RATE,
    );

    return [...screenedRows, ...enrolledRows, ...completedRows];
  }, [defaultParameters, forecastParameters]);
}

export default useForecastPatientsRows;

import { useMemo, useState } from 'react';

import Box from '@mui/material/Box';

import DashboardCard from 'shared/ui/dashboard-card/DashboardCard';

import PageContentLoadingContainer from 'shared/containers/page-content-loading-container/PageContentLoadingContainer';
import type { CurrencyViewMode } from 'shared/lib/currency-toggle-group/CurrencyToggleGroup';
import CurrencyToggleGroup from 'shared/lib/currency-toggle-group/CurrencyToggleGroup';

import withAccrualPageRequiredData from 'accruals/api/hocs/withAccrualPageRequiredData';

import ClinicalExpenseSummaryGridHeader from './components/ClinicalExpenseSummaryGridHeader';
import ClinicalExpenseSummaryGrid from './grids/ClinicalExpenseSummaryGrid';

function SummaryPage() {
  const [currencyViewMode, setCurrencyViewMode] =
    useState<CurrencyViewMode>('trial');
  const userDisplayOptions = useMemo(
    () => ({
      currencyViewMode,
    }),
    [currencyViewMode],
  );

  return (
    <PageContentLoadingContainer>
      <DashboardCard
        sx={{ display: 'flex', flexFlow: 'column' }}
        title="Clinical Expense Summary"
        titleAction={
          <Box alignItems="center" display="flex" gap={1}>
            <CurrencyToggleGroup
              setValue={setCurrencyViewMode}
              testId="ClinicalExpenseSummaryToggle"
              value={currencyViewMode}
            />
            <ClinicalExpenseSummaryGridHeader />
          </Box>
        }
      >
        <ClinicalExpenseSummaryGrid userDisplayOptions={userDisplayOptions} />
      </DashboardCard>
    </PageContentLoadingContainer>
  );
}

export default withAccrualPageRequiredData(SummaryPage);

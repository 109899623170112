import { useMemo } from 'react';

import type {
  RegionAndRegionGroupResponse,
  RegionGroupResponse,
  RegionResponse,
} from 'shared/lib/types';

export const processRegionsAndGroupsListInGrids = (
  regions: RegionResponse[] | undefined,
  regionGroups: RegionGroupResponse[] | undefined,
) =>
  useMemo(() => {
    const returnValue: RegionAndRegionGroupResponse[] = [];

    for (const region of regions ?? []) {
      returnValue.push({
        name: region.name,
        trace_id: region.trace_id,
        type: 'Region',
      });
    }
    returnValue.push({ name: 'Global', trace_id: '', type: 'Global' });

    for (const regionGroup of regionGroups ?? []) {
      returnValue.push({
        name: regionGroup.name,
        trace_id: regionGroup.trace_id,
        type: 'RegionGroup',
      });
    }
    return returnValue;
  }, [regions, regionGroups]);

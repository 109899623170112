import { useParams } from 'react-router-dom';

import NavigationTabs from 'shared/components/tabs/NavigationTabs';

import { getScheduleOfAssessmentsSubTab } from 'routes';

function ScheduleOfAssessmentsProceduresSubTabs() {
  const { activeTab, activeSubTab } = useParams();

  const tabs = [
    {
      key: SCHEDULE_SUB_TABS.PROCEDURE_NAMES,
      label: 'Procedure Names',
    },
    {
      key: SCHEDULE_SUB_TABS.ASSOCIATED_VISITS,
      label: 'Associated Visits',
    },
  ];

  if (!tabs.length || !activeSubTab) {
    return null;
  }

  return (
    <NavigationTabs
      activeTab={activeSubTab}
      sx={{ bgcolor: 'common.white' }}
      tabs={tabs}
      generateUrl={(newTab) =>
        getScheduleOfAssessmentsSubTab(activeTab, newTab)
      }
    />
  );
}

export const SCHEDULE_SUB_TABS = {
  PROCEDURE_NAMES: 'procedure-names',
  ASSOCIATED_VISITS: 'associated-visits',
};

export default ScheduleOfAssessmentsProceduresSubTabs;

import { useNavigate } from 'react-router-dom';

import EmptyStateMessage from 'accruals/pages/trial-activity/schedule-of-assessments/EmptyStateMessage';
import { getScheduleOfAssessmentsTab } from 'routes';
import { SCHEDULE_TABS } from 'accruals/pages/trial-activity/schedule-of-assessments/ScheduleOfAssessmentsTabs';

function EmptyAssociatedVisits() {
  const navigate = useNavigate();

  function onClickManageVisits() {
    navigate(getScheduleOfAssessmentsTab(SCHEDULE_TABS.VISITS));
  }

  return (
    <EmptyStateMessage
      buttonText="Manage Visits"
      description="To configure associated visits, make sure you have at least one cohort and visit."
      testId="btn-manage-visits"
      title="There are no cohorts and/or visits to map your procedures to."
      onButtonClick={onClickManageVisits}
    />
  );
}

export default EmptyAssociatedVisits;

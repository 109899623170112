import type { PeriodMenuItemResponse } from 'shared/lib/types';

import constructApi from './constructApi';

const PATH = 'periodmenuitems';

const apiEndpointFactory =
  constructApi<PeriodMenuItemResponse>(PATH).withTags('PERIOD_MENU_ITEM');
const api = apiEndpointFactory.inject(() => ({
  upsertPeriodMenuItem: apiEndpointFactory.create<FormData>([], 'upsert'),
  getPeriodMenuItem: apiEndpointFactory.getWithFilters('-sort_order'),
}));

export const { useGetPeriodMenuItemQuery, useUpsertPeriodMenuItemMutation } =
  api;

import { useEffect, useState } from 'react';

import { skipToken } from '@reduxjs/toolkit/query';

import useContractAndPeriodWithVersions from 'accruals/pages/clinical-expenses/shared/hooks/useContractAndPeriodWithVersions';
import {
  type RegionsAndAssumptionGroups,
  calculateAssumptionGroups,
  createGroups,
} from 'shared/lib/contract-assumptions/hooks/useRegionsAndAssumptionGroups';
import type {
  AssumptionGroupRequestDisplay,
  RegionListItemType,
  TraceId,
} from 'shared/lib/types';
import { CRUDAction } from 'shared/lib/types';

import { useGetContractAssumptionGroupsByContractVersionQuery } from 'shared/api/rtkq/assumptiongroups';
import {
  useGetRegionsByContractContainerQuery,
  useGetRegionsByContractVersionQuery,
} from 'shared/api/rtkq/regions';

import { useAccrualsRegionGroups } from '../../../wizards/contract-version-wizard/useAccrualsRegionGroups';

export function useAccrualsRegionsAndAssumptionGroups(
  contractVersionTraceId: TraceId | undefined,
  readOnly = false,
): RegionsAndAssumptionGroups {
  const { contractContainerTraceId } = useContractAndPeriodWithVersions();

  const { currentData: databaseRegions } = useGetRegionsByContractVersionQuery(
    contractVersionTraceId ?? skipToken,
  );

  const { currentData: regionsFromContractContainer } =
    useGetRegionsByContractContainerQuery(
      contractContainerTraceId ?? skipToken,
    );

  const { currentData: databaseAssumptionGroups } =
    useGetContractAssumptionGroupsByContractVersionQuery(
      contractVersionTraceId ?? skipToken,
    );

  const [allRegions, setAllRegions] = useState<RegionListItemType[]>([]);

  const [assumptionGroups, setAssumptionGroups] = useState<
    AssumptionGroupRequestDisplay[]
  >([]);

  const { allRegionGroups } = useAccrualsRegionGroups(contractVersionTraceId);

  const editableRegions = allRegions.filter(
    (region) => region.action !== CRUDAction.DELETE,
  );

  function applyCalculations(
    updatedAssumptionGroups: AssumptionGroupRequestDisplay[],
  ) {
    setAssumptionGroups(calculateAssumptionGroups(updatedAssumptionGroups));
  }

  useEffect(() => {
    if (databaseRegions !== undefined) {
      setAllRegions(
        databaseRegions.map((dbRegion) => ({ ...dbRegion, new: false })),
      );
    }
  }, [databaseRegions, regionsFromContractContainer]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: as this examines assumptionGroups, which is what this generates, this will cause an infinite loop if added
  useEffect(() => {
    if (contractVersionTraceId) {
      setAssumptionGroups(
        createGroups(
          contractVersionTraceId,
          readOnly,
          allRegions,
          assumptionGroups,
          databaseAssumptionGroups,
        ),
      );
    }
  }, [contractVersionTraceId, allRegions, databaseAssumptionGroups]);

  return [
    editableRegions,
    assumptionGroups,
    allRegions,
    allRegionGroups,
    applyCalculations,
  ];
}

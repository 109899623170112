import type {
  IntegrationResponse,
  TraceId,
  UnprocessedRecordResponse,
} from 'shared/lib/types';

import { LATEST_INVOICE_SNAPSHOT_RECORD } from './companies';
import constructApi from './constructApi';
import { PURCHASE_ORDER_RECORDS_TAG } from './purchaseorderrecords';

const PATH = 'integrations';

const apiEndpointFactory =
  constructApi<IntegrationResponse>(PATH).withTags('INTEGRATIONS');
const api = apiEndpointFactory.inject(() => ({
  getIntegrationsByCompany: apiEndpointFactory.getWithFilters(),
  getUnprocessedRecords: apiEndpointFactory.getExtraRoute<
    UnprocessedRecordResponse[]
  >('get_unprocessed_records'),
  getDataFromIntegration: apiEndpointFactory.getExtraRoute<{
    records_imported: number;
  }>('new_from_integration'),
  exportDataToIntegration: apiEndpointFactory.create<{
    integration: TraceId;
    period: TraceId;
  }>([], 'new_to_integration'),
  convertUnprocessedRecords: apiEndpointFactory.create<{
    integration: TraceId;
    records: UnprocessedRecordResponse[];
  }>(
    [PURCHASE_ORDER_RECORDS_TAG, LATEST_INVOICE_SNAPSHOT_RECORD],
    'convert_unprocessed_records',
  ),
}));

export const {
  useGetIntegrationsByCompanyQuery,
  useGetDataFromIntegrationQuery,
  useExportDataToIntegrationMutation,
  useGetUnprocessedRecordsQuery,
  useConvertUnprocessedRecordsMutation,
} = api;

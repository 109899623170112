import { useState } from 'react';

import { useSelector } from 'react-redux';

import { selectPeriodVersion } from 'accruals/state/slices/periodVersionSlice';
import ColumnMapperStep from 'shared/lib/column-mapper/ColumnMapperStep';
import FullWidthDialog from 'shared/lib/dialog/FullWidthDialog';
import type {
  FileUploadType,
  TraceId,
  UnmappedFileResponse,
  UploadedFile,
} from 'shared/lib/types';
import { UnmappedFileEntityType } from 'shared/lib/types';
import Wizard from 'shared/lib/wizard/Wizard';
import useOnCloseLogic from 'shared/wizards/hooks/useOnCloseLogic';
import UploadUnmappedCsvStep from 'shared/wizards/upload-unmapped-step/csv/UploadUnmappedCsvStep';

import {
  useCreateProceduresEdcSnapshotFromCsvMutation,
  useDeleteEdcProceduresSnapshotMutation,
  usePostSaveProceduresRecordsMutation,
} from 'shared/api/rtkq/proceduresedcsnapshots';

import columns from './columns';
import EdcProceduresConfirmationStep from './steps/EdcProceduresConfirmationStep';
import EdcProceduresExtraFrame from './steps/EdcProceduresExtraFrame';
import EdcProceduresReviewStep from './steps/EdcProceduresReviewStep';

type Props = {
  fileUploadType: FileUploadType;
  onClose: () => void;
  onComplete?: (message: string) => void;
};

function EdcProceduresUploadWizard(props: Props) {
  const { onClose: onCloseProp, onComplete, fileUploadType } = props;
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [snapshotId, setSnapshotId] = useState<TraceId>();
  const [isAllInOneFile, setIsAllInOneFile] = useState(true);
  const [procedureType, setProcedureType] = useState('');

  const [deleteSnapshot, { isLoading: _deleteIsLoading }] =
    useDeleteEdcProceduresSnapshotMutation();
  const [setFfMappingId, handleCloseBtnClick] = useOnCloseLogic();
  const currentPeriodVersion = useSelector(selectPeriodVersion);

  function onClose(isLastStepSaveClick?: boolean) {
    handleCloseBtnClick(
      uploadedFiles,
      deleteSnapshot,
      setUploadedFiles,
      setSnapshotId,
      onCloseProp,
      isLastStepSaveClick,
      snapshotId,
    );
  }

  function saveUrl(fileResponses: UnmappedFileResponse[]) {
    setUploadedFiles(
      fileResponses.map((file) => ({
        csvBlobUrl: file.file,
        unmappedFileUrl: file.url,
        traceId: file.trace_id,
      })),
    );
  }

  return (
    <FullWidthDialog open>
      <Wizard
        devTitle="BRAN"
        startIndex={0}
        stepNames={['Map Columns', 'Validate', 'Review']}
        title="Upload EDC procedures"
        onClose={onClose}
      >
        <UploadUnmappedCsvStep
          accept=".csv"
          description="If you upload a report with procedures you've uploaded before, all the data from the previous file will be replaced with the new file data."
          entityClass={UnmappedFileEntityType.ProceduresEdcSnapshot}
          fileTypeWithGrammaticalArticle="an EDC report"
          fileUploadType={fileUploadType}
          header="Upload your EDC procedures report."
          periodVersion={currentPeriodVersion}
          setSnapshotId={setSnapshotId}
          titleOverride="Upload EDC procedures" // TODO: Should not need to override the title since it matched the one above
          ExtraFrame={(frameProps) => (
            <EdcProceduresExtraFrame
              isAllInOneFile={isAllInOneFile}
              procedureType={procedureType}
              setIsAllInOneFile={setIsAllInOneFile}
              setProcedureType={setProcedureType}
              {...frameProps}
            />
          )}
          onSave={saveUrl}
        />
        <ColumnMapperStep
          columns={columns(isAllInOneFile)}
          setFfMappingId={setFfMappingId}
          setSnapshotId={setSnapshotId}
          stepTitleText="Assign these headers to your EDC procedures grid."
          uploadedFiles={uploadedFiles}
          usePostRecordsSaveEndpoint={usePostSaveProceduresRecordsMutation}
          extraCreateSnapshotArgs={{
            snapshot: snapshotId ?? '',
            procedure_category: isAllInOneFile ? null : procedureType,
          }}
          useCreateSnapshotEndpoint={
            useCreateProceduresEdcSnapshotFromCsvMutation
          }
        />
        <EdcProceduresConfirmationStep
          isAllInOneFile={isAllInOneFile}
          snapshotId={snapshotId}
        />
        <EdcProceduresReviewStep
          isAllInOneFile={isAllInOneFile}
          snapshotId={snapshotId}
          onComplete={() =>
            onComplete
              ? onComplete('EDC Report uploaded successfully')
              : onCloseProp()
          }
        />
      </Wizard>
    </FullWidthDialog>
  );
}

export default EdcProceduresUploadWizard;

import { useMemo } from 'react';

import type { CondorGridOptions } from 'shared/components/ag-grid/types';

import useEditActivityDriver from 'shared/api/hooks/useEditActivityDriver';

import useForecast from './useForecast';

export default function useForecastedCroExpenseGridOptions(
  _isOpenPeriod: boolean,
  contractContainerSlug: string,
): CondorGridOptions {
  const { forecast } = useForecast();
  const [onCellValueChanged] = useEditActivityDriver({
    columnPrefix: 'forecast_',
    vendorType: 'CRO',
    isForecast: true,
    forecast: forecast.trace_id,
    contract_container: contractContainerSlug,
  });
  return useMemo(
    () => ({
      groupDisplayType: 'groupRows',
      getRowId: 'id',
      getRowStyle: 'highlightRow',
      suppressStickyTotalRow: 'group',
      groupTotalRow: 'bottom',
      grandTotalRow: 'bottom',
      suppressAggFuncInHeader: true,
      onCellValueChanged,
    }),
    [onCellValueChanged],
  );
}

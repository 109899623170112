import { useMemo, useRef, useState } from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';
import Typography, { type TypographyProps } from '@mui/material/Typography';

import { tooltip } from 'shared/components/ag-grid-cells/renderers/ag-grid-custom-cell-renderer/helpers';

import type { ForecastType } from 'forecasting/components/parameters-menu/ParametersMenu';
import ParametersMenu from 'forecasting/components/parameters-menu/ParametersMenu';

import useFeatureFlag from 'shared/helpers/useFeatureFlag';

import PatientAveragesGrid from '../grids/PatientAveragesGrid';
import PatientEnrollmentParamsGrid from '../grids/PatientEnrollmentParamsGrid';
import PatientMonthsParamsGrid from '../grids/PatientMonthsParamsGrid';
import PatientsParamsGrid from '../grids/PatientsParamsGrid';
import SiteActivationParamsGrid from '../grids/SiteActivationParamsGrid';
import SiteAveragesGrid from '../grids/SiteAveragesGrid';
import SiteMonthsParamsGrid from '../grids/SiteMonthsParamsGrid';
import SitesParamsGrid from '../grids/SitesParamsGrid';
import TimelineParamsGrid from '../grids/TimelineParamsGrid';
import PatientActivityContainer from './PatientActivityContainer';

const TARGET_SCROLL_SELECTOR = 'data-scroll-to-category';

const subheadingProps: TypographyProps & { [TARGET_SCROLL_SELECTOR]: boolean } =
  {
    mt: 4,
    variant: 'subtitle1',
    [TARGET_SCROLL_SELECTOR]: true,
  };

function ParametersTab() {
  const [selectedForecastType, setSelectedForecastType] =
    useState<ForecastType>('Patients per region');
  const isTrialAveragesEnabled = useFeatureFlag(
    'forecasting_parameters_trial_averages',
  );
  const containerRef = useRef<HTMLElement | null>(null);
  const isParametersNewSectionsEnabled = useFeatureFlag(
    'forecasting_parameters_new_sections',
  );
  const isForecastingParametersManualPatientActivityEnabled = useFeatureFlag(
    'forecasting_parameters_manual_patient_activity',
  );
  const isForecastingParametersManualSiteActivityEnabled = useFeatureFlag(
    'forecasting_parameters_manual_site_activity',
  );

  function onItemClick(index: number) {
    const containerOffset =
      containerRef.current?.getBoundingClientRect().top ?? 0;
    const target = containerRef.current?.querySelectorAll(
      `[${TARGET_SCROLL_SELECTOR}]`,
    )[index];

    if (target) {
      containerRef.current?.scrollTo({
        top: (target as HTMLElement).offsetTop - containerOffset,
        behavior: 'smooth',
      });
    }
  }

  const gridSx = useMemo(() => ({ mt: 2 }), []);

  return (
    <>
      <ParametersMenu
        selectedForecastType={selectedForecastType}
        onForecastTypeChange={setSelectedForecastType}
        onItemClick={onItemClick}
      />
      <Box
        ref={containerRef}
        sx={{
          ml: 3,
          overflowY: 'scroll',
          flex: 1,
        }}
      >
        <Typography
          color="primary.main"
          variant="h4"
          {...{ [TARGET_SCROLL_SELECTOR]: true }}
        >
          Scenario planning
        </Typography>
        {selectedForecastType === 'Timeline' && (
          <>
            <Typography {...subheadingProps}>Timeline</Typography>
            <TimelineParamsGrid sx={gridSx} />
          </>
        )}
        {isForecastingParametersManualPatientActivityEnabled ? (
          <PatientActivityContainer
            gridSx={gridSx}
            header={
              <Typography {...subheadingProps}>Patient activity</Typography>
            }
          />
        ) : (
          <>
            <Typography {...subheadingProps}>Patient enrollment</Typography>
            <PatientEnrollmentParamsGrid sx={gridSx} />
          </>
        )}
        {isForecastingParametersManualSiteActivityEnabled ? (
          <Typography {...subheadingProps}>Site activity</Typography>
        ) : (
          <>
            <Typography {...subheadingProps}>Site activation</Typography>
            <SiteActivationParamsGrid sx={gridSx} />
          </>
        )}
        {isParametersNewSectionsEnabled && (
          <>
            {isTrialAveragesEnabled && (
              <>
                <Typography {...subheadingProps}>Trial averages</Typography>
                <PatientAveragesGrid sx={gridSx} />
                <SiteAveragesGrid sx={gridSx} />
              </>
            )}
            <Typography
              color="primary.main"
              mt={4}
              variant="h4"
              {...{ [TARGET_SCROLL_SELECTOR]: true }}
            >
              Contract assumptions{' '}
              {tooltip(
                <InfoOutlinedIcon color="secondary" fontSize="small" />,
                <>
                  Patient and site counts for each region are based off the max
                  count found among all contracts in the trial.
                  <Box mt={1}>
                    Timeline start dates are based off the earliest start date
                    found, and end dates are based off the latest end date found
                    among all contracts.
                  </Box>
                </>,
              )}
            </Typography>
            <Typography {...subheadingProps}>Patients</Typography>
            <PatientsParamsGrid sx={gridSx} />
            <Typography {...subheadingProps}>Patient months</Typography>
            <PatientMonthsParamsGrid sx={gridSx} />
            <Typography {...subheadingProps}>Sites</Typography>
            <SitesParamsGrid sx={gridSx} />
            <Typography {...subheadingProps}>Site months</Typography>
            <SiteMonthsParamsGrid sx={gridSx} />
            {selectedForecastType !== 'Timeline' && (
              <>
                <Typography {...subheadingProps}>Timeline</Typography>
                <TimelineParamsGrid sx={gridSx} />
              </>
            )}
          </>
        )}
      </Box>
    </>
  );
}

export default ParametersTab;

import { useMemo } from 'react';

import type { SxProps } from '@mui/material/styles';

import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';

import usePatientActivityRows from '../hooks/usePatientActivityRows';
import usePatientActivityColDefs from '../hooks/usePatientActivityColDefs';
import usePatientActivityGridOptions from '../hooks/usePatientActivityGridOptions';

type Props = {
  overlayNoRowsTemplate?: string;
  sx?: SxProps;
};

function PatientActivityGrid(props: Props) {
  const { overlayNoRowsTemplate, sx } = props;
  const colDefs = usePatientActivityColDefs();
  const rows = usePatientActivityRows();
  const gridOptions = usePatientActivityGridOptions();

  const sxGrid = useMemo(
    () => ({
      ...sx,
      ...(!rows.length && { height: '200px' }),
    }),
    [sx, rows],
  );

  return (
    <CondorAgGrid
      columnDefs={colDefs}
      domLayout={rows.length ? 'autoHeight' : 'normal'}
      gridOptions={gridOptions}
      overlayNoRowsTemplate={overlayNoRowsTemplate}
      rowData={rows}
      sx={sxGrid}
    />
  );
}

export default PatientActivityGrid;

import { useMemo } from 'react';

import type { CondorColDef } from 'shared/components/ag-grid/types';

const usePatientAveragesGridColDefs = (): CondorColDef[] =>
  useMemo(
    () => [
      {
        field: 'row_title',
        headerName: 'Patient averages',
        flex: 1,
      },
      {
        field: 'actual_average',
        headerName: 'Actual',
        flex: 1,
        cellRendererSelector: 'optionalCurrencyNumberCellRendererSelector',
      },
      {
        field: 'forecasted_average',
        headerName: 'Scenario',
        flex: 1,
        cellRendererSelector: 'optionalCurrencyNumberCellRendererSelector',
      },
    ],
    [],
  );

export default usePatientAveragesGridColDefs;

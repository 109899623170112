import { useMemo } from 'react';

import { skipToken } from '@reduxjs/toolkit/query';

import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import useGridOptions from 'shared/components/ag-grid/hooks/useGridOptions';

import { useGetCurrentContractVersionQuery } from 'shared/api/rtkq/contractcontainers';
import { useGetContractInvestigatorFeesGridQuery } from 'shared/api/rtkq/contracts';

import useForecast from '../hooks/useForecast';
import useForecastedCroExpenseGridOptions from '../hooks/useForecastedCroExpenseGridOptions';
import useForecastedInvestigatorFeesColumnDefs from '../hooks/useForecastedInvestigatorFeesColumnDefs';
import useForecastedInvestigatorFeesRows from '../hooks/useForecastedInvestigatorFeesRows';
import type {
  GenericGridProps,
  PeriodSpecificProps,
} from './ForecastedExpenseGridProps';

function OpenPeriodForecastedInvestigatorFeesExpenseGrid(
  props: PeriodSpecificProps,
) {
  return (
    <GenericForecastedInvestigatorFeesExpenseGrid
      baseColDefs={undefined}
      processedExpenseData={[]}
      {...props}
    />
  );
}

function ClosedPeriodForecastedInvestigatorFeesExpenseGrid(
  props: PeriodSpecificProps,
) {
  // TODO: We don't have any data available for Closed Periods yet, so we're using open data
  return <OpenPeriodForecastedInvestigatorFeesExpenseGrid {...props} />;
}

function GenericForecastedInvestigatorFeesExpenseGrid(props: GenericGridProps) {
  const { sx, showActuals, contractContainerSlug } = props;

  const { generatedForecast, period } = useForecast();
  const { data: currentContract } = useGetCurrentContractVersionQuery(
    period
      ? {
          trace_id: contractContainerSlug,
          otherParameter: period.trace_id,
        }
      : skipToken,
  );

  const { currentData: expenseGridResponse } =
    useGetContractInvestigatorFeesGridQuery(
      currentContract && period
        ? {
            trace_id: currentContract.trace_id,
            otherParameter: period.trace_id,
          }
        : skipToken,
    );

  const columnDefs = useForecastedInvestigatorFeesColumnDefs(
    generatedForecast,
    showActuals,
  );

  let forecastedMonths: string[] = [];
  if (generatedForecast) {
    forecastedMonths = Object.keys(generatedForecast.expenses[0]).filter(
      (key) => key.startsWith('forecasted_month_'),
    );
  }

  const gridOptions = useGridOptions(useForecastedCroExpenseGridOptions, [
    contractContainerSlug,
  ]);

  const rowData = useForecastedInvestigatorFeesRows(
    generatedForecast,
    contractContainerSlug,
    forecastedMonths,
    expenseGridResponse?.monthly_values,
  );

  const userDisplayOptions = useMemo(
    () => ({ currencyViewMode: 'trial' as const }),
    [],
  );

  return (
    <CondorAgGrid
      columnDefs={columnDefs}
      gridOptions={gridOptions}
      rowData={rowData}
      sx={sx}
      userDisplayOptions={userDisplayOptions}
    />
  );
}

export default function ForecastedInvestigatorFeesExpenseGrid(
  props: PeriodSpecificProps,
) {
  const { period } = useForecast();

  return period?.is_closed ? (
    <ClosedPeriodForecastedInvestigatorFeesExpenseGrid {...props} />
  ) : (
    <OpenPeriodForecastedInvestigatorFeesExpenseGrid {...props} />
  );
}

import type { ChangeEvent } from 'react';
import { useRef } from 'react';

import ReactDOM from 'react-dom';

import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DeleteIcon from '@mui/icons-material/Delete';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import Box from '@mui/material/Box';
import ListItem, { getListItemUtilityClass } from '@mui/material/ListItem';
import Switch, { getSwitchUtilityClass } from '@mui/material/Switch';
import Radio, { getRadioUtilityClass } from '@mui/material/Radio';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import RadioGroup from '@mui/material/RadioGroup';
import { useDispatch, useSelector } from 'react-redux';
import { DropIndicator } from '@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/box';

import IconButton, {
  getIconButtonUtilityClass,
} from 'shared/ui/icon-button/IconButton';
import CondorTextField, {
  getTextFieldUtilityClass,
} from 'shared/components/text-field/CondorTextField';
import Select from 'shared/ui/select/Select';

import type {
  LabelValueOption,
  PatientAssessment,
  PatientAssessmentEditor,
  PatientCohort,
  VisitType,
} from 'shared/lib/types';
import { PatientAssessmentTimelinePortionEnum } from 'shared/lib/types';
import {
  changeDayOfProtocol,
  changeTimelinePortion,
  changeVisitType,
  getNewCopyName,
  insertVisit,
  isVisitValid,
  mapVisitType,
  removeVisit,
  selectCohortNameEditor,
  toggleDayOfProtocol,
} from 'shared/state/slices/visitSchedulesSlice';
import HasPermission from 'shared/lib/permissions/HasPermission';

import { useDeletePatientAssessmentMutation } from 'shared/api/rtkq/patientassessments';

import useSavePatientVisit from '../../hooks/useSavePatientVisit';
import { mapPatientVisitFromApiResponse } from '../../mapper';
import VisitInput from './VisitInput';
import useDnd from './useDnd';
import draggableSx from './draggableSx';

type Props = {
  currentCohort: PatientCohort;
  index: number;
  visit: PatientAssessmentEditor;
};

function VisitItem(props: Props) {
  const { index, visit, currentCohort } = props;
  const [deleteVisit, { isLoading }] = useDeletePatientAssessmentMutation();
  const [savePatientVisit] = useSavePatientVisit();
  const ref = useRef<HTMLLIElement>(null);
  const dragHandleRef = useRef<HTMLElement>(null);
  const { draggableState, closestEdge } = useDnd(
    index,
    'visit',
    ref,
    dragHandleRef,
  );

  const dispatch = useDispatch();
  const cohortNameEditor = useSelector(selectCohortNameEditor);

  async function onRemoveVisit(visitToRemove: PatientAssessmentEditor) {
    await deleteVisit(visitToRemove.traceId!);
    dispatch(removeVisit({ visit: visitToRemove }));
  }

  async function onInsertNewVisit(targetVisit: PatientAssessment) {
    const { data: visitData } = await savePatientVisit({
      name: getNewCopyName(
        targetVisit.name,
        currentCohort.patientAssessments.map((item) => item.name),
      ),
      patientCohort: targetVisit.patientCohort,
      orderIndex: targetVisit.orderIndex + 1,
      mappedName: undefined,
      isScreen: false,
      isEnroll: false,
      isScreenfail: false,
      isDroppedCompleted: false,
      dayOfProtocol: null,
      isNew: true,
      timelinePortion: PatientAssessmentTimelinePortionEnum.OTHER,
    });

    if (!visitData) {
      return;
    }

    dispatch(
      insertVisit({
        visit: mapPatientVisitFromApiResponse(visitData),
        afterVisitTraceId: targetVisit.traceId,
      }),
    );
  }

  return (
    <>
      <ListItem
        ref={ref}
        secondaryAction={
          <>
            <IconButton
              disabled={!isVisitValid(visit)}
              size="small"
              onClick={() => void onInsertNewVisit(visit)}
            >
              <ControlPointIcon />
            </IconButton>
            <HasPermission permissions={['canDeleteTrialInfo']}>
              <IconButton
                edge="end"
                size="small"
                disabled={
                  (currentCohort.patientAssessments.length === 1 &&
                    index === 0) ||
                  isLoading
                }
                onClick={() => void onRemoveVisit(visit)}
              >
                <DeleteIcon />
              </IconButton>
            </HasPermission>
          </>
        }
        sx={{
          ...(draggableState.type === 'dragging' && {
            [`&.${getListItemUtilityClass('root')}`]: draggableSx,
            [` .${getIconButtonUtilityClass('root')}`]: {
              color: (theme) => theme.palette.grey[500],
            },
            [` .${getRadioUtilityClass('root')}`]: {
              color: (theme) => theme.palette.grey[500],
            },
            [` .${getTextFieldUtilityClass('root')}`]: {
              backgroundColor: (theme) => theme.palette.grey[50],
            },
            [` .${getSwitchUtilityClass('thumb')}`]: {
              backgroundColor: (theme) => theme.palette.grey[400],
            },
            [` .${getSwitchUtilityClass('track')}`]: {
              boxShadow: (theme) =>
                `inset 0 0 0 2px ${theme.palette.grey[500]}`,
            },
            [`.${getSwitchUtilityClass('switchBase')}`]: {
              [`&.${getSwitchUtilityClass('checked')}`]: {
                [`+.${getSwitchUtilityClass('track')}`]: {
                  backgroundColor: (theme) => theme.palette.grey[500],
                },
              },
            },
          }),
        }}
        disableGutters
        divider
      >
        <ListItemText
          sx={{ display: 'flex', alignItems: 'center' }}
          primary={
            <>
              <ListItemIcon
                ref={dragHandleRef}
                color="green"
                sx={
                  !cohortNameEditor &&
                  currentCohort.patientAssessments.length > 1
                    ? {
                        cursor: 'grab',
                        color: 'grey.700',
                      }
                    : {
                        color: 'grey.300',
                      }
                }
              >
                <DragHandleIcon />
              </ListItemIcon>
              <VisitInput visit={visit} />
              <Select
                name="Site"
                placeholder="Select site"
                size="small"
                sx={{ width: '256px', ml: 2 }}
                value={mapVisitType(visit)}
                onChange={({
                  target: { value },
                }: ChangeEvent<HTMLInputElement>) =>
                  dispatch(
                    changeVisitType({ visit, value: value as VisitType }),
                  )
                }
              >
                {VISIT_TYPE_OPTIONS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>

              <Box ml={2} width={240}>
                <RadioGroup
                  name="portion"
                  sx={{ justifyContent: 'space-between' }}
                  value={visit.timelinePortion}
                  row
                  onChange={(event) => {
                    dispatch(
                      changeTimelinePortion({
                        visit,
                        timelinePortion: event.target
                          .value as PatientAssessmentTimelinePortionEnum,
                      }),
                    );
                  }}
                >
                  <Radio
                    value={PatientAssessmentTimelinePortionEnum.TREATMENT}
                  />
                  <Radio
                    value={PatientAssessmentTimelinePortionEnum.FOLLOW_UP}
                  />
                  <Radio value={PatientAssessmentTimelinePortionEnum.OTHER} />
                </RadioGroup>
              </Box>

              <Switch
                checked={visit.dayOfProtocol != null}
                disabled={visit.isScreen || visit.isEnroll}
                sx={{ ml: 2 }}
                onChange={({
                  target: { checked },
                }: ChangeEvent<HTMLInputElement>) => {
                  dispatch(toggleDayOfProtocol({ visit, checked }));
                }}
              />
              {!visit.isScreen && visit.dayOfProtocol != null && (
                <CondorTextField
                  InputProps={{ inputProps: { min: 1 } }}
                  disabled={visit.isEnroll}
                  size="small"
                  sx={{ width: '80px' }}
                  type="number"
                  value={visit.dayOfProtocol}
                  onChange={({
                    target: { value },
                  }: ChangeEvent<HTMLInputElement>) =>
                    dispatch(
                      changeDayOfProtocol({ visit, dayOfProtocol: value }),
                    )
                  }
                />
              )}
            </>
          }
          disableTypography
        />
        {closestEdge && <DropIndicator edge={closestEdge} gap="1px" />}
      </ListItem>
      {draggableState.type === 'preview' &&
        ReactDOM.createPortal(
          <Box
            sx={{
              color: (theme) => theme.palette.text.secondary,
              margin: '2px',
              padding: '6px 8px',
              borderRadius: '4px',
              border: (theme) => `1px solid ${theme.palette.primary.main}`,
              backgroundColor: (theme) => theme.palette.secondary.light,
              width: '30vw',
              boxShadow: (theme) => theme.shadows[0],
            }}
          >
            {visit.name}
          </Box>,
          draggableState.container,
        )}
    </>
  );
}

export const VISIT_TYPE_OPTIONS: Array<LabelValueOption<string, VisitType>> = [
  {
    label: 'Screening',
    value: 'isScreen',
  },
  {
    label: 'Enrolled',
    value: 'isEnroll',
  },
  {
    label: 'Screen-fail',
    value: 'isScreenfail',
  },
  {
    label: 'Dropped/completed',
    value: 'isDroppedCompleted',
  },
  {
    label: 'Regular visit',
    value: 'isRegular',
  },
];

export default VisitItem;

import { type ReactElement, useState } from 'react';

import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import UploadIcon from '@mui/icons-material/Upload';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';

import usePOListingIntegrationGridColDefs from 'shared/components/integrations/hooks/usePOListingIntegrationGridColDefs';
import IntegrationImportWizard from 'shared/components/integrations/wizards/IntegrationImportWizard';
import Button from 'shared/ui/button/Button';

import useFeatureFlag from 'shared/helpers/useFeatureFlag';
import withPeriodSpecificGenericWrapper from 'shared/lib/periods/withPeriodSpecificGenericWrapper';
import HasPermission from 'shared/lib/permissions/HasPermission';
import { IntegrationType } from 'shared/lib/types';
import { selectCompany } from 'shared/state/slices/companySlice';
import PurchaseOrdersUploadWizard from 'shared/wizards/purchase-order/PurchaseOrdersUploadWizard';
import useIntegration from 'shared/hooks/useIntegration';

function PurchaseOrdersHeader(): ReactElement {
  const currentCompany = useSelector(selectCompany);
  const integration = useIntegration(IntegrationType.PURCHASE_ORDER_SNAPSHOT);

  const [showUploadWizard, setShowUploadWizard] = useState<boolean>(false);
  const isIntegrationEnabledForPOListing = useFeatureFlag(
    'integration_po_listing',
  );
  const [showIntegrationWizard, setShowIntegrationWizard] =
    useState<boolean>(false);

  const poListingIntegrationColDefs = usePOListingIntegrationGridColDefs();

  return (
    <HasPermission permissions={['canEditCompanyLevelInfo']}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mb: 2,
          gap: 1,
          flexGrow: 0,
          flexShrink: 0,
        }}
      >
        {isIntegrationEnabledForPOListing && integration != null && (
          <Button
            startIcon={<ElectricalServicesIcon />}
            sx={{ alignSelf: 'flex-end' }}
            testId="btn_import_invoice_listing"
            variant="contained"
            onClick={() => setShowIntegrationWizard(true)}
          >
            Import
          </Button>
        )}
        <Button
          startIcon={<UploadIcon />}
          testId="upload_po_listing"
          variant="contained"
          onClick={() => setShowUploadWizard(true)}
        >
          Upload PO Listing
        </Button>
      </Box>
      {showUploadWizard && (
        <PurchaseOrdersUploadWizard
          companyTraceId={currentCompany.trace_id}
          onClose={() => setShowUploadWizard(false)}
        />
      )}
      {showIntegrationWizard && integration != null && (
        <IntegrationImportWizard
          childTotalValidationKey="po_amount"
          colDefs={poListingIntegrationColDefs}
          fieldsToValidate={['po_amount', 'po_number', 'study_id']}
          integration={integration}
          overlayNoRowsTemplate="No purchase orders"
          setShowIntegration={setShowIntegrationWizard}
          title="Import PO Listing"
          headerText={
            <>
              <Typography
                color={(theme) => theme.palette.primary.main}
                variant="h6"
              >
                Review the trials we have associated to each Purchase Order #.
                Reassign, split, or ignore lines as needed.
              </Typography>
              <Typography variant="body1">
                In some cases we were not able to identify which trial the line
                item should belong to, or the same PO# is used in multiple
                trials. You may reassign line items or split line items across
                multiple trials if needed. Ignored line items will not be used
                in any trial.
              </Typography>
            </>
          }
        />
      )}
    </HasPermission>
  );
}

export default withPeriodSpecificGenericWrapper(PurchaseOrdersHeader);

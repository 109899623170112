import Box from '@mui/material/Box';

import FileDisplay from 'shared/components/file-uploader/FileDisplay';
import type { FileUploaderRef } from 'shared/components/file-uploader/FileUploader';
import Button from 'shared/ui/button/Button';

import type { UnmappedFileResponse } from 'shared/lib/types';

import successIcon from '../icons/success.svg';

type Props = {
  columnCount: number;
  fileUploadData: { message: string; rows_count: number } | null;
  fileUploaderRef: React.MutableRefObject<FileUploaderRef | null>;
  files: File[];
  filesUploaded: UnmappedFileResponse[];
};

function UploadSuccessCSVSection(props: Props) {
  const { columnCount, fileUploadData, fileUploaderRef, filesUploaded, files } =
    props;
  return (
    <>
      <img alt="Success" height={120} src={successIcon} />
      <Box color="success.dark" fontSize="18px" lineHeight="1.5">
        {filesUploaded.length > 1 ? filesUploaded.length : ''} File
        {filesUploaded.length > 1 ? 's' : ''} successfully uploaded!
      </Box>
      <Box color="grey.900" display="flex" gap={4}>
        <span>
          Column count: <b>{columnCount}</b>
        </span>
        <span>
          Row count: <b>{fileUploadData?.rows_count ?? 0}</b>
        </span>
      </Box>
      <Button component="label" testId="change_file_success" variant="outlined">
        Change File
        {fileUploaderRef.current?.getFileInput()}
      </Button>
      <FileDisplay files={files} />
      {
        // eslint-disable-next-line etc/no-commented-out-code -- Temporarily disabled until Product provides templates
        /* <Typography
        variant="body1"
        color="info.main"
        sx={{ marginTop: '24px' }}
      >
        What should {fileTypeWithGrammaticalArticle} look like?
      </Typography> */
      }
    </>
  );
}

export default UploadSuccessCSVSection;

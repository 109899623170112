import { useEffect, useState } from 'react';

import { skipToken } from '@reduxjs/toolkit/query';

import useContractAndPeriodWithVersions from 'accruals/pages/clinical-expenses/shared/hooks/useContractAndPeriodWithVersions';
import { CRO_CONTRACT_TABS, getJSONFromFile } from 'shared/helpers/helpers';
import type { ContractVersionResponse, TraceId } from 'shared/lib/types';
import {
  ContractStatusOptions,
  PeriodRecordBlobType,
  statusFromBackendValue,
} from 'shared/lib/types';

import { useGetPeriodRecordBlobQuery } from 'shared/api/rtkq/periodrecordblobs';

type ReturnValue = {
  allowedStatuses?: ContractStatusOptions[];
  contractVersionTraceId?: TraceId;
  executionDateString: string | undefined;
  status: ContractStatusOptions | undefined;
};

// allowedStatuses, contractVersionTraceId are not needed for closed periods, so not added here
export function processContractVersionStatus(
  contractVersion: ContractVersionResponse | undefined,
): ReturnValue {
  const status = statusFromBackendValue(contractVersion?.status_for_period);

  let executionDateString;
  if (
    status === ContractStatusOptions.CURRENT &&
    contractVersion?.execution_date !== undefined
  ) {
    executionDateString = `Executed on ${contractVersion.execution_date}`;
  }

  return { executionDateString, status };
}

export default function useContractVersionStatus(): ReturnValue {
  const [returnValue, setReturnValue] = useState<ReturnValue>({
    executionDateString: undefined,
    status: undefined,
  });
  const {
    activeTab,
    contractContainer,
    contractVersion,
    contractVersionPeriodMenuItemTraceId,
    isClosedPeriodVersion,
    periodVersionTraceId,
  } = useContractAndPeriodWithVersions();

  const { currentData: periodRecordBlob } = useGetPeriodRecordBlobQuery(
    contractVersionPeriodMenuItemTraceId && periodVersionTraceId
      ? {
          saved_object_type: PeriodRecordBlobType.CONTRACT_VERSION_STATUS,
          period_version: periodVersionTraceId,
          parent_menu_item: contractVersionPeriodMenuItemTraceId,
        }
      : skipToken,
  );

  // there can only ever be one, but our "getByFilters" helper assumes many
  const recordSnapshot = periodRecordBlob?.length
    ? periodRecordBlob[0]
    : undefined;

  useEffect(() => {
    void (async () => {
      if (isClosedPeriodVersion) {
        if (recordSnapshot !== undefined) {
          const json = await getJSONFromFile<ReturnValue>(recordSnapshot.blob);
          if (json !== undefined) {
            setReturnValue(json);
          }
        }
      } else {
        const allowedStatuses = [ContractStatusOptions.VOIDED];
        if (
          activeTab === CRO_CONTRACT_TABS.CURRENT_CONTRACT &&
          contractContainer?.vendor_type === 'CRO'
        ) {
          allowedStatuses.push(ContractStatusOptions.AIP);
        }
        setReturnValue({
          ...processContractVersionStatus(contractVersion),
          allowedStatuses,
          contractVersionTraceId: contractVersion?.trace_id,
        });
      }
    })();
  }, [
    isClosedPeriodVersion,
    contractContainer,
    contractVersion,
    recordSnapshot,
    activeTab,
  ]);

  return returnValue;
}

import { useMemo } from 'react';

import type { CondorGridOptions } from 'shared/components/ag-grid/types';

export default function useOccContractBudgetGridOptions(
  _isOpenPeriod: boolean,
): CondorGridOptions {
  return useMemo(
    () => ({
      // this is used for row-level-comments as rowId, so ideally should not be changed
      getRowId: 'traceId',
      groupTotalRow: 'bottom' as const,
      grandTotalRow: 'bottom',
      suppressAggFuncInHeader: true,
    }),
    [],
  );
}

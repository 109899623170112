import { useMemo } from 'react';

import BookOutlined from '@mui/icons-material/BookOutlined';
import CalendarMonth from '@mui/icons-material/CalendarMonth';
import DashboardOutlined from '@mui/icons-material/DashboardOutlined';
import DescriptionIcon from '@mui/icons-material/DescriptionOutlined';
import MonetizationOnOutlined from '@mui/icons-material/MonetizationOnOutlined';
import RestoreIcon from '@mui/icons-material/Restore';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Timeline from '@mui/icons-material/Timeline';
import Vaccines from '@mui/icons-material/Vaccines';
import { useSelector } from 'react-redux';

import { selectPeriodVersion } from 'accruals/state/slices/periodVersionSlice';
import * as routes from 'routes';
import useFeatureFlag from 'shared/helpers/useFeatureFlag';
import SidebarContainer, {
  DIVIDER,
  ICON_SX,
} from 'shared/lib/sidebar/SidebarContainer';
import SidebarMenuItems from 'shared/lib/sidebar/SidebarMenuItems';
import type { SidebarSectionItem } from 'shared/lib/sidebar/SidebarSection';
import SidebarSkeletonContainer from 'shared/lib/sidebar/SidebarSkeletonContainer';
import SidebarTabs from 'shared/lib/sidebar/SidebarTabs';
import TrialNameAndBackButton from 'shared/lib/sidebar/TrialNameAndBackButton';
import { selectSidebarCollapsed } from 'shared/state/slices/sidebarSlice';
import { selectTrial } from 'shared/state/slices/trialSlice';

import useIsClosedPeriodVersion from 'shared/api/hooks/useIsClosedPeriodVersion';

import PeriodSelector from './PeriodSelector';
import useClosedPeriodContractContainerMenuItems from './hooks/useClosedPeriodContractContainerMenuItems';
import useOpenPeriodContractContainerMenuItems from './hooks/useOpenPeriodContractContainerMenuItems';

function AccrualsSidebar() {
  const trial = useSelector(selectTrial);
  const currentPeriodVersion = useSelector(selectPeriodVersion);
  const collapsed = useSelector(selectSidebarCollapsed);
  const isClosed = useIsClosedPeriodVersion();
  const isScheduleOfAssessmentsEnabled = useFeatureFlag(
    'schedule_of_assessments',
  );
  const isScheduleOfAssessmentsNonProceduresEnabled = useFeatureFlag(
    'schedule_of_assessments_non_procedures',
  );

  const { menuItems: openContractContainerMenuItems, loading: openLoading } =
    useOpenPeriodContractContainerMenuItems(trial.trace_id);
  const {
    menuItems: closedContractContainerMenuItems,
    loading: closedLoading,
  } = useClosedPeriodContractContainerMenuItems(currentPeriodVersion.trace_id);

  // only one of these will be set correctly due to how the hooks work
  const contractContainerMenuItems =
    openContractContainerMenuItems ?? closedContractContainerMenuItems;
  const isLoading = openLoading || closedLoading;

  const navigationItems: Array<SidebarSectionItem | typeof DIVIDER> =
    useMemo(() => {
      const companyMenuItems = [
        {
          title: 'Company',
          icon: <RestoreIcon sx={ICON_SX} />,
          disabled: false,
          subNav: [
            {
              title: 'PO listing',
              link: routes.getPurchaseOrders(routes.getCompanyPeriodClosed()),
            },
            {
              title: 'Invoice listing',
              link: routes.getInvoices(routes.getCompanyPeriodClosed()),
            },
            {
              title: 'FX rates',
              link: routes.getFxRates(routes.getCompanyPeriodClosed()),
            },
            {
              title: 'G/l accounts',
              link: routes.getGlAccounts(routes.getCompanyPeriodClosed()),
            },
            {
              title: 'Company files',
              link: routes.getCompanyFiles(routes.getCompanyPeriodClosed()),
            },
            {
              title: 'Users & permissions',
              link: routes.getUsersAndPermissions(
                routes.getCompanyPeriodClosed(),
              ),
            },
          ],
        },
      ];

      const trialMenuItems = [
        {
          title: 'Trial dashboard',
          icon: <DashboardOutlined sx={ICON_SX} />,
          link: routes.getTrialDashboard(),
        },
        {
          title: 'Analytics',
          icon: <Timeline sx={ICON_SX} />,
          link: routes.getAnalytics(),
        },
        {
          title: 'Financial close',
          icon: <CalendarMonth sx={ICON_SX} />,
          link: routes.getFinancialClose(),
        },
        {
          title: 'Journal entry',
          icon: <MonetizationOnOutlined sx={ICON_SX} />,
          link: routes.getJournalEntry(),
        },
        DIVIDER,
        {
          title: 'Clinical expenses',
          icon: <BookOutlined sx={ICON_SX} />,
          disabled: false,
          subNav: [
            { title: 'Summary', link: routes.getClinicalExpenses() },
            ...(contractContainerMenuItems ?? []),
            { title: 'Other clinical contracts', link: routes.getOcc() },
          ],
        },
        DIVIDER,
        {
          title: 'Trial activity',
          icon: <Vaccines sx={ICON_SX} />,
          subNav: [
            {
              title: 'Patient activity',
              link: routes.getPatientNavigation('patient-activity'),
            },
            {
              title: 'Site & lab cost matrix',
              link: routes.getSiteLabCostMatrix(),
            },
            ...(isScheduleOfAssessmentsEnabled
              ? [
                  {
                    title: isScheduleOfAssessmentsNonProceduresEnabled
                      ? 'Schedule of assessments'
                      : 'Map procedures',
                    link: routes.getScheduleOfAssessments(),
                  },
                ]
              : []),
          ],
        },
        DIVIDER,
        {
          title: 'Trial files',
          icon: <DescriptionIcon sx={ICON_SX} />,
          link: routes.getTrialFiles(),
        },
        {
          title: 'Trial settings',
          icon: <SettingsOutlinedIcon sx={ICON_SX} />,
          link: routes.getTrialSettings(),
        },
      ];

      return [
        ...trialMenuItems,
        ...(isClosed ? [DIVIDER, ...companyMenuItems] : []),
      ];
    }, [
      isClosed,
      contractContainerMenuItems,
      isScheduleOfAssessmentsEnabled,
      isScheduleOfAssessmentsNonProceduresEnabled,
    ]);

  return (
    <SidebarContainer>
      <TrialNameAndBackButton collapsed={collapsed} />
      <SidebarTabs activeTab="accruals" collapsed={collapsed} />
      <PeriodSelector collapsed={collapsed} />
      {isLoading ? (
        <SidebarSkeletonContainer collapsed={collapsed} numSkeletons={8} />
      ) : (
        <SidebarMenuItems collapsed={collapsed} items={navigationItems} />
      )}
    </SidebarContainer>
  );
}

export default AccrualsSidebar;

import type {
  ActivityDriverResponse,
  ForecastActivityDriverRequestParams,
} from 'shared/lib/types';

import { PERIOD_OCC_EXPENSES, PERIOD_OCC_RECON } from './apiTags';
import constructApi from './constructApi';
import { CONTRACT_BUDGET_RECORDS } from './contractbudgetrecords';
import {
  CONTRACT_ACTIVITY_DRIVER_COMPLETED_COUNT,
  CONTRACT_EXPENSES,
  CONTRACT_RECON,
} from './contracts';
import { GENERATE_FORECASTS_TAG } from './forecasts';
import { TRIAL_OCC_ACTIVITY_DRIVER_COMPLETED_COUNT } from './periods';

const apiEndpointFactory = constructApi<
  ActivityDriverResponse,
  ForecastActivityDriverRequestParams
>('forecastactivitydriver').withTags('ACTIVITY_DRIVERS', [
  CONTRACT_EXPENSES,
  CONTRACT_RECON,
  CONTRACT_BUDGET_RECORDS,
  PERIOD_OCC_EXPENSES,
  PERIOD_OCC_RECON,
  CONTRACT_ACTIVITY_DRIVER_COMPLETED_COUNT,
  TRIAL_OCC_ACTIVITY_DRIVER_COMPLETED_COUNT,
  GENERATE_FORECASTS_TAG,
]);

const api = apiEndpointFactory.inject(() => ({
  upsertForecastActivityDriver: apiEndpointFactory.create(
    [
      CONTRACT_EXPENSES,
      CONTRACT_RECON,
      PERIOD_OCC_EXPENSES,
      PERIOD_OCC_RECON,
      CONTRACT_ACTIVITY_DRIVER_COMPLETED_COUNT,
      TRIAL_OCC_ACTIVITY_DRIVER_COMPLETED_COUNT,
      GENERATE_FORECASTS_TAG,
    ],
    'upsert_forecast_activity_driver',
  ),
}));

export const { useUpsertForecastActivityDriverMutation } = api;

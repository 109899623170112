import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import Box from '@mui/material/Box';

import type { CustomCellRendererParams } from 'shared/components/ag-grid/types';

function AgGridIntegrationsErrorRenderer(params: CustomCellRendererParams) {
  const { value } = params;

  return (
    value && (
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'baseline',
        }}
      >
        <span>
          <ErrorOutlineOutlinedIcon
            sx={{
              color: (theme) => theme.palette.error.main,
              fontWeight: 'bold',
            }}
          />
        </span>
      </Box>
    )
  );
}

export default AgGridIntegrationsErrorRenderer;

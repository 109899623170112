import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';

type Props = {
  setShowActuals: (value: boolean) => void;
  showActuals: boolean;
};

export default function ShowActuals(props: Props) {
  const { setShowActuals, showActuals } = props;
  return (
    <Tooltip title="Show actuals">
      <FormControlLabel
        label="Show actuals"
        control={
          <Switch
            checked={showActuals}
            onChange={(_, checked) => setShowActuals(checked)}
          />
        }
      />
    </Tooltip>
  );
}

/* eslint-disable @typescript-eslint/no-restricted-imports -- Overridden MUI component is only allowed in this file that overrides it */
import type { Ref } from 'react';
import { forwardRef } from 'react';

import MuiIconButton, { iconButtonClasses } from '@mui/material/IconButton';
import type { IconButtonProps as MuiIconButtonProps } from '@mui/material/IconButton/IconButton';
/* eslint-enable @typescript-eslint/no-restricted-imports */

function IconButton(props: IconButtonProps, ref: Ref<HTMLButtonElement>) {
  return <MuiIconButton ref={ref} size="small" {...props} />;
}

type Variant = 'primary' | 'secondary' | 'tertiary';

type IconButtonProps = MuiIconButtonProps & {
  variant?: Variant;
  component?: React.ElementType;
};

// eslint-disable-next-line @typescript-eslint/no-restricted-imports -- Overridden MUI component is only allowed in this file that overrides it
export { getIconButtonUtilityClass } from '@mui/material/IconButton';
export { iconButtonClasses, type IconButtonProps };
export default forwardRef(IconButton);

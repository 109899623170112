import { useParams } from 'react-router-dom';

import NavigationTabs from 'shared/components/tabs/NavigationTabs';

import {
  getScheduleOfAssessmentsSubTab,
  getScheduleOfAssessmentsTab,
} from 'routes';

function ScheduleOfAssessmentsTabs() {
  const { activeTab, activeSubTab } = useParams();

  const scheduleTabs = [
    { key: SCHEDULE_TABS.VISITS, label: 'Visits' },
    { key: SCHEDULE_TABS.PROCEDURES, label: 'Procedures' },
    { key: SCHEDULE_TABS.COHORTS, label: 'Cohorts' },
  ];

  if (!activeTab) {
    return null;
  }

  return (
    <NavigationTabs
      activeTab={activeTab}
      sx={{ bgcolor: 'common.white' }}
      tabs={scheduleTabs}
      generateUrl={(newTab) => {
        switch (newTab) {
          case SCHEDULE_TABS.PROCEDURES:
            return getScheduleOfAssessmentsSubTab(
              newTab,
              activeSubTab ?? 'procedure-names',
            );
          default:
            return getScheduleOfAssessmentsTab(newTab);
        }
      }}
    />
  );
}

export const SCHEDULE_TABS = {
  VISITS: 'visits',
  PROCEDURES: 'procedures',
  COHORTS: 'cohorts',
};

export default ScheduleOfAssessmentsTabs;

import Box from '@mui/material/Box';

import type { CustomCellRendererParams } from 'shared/components/ag-grid/types';
import Checkbox from 'shared/ui/checkbox/Checkbox';

function AgGridIntegrationsIgnoreRenderer(params: CustomCellRendererParams) {
  const { value, data, api } = params;

  const handleIgnoreCheckboxChange = () => {
    const currentRow = api.getRowNode(data.row_id);
    if (currentRow) {
      api.applyTransaction({
        update: [{ ...currentRow.data, ignored: !value }],
      });

      api.refreshCells({ rowNodes: [currentRow], force: true });
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 33,
      }}
    >
      {!data.split && (
        <Checkbox
          checked={value}
          disableRipple
          onChange={handleIgnoreCheckboxChange}
        />
      )}
    </Box>
  );
}

export default AgGridIntegrationsIgnoreRenderer;

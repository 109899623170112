import type { CommentCellRendererParams } from 'shared/components/ag-grid/types';
import CommentsButton from 'shared/components/comments/CommentsButton';

import HasPermission from 'shared/lib/permissions/HasPermission';

function AgGridAddCommentRenderer(params: CommentCellRendererParams) {
  const { location, locationSlug = '', node, commentCountsByRowId } = params;

  if (node.group) {
    return null;
  }

  // the id for each grid needs to be defined in its respective GridOptions
  const locationRowId = node.id;
  if (!locationRowId) {
    return null;
  }

  return (
    <HasPermission permissions={['canAddComments']}>
      <CommentsButton
        commentsCount={commentCountsByRowId?.[locationRowId] ?? 0}
        location={location}
        locationRowId={locationRowId}
        locationSlug={locationSlug}
        smallerButton
      />
    </HasPermission>
  );
}

export default AgGridAddCommentRenderer;

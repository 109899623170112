import { useState } from 'react';

import { skipToken } from '@reduxjs/toolkit/query/react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { selectPeriod } from 'accruals/state/slices/periodSlice';
import { selectPeriodVersion } from 'accruals/state/slices/periodVersionSlice';
import ColumnMapperStep from 'shared/lib/column-mapper/ColumnMapperStep';
import FullWidthDialog from 'shared/lib/dialog/FullWidthDialog';
import type {
  CroCostCategory,
  UnmappedFileResponse,
  UploadedFile,
} from 'shared/lib/types';
import { UnmappedFileEntityType } from 'shared/lib/types';
import Wizard from 'shared/lib/wizard/Wizard';
import useOnCloseLogic from 'shared/wizards/hooks/useOnCloseLogic';
import UploadUnmappedCsvStep from 'shared/wizards/upload-unmapped-step/csv/UploadUnmappedCsvStep';

import {
  useGetContractContainerQuery,
  useGetCurrentContractVersionQuery,
} from 'shared/api/rtkq/contractcontainers';
import {
  useCreateReportedActivityFromCsvMutation,
  useDeleteVendorRepActivitySnapshotMutation,
} from 'shared/api/rtkq/vendorrepactivitysnapshots';

import columns from './columns';
import DirectFeeConfirmationStep from './steps/DirectFeeConfirmationStep';
import DirectFeeReviewStep from './steps/DirectFeeReviewStep';

type Props = {
  costCategory: CroCostCategory;
  onClose: () => void;
  onComplete?: () => void;
};

function DirectFeeUploadWizard(props: Props) {
  const { costCategory, onClose: onCloseProp, onComplete } = props;
  const { contractContainerId } = useParams();
  const period = useSelector(selectPeriod);
  const { currentData: contractContainer } = useGetContractContainerQuery(
    contractContainerId ?? skipToken,
  );
  const { currentData: currentContract } = useGetCurrentContractVersionQuery(
    contractContainerId
      ? { trace_id: contractContainerId, otherParameter: period.trace_id }
      : skipToken,
  );
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [snapshotId, setSnapshotId] = useState<string>();

  const [deleteSnapshot, { isLoading: _deleteIsLoading }] =
    useDeleteVendorRepActivitySnapshotMutation();
  const [setFfMappingId, handleCloseBtnClick] = useOnCloseLogic();

  const currentPeriodVersion = useSelector(selectPeriodVersion);

  function onClose(isLastStepSaveClick?: boolean) {
    handleCloseBtnClick(
      uploadedFiles,
      deleteSnapshot,
      setUploadedFiles,
      setSnapshotId,
      onCloseProp,
      isLastStepSaveClick,
      snapshotId,
    );
  }

  function saveUrl(unmappedFiles: UnmappedFileResponse[]) {
    setUploadedFiles(
      unmappedFiles.map((file) => ({
        csvBlobUrl: file.file,
        unmappedFileUrl: file.url,
        traceId: file.trace_id,
      })),
    );
  }
  const activityType =
    costCategory === 'DIRECT_FEES' ? 'direct fees' : 'pass-throughs';
  const title = `CRO ${activityType} reported activity`;
  const uploadHeader = `Upload your LTD CRO ${activityType} reported activity.`;
  const mapperHeader = 'Assign these headers to your report';

  return (
    <FullWidthDialog open>
      <Wizard
        devTitle="BOURNE"
        startIndex={0}
        stepNames={['Map Columns', 'Validate', 'Review']}
        title="Column Mapping"
        onClose={onClose}
      >
        <UploadUnmappedCsvStep
          fileTypeWithGrammaticalArticle={`a ${activityType} reported activity file`}
          header={uploadHeader}
          periodVersion={currentPeriodVersion}
          setSnapshotId={setSnapshotId} // ??
          titleOverride={title}
          vendor={contractContainer?.vendor}
          entityClass={
            costCategory === 'DIRECT_FEES'
              ? UnmappedFileEntityType.DirectFeesSnapshot
              : UnmappedFileEntityType.PassthroughsSnapshot
          }
          onSave={saveUrl}
        />
        <ColumnMapperStep
          columns={columns}
          setFfMappingId={setFfMappingId}
          setSnapshotId={setSnapshotId}
          stepTitleText={mapperHeader}
          uploadedFiles={uploadedFiles}
          useCreateSnapshotEndpoint={useCreateReportedActivityFromCsvMutation}
          extraCreateSnapshotArgs={{
            contract: currentContract?.trace_id ?? '',
            snapshot: snapshotId ?? '',
            cost_category: costCategory,
          }}
        />
        <DirectFeeConfirmationStep snapshotId={snapshotId} />
        <DirectFeeReviewStep
          snapshotId={snapshotId}
          onNext={() => onComplete?.()}
        />
      </Wizard>
    </FullWidthDialog>
  );
}

export default DirectFeeUploadWizard;
